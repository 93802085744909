import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import UpdateGame from './UpdateGame';

function UpdateGameModal({ gameId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        as="button"
        p={4}
        color="white"
        fontWeight="bold"
        borderRadius="xl"
        bgGradient="linear(to-r, #439cfb, #f187fb)"
        _hover={{
          bgGradient: 'linear(to-r, teal.300, green.300)',
        }}
      >
        Update Game
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UpdateGame gameId={gameId} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateGameModal;
