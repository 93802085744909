import React from 'react';
import SearchById from './SearchById';
import { REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../Constants/ContractAddress';

const SearchMagicNFT = () => {
  return (
    <SearchById
      useCase={'tokens/' + REACT_APP_MAGIC_NFT_ERC721_ADDRESS}
      _placeholder="Magic NFT Id"
      label="Search Magic NFT"
    />
  );
};

export default SearchMagicNFT;
