import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Text,
  Stack,
  VStack,
  HStack,
  Grid,
  RadioGroup,
  Radio,
  Wrap,
  WrapItem,
  Image,
  Link,
  Icon
} from '@chakra-ui/react';
import { RiHomeHeartLine, RiHomeHeartFill } from 'react-icons/ri';
import { urlPrefix } from '../../../utils/helpers';
import { InfoIcon } from '@chakra-ui/icons';

export const HomeIcon = () => {
  return (
    <Link as={ReactLink} to="/">
      <Icon as={RiHomeHeartLine} w={10} h={10} color='green.300' />
    </Link>
  );
};


