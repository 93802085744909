import { useParams } from 'react-router-dom';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../../walletConnections/obtainProviders/getNetworkProvider';
import RenderToken from './RenderToken'
const TokenPageBase = ({ useCase }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const provider =
    address == undefined
      ? getNetworkProvider()
      : new ethers.providers.Web3Provider(walletProvider);
  // console.log('provider:', provider);
  const { tokenContractAddress, tokenId, user } = useParams();

  return (
    <>
      {provider && (
        <RenderToken
          provider={provider}
          tokenContractAddress={tokenContractAddress}
          tokenId={tokenId}
          useCase={useCase}
          user={user}
        />
      )}
    </>
  );
};

export default TokenPageBase;
