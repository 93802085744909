import React, { useState } from 'react';
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  VStack,
  Text,
  HStack,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
import {
  ExternalUrlForm,
  UrlPrefixWarning,
  urlPrefix,
} from '../views/NFTs/elements/FormElements';
import { removeItemAt, removePartFromArray } from '../utils/helpers';
import '../../styles/DashedBorder.css';
import GamePosterVideoForm from './GamePosterVideoForm';
import getImageUrl from '../utils/getImageUrl';
import SwitchForm from './SwtichForm';
import AddSaleIdsForm from './AddSaleIdsForm';
import SocialMediaForm from './SocialMediaForm';
// import HighlightsTokensForm from './HighlightsTokensForm';

const HighlightTokenMetaForm = ({ provider, meta, setMeta, pageName }) => {
  // console.log('meta:', meta);
  const socialMediaFields = ['X', 'facebook', 'youtube'];

  const handleMeta = (field, value) => {
    const updatedMeta = { ...meta }; // Create a copy of the meta object
    updatedMeta[field] = value; // Update the property with the given field
    setMeta(updatedMeta); // Update the state with the modified object
  };

  const [newContractAddress, setNewContractAddress] = useState('');
  const [newTokenIds, setNewTokenIds] = useState('');

  const [tokenImageUrl, setTokenImageUrl] = useState('');
  const [doesVerify, setDoesVerify] = useState(false);
  const [verifyTokenId, setVerifyTokenId] = useState(0);
  const [verifyTokenContract, setVerifyTokenContract] = useState('');

  const [addMarketplaceErc721Ids, setAddMarketplaceErc721Ids] = useState(false);
  const [addMarketplaceErc1155Ids, setAddMarketplaceErc1155Ids] =
    useState(false);
  const [addAuctionIds, setAddAuctionIds] = useState(false);

  // const handleMeta = (field, value) => {
  //   const updatedMeta = { ...meta }; // Create a copy of the meta object
  //   updatedMeta[field] = value; // Update the property with the given field
  //   setMeta(updatedMeta); // Update the state with the modified object
  // };

  const handleChange = (e1, e2) => {
    const newMeta = { ...meta };
    // newMeta[index].cTokenContracts.push(event.target.value);
    newMeta.cTokenContracts = [...newMeta.cTokenContracts, e1.target.value];
    newMeta.cTokenIds = [...newMeta.cTokenIds, e2.target.value];
    console.log('newMeta:', newMeta);
    setMeta(newMeta);
  };

  const verifyToken = async (_tokenId, _tokenContractAddr) => {
    const _tokenImageUrl = await getImageUrl(
      provider,
      _tokenId,
      _tokenContractAddr
    );
    console.log('_tokenImageUrl:', _tokenImageUrl);
    setTokenImageUrl(_tokenImageUrl);
    setDoesVerify(true);
    setVerifyTokenContract(_tokenContractAddr);
    setVerifyTokenId(_tokenId);
  };

  const stringToArray = str => {
    let array = str.split(',').map(item => item.trim());
    return array;
  };

  const RenderVerifyTokenButton = ({ tokenId, tokenContractAddr }) => {
    return (
      <Button
        colorScheme="blue"
        onClick={() => verifyToken(tokenId, tokenContractAddr)}
      >
        Verify Token
      </Button>
    );
  };

  const handleRemoveToken = (index, partToRemove) => {
    const _newTokenIds = removePartFromArray(
      meta.cTokenIds,
      index,
      partToRemove
    );
    const updatedMeta = { ...meta }; // Create a copy of the meta object
    updatedMeta.cTokenIds = _newTokenIds; // Update the property with the given field
    console.log('_updatedMeta:', updatedMeta);
    setMeta(updatedMeta);
  };
  const RenderRemoveTokenButton = ({ index, partToRemove }) => {
    console.log('index:', index);
    console.log('partToRemove:', partToRemove);
    return (
      <Button
        colorScheme="pink"
        // onClick={() => removePartFromArray(meta.cTokenIds, index, partToRemove)}
        onClick={() => handleRemoveToken(index, partToRemove)}
      >
        Remove Token
      </Button>
    );
  };

  const handleRemoveContract = index => {
    const _newContracts = removeItemAt(meta.cTokenContracts, index);
    const _newTokenIds = removeItemAt(meta.cTokenIds, index);
    const updatedMeta = { ...meta }; // Create a copy of the meta object
    updatedMeta.cTokenContracts = _newContracts;
    updatedMeta.cTokenIds = _newTokenIds; // Update the property with the given field
    console.log('_updatedMeta:', updatedMeta);
    setMeta(updatedMeta);
  };

  const RenderRemoveContractButton = ({ index }) => {
    console.log('index:', index);
    return (
      <Button
        colorScheme="red"
        onClick={() => {
          // removeItemAt(meta.cTokenContracts, index);
          // removeItemAt(meta.cTokenIds, index);
          handleRemoveContract(index);
        }}
      >
        Remove Contract
      </Button>
    );
  };

  const handleRemoveAllContracts = () => {
    const updatedMeta = { ...meta }; // Create a copy of the meta object
    updatedMeta.cTokenContracts = [];
    updatedMeta.cTokenIds = []; // Update the property with the given field
    console.log('_updatedMeta:', updatedMeta);
    setMeta(updatedMeta);
  };

  const RenderRemoveAllContractsButton = ({}) => {
    return (
      <Button
        colorScheme="red"
        onClick={() => {
          handleRemoveAllContracts();
        }}
      >
        Remove All Contracts
      </Button>
    );
  };

  return (
    <>
      <GamePosterVideoForm meta={meta} handleMeta={handleMeta} label="" />

      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Display Name:</FormLabel>

          <Input
            name="name"
            value={meta.name}
            onChange={e => handleMeta('name', e.target.value)}
            placeholder="Game Name"
            size="sm"
          />
        </FormControl>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Description:</FormLabel>

          <Input
            name="description"
            value={meta.description}
            onChange={e => handleMeta('description', e.target.value)}
            placeholder="NFT description"
            size="sm"
          />
        </FormControl>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">
            Tags:
            <Popover>
              <PopoverTrigger>
                <IconButton
                  variant="outline"
                  colorScheme="green"
                  aria-label="Get Info"
                  size="xs"
                  icon={<FaInfo boxSize={8} />}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  Separate tags with comma, for example: fairy, beautiful,
                  romantic, ...
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormLabel>
          <Input
            name="tags"
            value={meta.tags}
            onChange={e => handleMeta('tags', e.target.value)}
            placeholder="Tags"
            size="sm"
          />
        </FormControl>
      </Box>

      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">External Url for Artist:</FormLabel>

          <UrlPrefixWarning urlType="general" />

          <Input
            name="externalUrl"
            value={meta.externalUrl}
            onChange={e => handleMeta('externalUrl', e.target.value)}
            placeholder={'External Url must start with ' + urlPrefix['general']}
            size="sm"
          />
        </FormControl>
      </Box>

      <SocialMediaForm
        socialMediaFields={socialMediaFields}
        meta={meta}
        handleMeta={handleMeta}
      />

      {/* <HighlightsTokensForm meta={meta} setMeta={setMeta} /> */}
      <>
        {pageName == 'Artist' && <Text>Add Highlight Tokens:</Text>}
        <Box
          display="flex"
          alignItems="baseline"
          mb="20px"
          style={{
            fontSize: '1.2rem',
            display: 'grid',

            border: '8px solid',
            padding: '1rem',
            borderWidth: '10px',
            borderImage:
              'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
          }}
        >
          {pageName == 'Artist' && (
            <>
              <Box>
                <VStack>
                  <Text fontSize="lg" color="teal.500">
                    Highlight Tokens Added:
                  </Text>
                  {meta.cTokenContracts.length == 0 && (
                    <Text fontSize="md" color="teal.500" as="i">
                      None
                    </Text>
                  )}
                  {meta.cTokenContracts.length > 0 && (
                    <RenderRemoveAllContractsButton token={meta} />
                  )}
                  {meta.cTokenContracts.length > 0 &&
                    meta.cTokenContracts.map((contract, contractIndex) => (
                      <Box
                        key={contractIndex}
                        bgGradient="linear(to-r, #84ffc9, #aab2ff, #eca0ff)"
                        boxShadow="xl"
                        p="2"
                        rounded="md"
                      >
                        <RenderRemoveContractButton
                          token={meta}
                          index={contractIndex}
                        />
                        <Text fontSize="sm" color="blackAlpha.900">
                          Token Contract :
                        </Text>
                        <Text fontSize="sm" color="blackAlpha.900" as="i">
                          {contract.substring(0, 20)}...{' '}
                        </Text>
                        <Text fontSize="sm" color="blackAlpha.900">
                          Tokens from this contract:
                        </Text>
                        {/* <Text fontSize="sm" color="blackAlpha.900" as="i">
                        {meta.cTokenIds[contractIndex]}
                      </Text> */}
                        {stringToArray(meta.cTokenIds[contractIndex]).map(
                          (t, i) => (
                            <>
                              <Box>
                                <HStack>
                                  <Text
                                    fontSize="sm"
                                    color="blackAlpha.900"
                                    as="i"
                                  >
                                    Token Id: {t}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <RenderVerifyTokenButton
                                    tokenId={t}
                                    tokenContractAddr={contract}
                                  />
                                  <RenderRemoveTokenButton
                                    token={meta}
                                    index={contractIndex}
                                    partToRemove={t}
                                  />
                                </HStack>

                                {doesVerify &&
                                  t == verifyTokenId &&
                                  contract == verifyTokenContract && (
                                    <img
                                      src={tokenImageUrl}
                                      style={{ width: '150px' }}
                                    />
                                  )}
                              </Box>
                            </>
                          )
                        )}
                      </Box>
                    ))}
                </VStack>
              </Box>
              <Box mb="20px" mt="30px">
                <FormControl>
                  <FormLabel as="legend">Token Contract Address:</FormLabel>

                  <Input
                    name="cTokenContracts"
                    value={newContractAddress}
                    onChange={e => setNewContractAddress(e.target.value)}
                    placeholder="Complimentary Token Contract Address"
                    size="sm"
                  />
                </FormControl>
              </Box>

              <Box style={{ marginBottom: '20px' }}>
                <FormControl>
                  <FormLabel as="legend">
                    Token Ids from this contract:
                    <Popover>
                      <PopoverTrigger>
                        <IconButton
                          variant="outline"
                          colorScheme="green"
                          aria-label="Get Info"
                          size="xs"
                          icon={<FaInfo boxSize={8} />}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          Separate tokenIds with comma, for example: 1, 2, 3,
                          ...
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </FormLabel>
                  <Input
                    name="cTokenIds"
                    value={newTokenIds}
                    onChange={e => setNewTokenIds(e.target.value)}
                    placeholder="Complimentary Token Ids from this conract"
                    size="sm"
                  />
                </FormControl>
              </Box>

              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => {
                  if (newContractAddress.length > 0 && newTokenIds.length > 0) {
                    handleChange(
                      { target: { value: newContractAddress } },
                      { target: { value: newTokenIds } }
                    );

                    // handleNewContractChange({
                    //   target: { value: newContractAddress },
                    // });

                    // handleNewTokenIdsChange({
                    //   target: { value: newTokenIds },
                    // });

                    console.log('meta:', meta);

                    setNewContractAddress('');

                    setNewTokenIds('');
                  }
                }}
              >
                Add Contract and Token Ids
              </Button>
            </>
          )}

          {pageName == 'Shop' && (
            <>
              <SwitchForm
                item={addMarketplaceErc721Ids}
                setItem={setAddMarketplaceErc721Ids}
                label="Highlight ERC721 Sales"
              />
              {addMarketplaceErc721Ids && (
                <AddSaleIdsForm
                  meta={meta}
                  field="marketplaceIdsErc721"
                  handleMeta={handleMeta}
                  label="Marketplace (ERC721) Ids"
                />
              )}
              <SwitchForm
                item={addMarketplaceErc1155Ids}
                setItem={setAddMarketplaceErc1155Ids}
                label="Highlight ERC1155 Sales"
              />
              {addMarketplaceErc1155Ids && (
                <AddSaleIdsForm
                  meta={meta}
                  field="marketplaceIdsErc1155"
                  handleMeta={handleMeta}
                  label="Marketplace (ERC1155) Ids"
                />
              )}
              <SwitchForm
                item={addAuctionIds}
                setItem={setAddAuctionIds}
                label="Highlight Auctions"
              />
              {addAuctionIds && (
                <AddSaleIdsForm
                  meta={meta}
                  field="auctionIds"
                  handleMeta={handleMeta}
                  label="Auction Ids"
                />
              )}
            </>
          )}
        </Box>
      </>
    </>
  );
};

export default HighlightTokenMetaForm;
