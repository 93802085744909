import {
    Box,
    Text,
    VStack,
    Link,
    Icon,
    HStack,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
  } from '@chakra-ui/react';
  import { GiCutDiamond, GiSunflower } from 'react-icons/gi';

  import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
  import { Link as ReactLink, useLocation } from 'react-router-dom';
  import MintTokensBase from '../../../components/actions/NFTs/mintTokensBase';
  
  const MintAndAddToGalleryBase = ({gameTokenId}) => {
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const magicflowerId = searchParams.get('data');
  
    return (
      <div>
        <VStack>
          <HomeIcon />
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>Mint Erc721 and Integrate In Game</Tab>
              <Tab>Mint Erc1155 and Integrate In Game</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box>
                  <MintTokensBase tokenType="Erc721" specificGameTokenId={gameTokenId} />
                </Box>
              </TabPanel>
              <TabPanel>
                <Box>
                  <MintTokensBase tokenType="Erc1155" specificGameTokenId={gameTokenId} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
  
          <HStack>
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          </HStack>
          <Box mt="30px">
            <Text fontSize="sm" as="i">
              Back to{' '}
              <Link
                color="teal.500"
                as={ReactLink}
                to={`/gamelist/${gameTokenId}`}
              >
                Game Token #{gameTokenId}
              </Link>
            </Text>
          </Box>
        </VStack>
      </div>
    );
  };
  
  export default MintAndAddToGalleryBase;
  