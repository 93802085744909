import React, { useState } from 'react';
import {
  Input,
  HStack,
  VStack,
  InputGroup,
  InputLeftElement,
  Button,
  Box,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import RenderValleyItems from '../views/NFTs/elements/valleyItems/RenderValleyItems';

const SearchValleyItemsBySeller = ({ allItems }) => {
  const [addr, setAddr] = useState('');
  const [items, setItems] = useState([]);
  const [showItems, setShowItems] = useState(false);
  const handleAddr = event => setAddr(event.target.value);
  const isSeller = val => {
    return val.seller === addr;
  };
  const getItems = () => {
    const _items = allItems.filter(isSeller);
    setItems(_items);
  };

  const displayItems = () => {
    getItems();
    setShowItems(true);
  };
  return (
    <VStack spacing={3} mt={6}>
      <HStack>
        <Box>
          <InputGroup size="sm">
            <InputLeftElement children={<SearchIcon color="green.300" />} />

            <Input
              value={addr}
              onChange={handleAddr}
              placeholder="Seller Address"
              size="sm"
            />
          </InputGroup>
        </Box>
        <Box>
          <Button
            onClick={displayItems}
            colorScheme="green"
            size="sm"
            variant="outline"
          >
            Search
          </Button>
        </Box>
      </HStack>
      <Box>{showItems && <RenderValleyItems items={items} />}</Box>
    </VStack>
  );
};

export default SearchValleyItemsBySeller;
