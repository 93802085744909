import { Box, Input, FormControl, FormLabel } from '@chakra-ui/react';
import RenderDisplayCoverImage from './RenderDisplayCoverImage';
import RenderDisplayName from './RenderDisplayName';
import TokenCidForm from './TokenCidForm';
import CollectionImageUrlForm from './CollectionImageUrlForm';

const CreateNewCollectionForm = ({
  collectionImageUrl,
  setCollectionImageUrl,
  newCollectionName,
  setNewCollectionName,
  collectionMeta,
  setCollectionMeta,
}) => {
  const handleCollectionMeta = e => {
    setCollectionMeta(prevCollectionMeta => {
      return {
        ...prevCollectionMeta,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <Box>
      {/* <RenderDisplayCoverImage
        objUrlDisplay={objUrlCollection}
        setObjUrlDisplay={setObjUrlCollection}
        setFileDisplay={setFileCollection}
        setData={setData}
        err={err}
        setErr={setErr}
        label='Collection'
      /> */}
      <CollectionImageUrlForm
        collectionImageUrl={collectionImageUrl}
        setCollectionImageUrl={setCollectionImageUrl}
      />

      <Box style={{ marginBottom: '20px' }}>
        <RenderDisplayName
          newDisplayName={newCollectionName}
          setNewDisplayName={setNewCollectionName}
          label="Collection Name"
        />

        <Box style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">Collection Description:</FormLabel>

            <Input
              name="collectionDescription"
              value={collectionMeta.collectionDescription}
              onChange={handleCollectionMeta}
              placeholder="collection description"
              size="sm"
            />
          </FormControl>
        </Box>

        <Box style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">External Url:</FormLabel>

            <Input
              name="collectionExternalUrl"
              value={collectionMeta.collectionExternalUrl}
              onChange={handleCollectionMeta}
              placeholder="External Url "
              size="sm"
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateNewCollectionForm;
