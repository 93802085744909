import { Icon, List, ListItem, ListIcon } from '@chakra-ui/react';
import { GiButterfly } from 'react-icons/gi';
import UserAddTokenToNewCollection from './UserAddTokenToNewCollection';
import UserAddTokenToCollection from './UserAddTokenToCollection';
import UserRemoveTokenFromCollection from './UserRemoveTokenFromCollection';

const UserCollectionAction = ({ tokenContract, tokenId }) => {
  return (
    <>
      <List spacing={3}>
        <ListItem>
          <ListIcon as={GiButterfly} color="green.500" />
          <UserAddTokenToNewCollection
            tokenContract={tokenContract}
            tokenId={tokenId}
          />
        </ListItem>
        <ListItem>
          <ListIcon as={GiButterfly} color="green.500" />
          <UserAddTokenToCollection
            tokenContract={tokenContract}
            tokenId={tokenId}
          />
        </ListItem>
        <ListItem>
          <ListIcon as={GiButterfly} color="green.500" />
          <UserRemoveTokenFromCollection
            tokenContract={tokenContract}
            tokenId={tokenId}
          />
        </ListItem>
      </List>
    </>
  );
};

export default UserCollectionAction;
