import React from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import ListIntegrateTokensBase from '../../../components/actions/NFTs/ListIntegrateTokensBase';

function IntegrateTokensForGame() {
  const location = useLocation();
  // console.log('location:', location);
  const data = location.state;
  // console.log('data:', data);
  return (
    <ListIntegrateTokensBase
      tokenType="Erc721"
      specificGameTokenId={Number(data.gameTokenId)}
    />
  );
}

export default IntegrateTokensForGame
