import { Link as ReactLink } from 'react-router-dom';
import { Box, Link } from '@chakra-ui/react';
import MarketplaceImage from '../../../../../../resources/images/valley/marketplace.jpg';
import AuctionHouseImage from '../../../../../../resources/images/valley/auctionHouse.jpg';
function BackdropFilter({ title, gameTokenId, gameName, gameVideoUrl, gamePosterUrl, isMarketplace }) {
  const toUrl = isMarketplace ? '/marketplacegame' : '/auctionsgame';
  const bgUrl = isMarketplace ? MarketplaceImage : AuctionHouseImage;
  // console.log('gameVideoUrl:', gameVideoUrl)
  const myData = { gameTokenId, gameName, gameVideoUrl, gamePosterUrl };
  const outerBoxStyles = {
    boxSize: '288px',
    p: '10',
    background: `url(${bgUrl}) center/cover no-repeat`,
  };

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '34px',
    fontFamily: 'Lobster',
  };
  return (
    <Box sx={outerBoxStyles}>
      <Link as={ReactLink} to={toUrl} state={myData}>
        <Box sx={innerBoxStyles} backdropFilter="auto" backdropBlur="8px">
          {title}
        </Box>
      </Link>
    </Box>
  );
}

export default BackdropFilter;
