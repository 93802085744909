import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Text,
  Input,
  HStack,
  Alert,
  Link,
  InputGroup,
  Icon,
  InputLeftElement,
  InputRightAddon,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { MdFindInPage } from 'react-icons/md';
import { RiArrowGoBackFill } from 'react-icons/ri';

const SearchByAddress = ({useCase, _placeholder, label }) => {
  const [addr, setAddr] = useState('');
  const handleAddr = event => setAddr(event.target.value);
  return (
    <>
      <InputGroup size="sm">
        <InputLeftElement children={<SearchIcon color="green.300" />} />

        <Input
          value={addr}
          onChange={handleAddr}
          placeholder={_placeholder}
          size="sm"
        />
        <InputRightAddon children={label} color="green.300" />
      </InputGroup>

      {addr && (
        <Alert status="success" variant="solid">
          <Link as={ReactLink} to={`/${useCase}/${addr}`}>
            <HStack spacing={[0, 3, 8]}>
              <Icon as={MdFindInPage} />
              <Text>{addr}</Text>
              <Icon as={RiArrowGoBackFill} />
            </HStack>
          </Link>
        </Alert>
      )}
    </>
  );
};

export default SearchByAddress;
