import { useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  VStack,
} from '@chakra-ui/react';
import CancelSaleAuction from './CancelSaleAuction';
import FeatureItemByUser from './featured/FeatureItemByUser';

function SellerActions({ itemId, isMarketplace, tokenContract, tokenId, tokenType }) {
  const [showSellerActions, setShowSellerActions] = useState(false);
  const handleShowSellerActions = () => {
    if (showSellerActions) {
      setShowSellerActions(false);
    } else {
      setShowSellerActions(true);
    }
  };
  const SellerActionsButton = () => {
    if (showSellerActions) {
      return (
        <>
          <Button
            size="md"
            colorScheme="green"
            onClick={handleShowSellerActions}
          >
            Hide Seller Actions
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="md"
            colorScheme="green"
            onClick={handleShowSellerActions}
          >
            Seller Actions
          </Button>
        </>
      );
    }
  };
  return (
    <VStack>
      <SellerActionsButton />
      {showSellerActions && (
        <Box
          mt={3}
          display="flex"
          alignItems="baseline"
          style={{
            fontSize: '1.2rem',
            display: 'grid',
            placeItems: 'center',
            border: '8px solid',
            padding: '1rem',
            borderWidth: '10px',
            borderImage:
              'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
          }}
        >
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>Featuring</Tab>
              <Tab>Cancel {isMarketplace ? 'sale' : 'auction'}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <FeatureItemByUser tokenContract={tokenContract} tokenId={tokenId}/>
              </TabPanel>

              <TabPanel>
                <CancelSaleAuction
                  itemId={itemId}
                  isMarketplace={isMarketplace}
                  tokenType={tokenType}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </VStack>
  );
}

export default SellerActions;
