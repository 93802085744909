import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Link, VStack, Box, Wrap, WrapItem, Text } from '@chakra-ui/react';
import getAllCollectionsBy from './elements/getAllCollectionsBy';

function AllCollectionsCreatedBy({ provider, addr }) {
  const [allCollections, setAllCollections] = useState([]);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const _allCollections = await getAllCollectionsBy(provider, addr, true);
    setAllCollections(_allCollections);
  };

  const ShowAllCollections = () => {
    return (
      <VStack spacing={4}>
        {allCollections.length &&
          allCollections.map((c, key) => (
            <Box key={key}>
              <Link as={ReactLink} to={`/collections/${c.collectionId}`}>
                <Box
                  maxW="sm"
                  textAlign="center"
                  style={{
                    display: 'grid',
                    minHeight: '200px',
                    border: '8px solid',
                    padding: '1rem',
                    borderImage:
                      'linear-gradient(45deg, turquoise, greenyellow) 1',
                    marginTop: '20px',
                  }}
                >
                  <Box>
                    <img
                      src={c.collectionImageUrl}
                      style={{
                        width: '300px',
                        margin: 'auto',
                        display: 'block',
                      }}
                    />
                  </Box>

                  <Box mt="8px">
                    <Text fontSize="xl">{c.collectionName}</Text>
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
      </VStack>
    );
  };

  return (
    <VStack>
      <ShowAllCollections />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllCollectionsCreatedBy;
