import AddGalleryBase from '../../../components/actions/NFTs/addGalleryBase';
import { Link as ReactLink, useLocation } from 'react-router-dom';

function IntegrateTokenInGame() {
  const location = useLocation();
  // console.log('location:', location);
  const data = location.state;
  // console.log('data:', data);

  return (
    <AddGalleryBase gameTokenId={data.gameTokenId} gameName={data.gameName} />
  );
}

export default IntegrateTokenInGame;
