import React from 'react';
import MintAndAddToGalleryBase from '../../../components/actions/NFTs/mintAndAddToGalleryBase';
import { Link as ReactLink, useLocation } from 'react-router-dom';

function MintAndIntegrate() {
  const location = useLocation();
  console.log('location:', location);
  const data = location.state;
  console.log('data:', data);
  return <MintAndAddToGalleryBase gameTokenId={data.gameTokenId}/>;
}

export default MintAndIntegrate;
