import React from 'react';
import { Box, FormControl, Input } from '@chakra-ui/react';

const SetContractAddrForm = ({ singleContractAddr, setSingleContractAddr }) => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <Input
            value={singleContractAddr}
            onChange={e => setSingleContractAddr(e.target.value)}
            placeholder="NFT Token Contract Address"
            size="sm"
          />
        </FormControl>
      </Box>
    </>
  );
};

export default SetContractAddrForm;
