import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Stack,
  RadioGroup,
  Radio,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
const AddSaleIdsForm = ({ meta, field, handleMeta, label }) => {
  return (
    <Box style={{ marginBottom: '20px' }}>
      <FormControl isRequired>
        <FormLabel as="legend">
          {label}:
          <Popover>
            <PopoverTrigger>
              <IconButton
                variant="outline"
                colorScheme="green"
                aria-label="Get Info"
                size="xs"
                icon={<FaInfo boxSize={8} />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                Separate {label} with comma, for example: 1, 2, 3
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormLabel>
        <Input
          name={field}
          value={meta[field]}
          onChange={e => handleMeta(field, e.target.value)}
          placeholder={field}
          size="sm"
        />
      </FormControl>
    </Box>
  );
};

export default AddSaleIdsForm;
