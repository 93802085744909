import { ethers } from 'ethers';
import Games from '../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { REACT_APP_GAMES_ADDRESS } from '../../../Constants/ContractAddress';

const getGameTokenInfo = async (provider, gameTokenId) => {
  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  const d = await games.getGameProperties(gameTokenId);
  console.log('d:', d);

  let gameTokenInfo = {};

  if (d.length > 0) {
    // console.log('metadata:', metadata.data);
    let gameName = d.gameName;
    let posterUrl = d.posterUrl;
    let videoUrl = d.videoUrl;
    let description = d.description;
    let externalUrlToGame =
      d.urlToGame == '/magicflowers'
        ? 'https://cozygames.xyz/magicflowers'
        : d.urlToGame;
    let trialUrl =
      d.urlToGame == '/magicflowers'
        ? 'https://cozygames.xyz/magicflowers/magicflowerstrial'
        : d.trialUrl;


    const hasBranch = d.hasBranch;
    // console.log('allowExternalIntegration:', allowExternalIntegration);
    // const gameFeatures = await games.getAllFeaturesByGame(gameTokenId);
    // // console.log('gameFeaturesInfo:', gameFeaturesInfo);

    let newItem = {
      posterUrl,
      videoUrl,
      gameName,
      gameFeaturesExternal: d.featuresExternal,
      gameFeaturesReserved: d.featuresReserved,
      description,
      externalUrlToGame,
      trialUrl,

      hasBranch,
    };

    // console.log('newItem:', newItem);
    // console.log('gameFeatures:', gameFeatures);

    gameTokenInfo = {
      newItem,
    };
  }
  return gameTokenInfo;
};

export default getGameTokenInfo;
