import React, { useEffect } from 'react';
import {
  Engine,
  Scene,
  VideoDome,
  ArcRotateCamera,
  Vector3,
} from '@babylonjs/core';

import '../../styles/Fonts.css';
import { Box } from '@chakra-ui/react';

const Video360 = ({ tokenUri }) => {
  useEffect(() => {
    // Create a Babylon.js engine
    const canvas = document.getElementById('babylon-canvas');
    const engine = new Engine(canvas);
    // Create a scene
    const scene = new Scene(engine);

    var camera = new ArcRotateCamera(
      'Camera',
      -Math.PI / 2,
      Math.PI / 2,
      5,
      Vector3.Zero(),
      scene
    );
    camera.attachControl(canvas, true);

    var videoDome = new VideoDome(
      'videoDome',
      [tokenUri],
      {
        resolution: 32,
        clickToPlay: true,
      },
      scene
    );

    scene.onPointerDown = function () {
      videoDome.videoTexture.video.play();
      scene.onPointerDown = null;
    };

    // Start the engine rendering loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    return () => {
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <Box textAlign="center">
      <canvas
        id="babylon-canvas"
        style={{ width: '100%', height: '100vh', touchAction: 'none' }}
      />
    </Box>
  );
};

export default Video360;
