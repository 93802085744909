import axios from 'axios';
import { OpenseaMeta } from '../../../../utils/constants';
const getTokenMetadata = async _tokenUri => {
  let metadata = _tokenUri.length > 0 ? await axios.get(_tokenUri) : {};
  console.log('metadata:', metadata.data);
  if (Object.keys(metadata).length > 0 && Object.keys(metadata.data).length > 0) {
    metadata = metadata.data;
  } else {
    metadata = OpenseaMeta;
  }
  return metadata;
};

export default getTokenMetadata;
