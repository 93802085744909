import { ethers } from 'ethers';
export const zeroAddress = ethers.constants.AddressZero;
export const OpenseaMeta = {
    "name": " ",
    "description": "",
    "image": "",
    "external_url": "",
    "attributes": [
      {
        "trait_type": "",
        "value": ""
      },
      
    ],
    "background_color": "",
    "animation_url": "",
    "youtube_url": ""
  }
  