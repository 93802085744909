import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Stack,
  RadioGroup,
  Radio,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
import ChooseContractForm from './ChooseContractForm';
import SetContractAddrForm from './SetContractAddrForm';
const AddTokensToCollectionForm = ({
  tokenIds,
  setTokenIds,
  singleContractAddr,
  setSingleContractAddr
}) => {
  return (
    <Box>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">
            Token Id(s):{' '}
            <Popover>
              <PopoverTrigger>
                <IconButton
                  variant="outline"
                  colorScheme="green"
                  aria-label="Get Info"
                  size="xs"
                  icon={<FaInfo boxSize={8} />}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  Separate token Ids with comma, for example: 1, 2, 3
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormLabel>
          <Input
            value={tokenIds}
            onChange={e => setTokenIds(e.target.value)}
            autoComplete="off"
            placeholder="Token Ids to be added to this collection"
            size="sm"
          />
        </FormControl>
      </Box>
      <Box>
        <ChooseContractForm
          singleContractAddr={singleContractAddr}
          setSingleContractAddr={setSingleContractAddr}
          tokenType="Full"
        />

        {singleContractAddr === 'Other Contract Address' && (
          <SetContractAddrForm
            singleContractAddr={singleContractAddr}
            setSingleContractAddr={setSingleContractAddr}
          />
        )}
      </Box>
      {/* <Box>
        <FormControl isRequired>
          <FormLabel as="legend">Token Contract Address:</FormLabel>
          <RadioGroup onChange={setSval} value={sval} colorScheme="green">
            <Stack direction="row">
              <Radio value="1">Magic NFT ERC721 contract</Radio>
              <Radio value="2">Magic NFT ERC1155 contract</Radio>
              <Radio value="3">Other contract</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
      {sval === '3' && (
        <Box>
          <FormControl isRequired>
            <Input
              value={tokenContract}
              onChange={e => setTokenContract(e.target.value)}
              placeholder="NFT Token Contract Address"
              size="sm"
            />
          </FormControl>
        </Box>
      )} */}
    </Box>
  );
};

export default AddTokensToCollectionForm;
