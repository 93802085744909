import React from 'react';
import {
  Box,
  Text,
  VStack,
  Container,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
} from '@chakra-ui/react';
import { GiCutDiamond } from 'react-icons/gi';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import AddGameIntegration from '../../../components/actions/NFTs/gameIntegration/AddGameIntegration';

import UpdateTokensInGame from '../../../components/actions/NFTs/gameIntegration/UpdateTokensInGame';

function GameAction() {
  return (
    <Box>
      <Container style={{ marginTop: '58px' }}>
        <VStack spacing={4}>
          <Box>
            <HomeIcon />
          </Box>
          <Box>
            <Text
              color="green.300"
              fontSize={[39, 58, 66]}
              style={{ fontFamily: 'Lobster' }}
            >
              Game Integration In NFT(s)
            </Text>
          </Box>

          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>Add Game Integration/Compatibility</Tab>
              <Tab>Update Tokens In Game</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HStack>
                  <Icon as={GiCutDiamond} color="green.300" />
                  <AddGameIntegration tokenType="Erc721" />
                </HStack>
                <HStack>
                  <Icon as={GiCutDiamond} color="green.300" />
                  <AddGameIntegration tokenType="Erc1155" />
                </HStack>
              </TabPanel>

              <TabPanel>
                <UpdateTokensInGame />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default GameAction;
