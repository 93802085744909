import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const TokenIdFormSingle = ({ tokenId, setTokenId, label='NFT Id' }) => {
  const handleTokenId = value => {
    setTokenId(value);
  };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">{label}:</FormLabel>

          <NumberInput
            min={0}
            value={tokenId}
            onChange={e => handleTokenId(e)}
            placeholder="NFT ID"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Box>
    </>
  );
};

export default TokenIdFormSingle;
