import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { VStack, Box, Text, Link } from '@chakra-ui/react';
import TokenGameTrialModal from '../TokenGameTrialModal';
import { getIntegratedGamesDetailed } from './getIntegratedGames';
import GamesIntegratedImg from '../../../../../resources/images/valley/games-integrated.gif';
import HideGamesIntegratedImg from '../../../../../resources/images/valley/games-integrated-hide.gif';

function RenderGamesIntegrated({
  provider,
  tokenContract,
  tokenId,
  category,
  player,
}) {
  const [allGames, setAllGames] = useState([]);
  const [showGames, setShowGames] = useState(false);

  const handleShowGames = async () => {
    if (showGames) {
      setShowGames(false);
    } else {
      let _allGames = await getIntegratedGamesDetailed(
        provider,
        tokenContract,
        tokenId,
        player
      );
      // console.log('player:', player)
      // console.log('_allGames:', _allGames);
      setAllGames(_allGames);

      setShowGames(true);
    }
  };

  const RenderGamesButton = () => {
    if (showGames) {
      return (
        <Box
          onClick={handleShowGames}
          as="button"
          style={{ position: 'relative', textAlign: 'center', color: 'white' }}
        >
          <img
            src={HideGamesIntegratedImg}
            alt="Hide Games Integrated"
            style={{ width: '100%' }}
          />
        </Box>
      );
    } else {
      return (
        <Box
          onClick={handleShowGames}
          as="button"
          style={{ position: 'relative', textAlign: 'center', color: 'white' }}
        >
          <img
            src={GamesIntegratedImg}
            alt="Games Integrated"
            style={{ width: '100%' }}
          />
        </Box>
      );
    }
  };

  return (
    <VStack>
      <RenderGamesButton />

      {showGames && allGames.length === 0 && (
        <Text fontSize="xl" color="teal.500">
          Not integrated in any game
        </Text>
      )}

      {showGames &&
        allGames.length > 0 &&
        allGames.map((game, index) => {
          return (
            <Box
              key={index}
              bgGradient="linear(to-r, #82f4b1, #30c67c)"
              p="2"
              rounded="md"
              textAlign="center"
            >
              <VStack>
                <Text as="i" fontSize="md">
                  <Link as={ReactLink} to={`/gamelist/${game.gameTokenId}`}>
                    {game.gameName}
                  </Link>
                </Text>
                <TokenGameTrialModal
                  features={game.features}
                  tokenContract={tokenContract}
                  tokenId={tokenId}
                  category={category}
                  trialUrl={game.trialUrl}
                  branch={game.branch}
                />
              </VStack>
            </Box>
          );
        })}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default RenderGamesIntegrated;
