import { ethers } from 'ethers';
const getNetworkProvider = () => {
  const localNodeUrl = 'http://localhost:8545';
  const amoyUrl = 'https://rpc-amoy.polygon.technology/'

  const provider = new ethers.providers.JsonRpcProvider(amoyUrl);
  // provider = new ethers.providers.AlchemyProvider('homestead', alchemyApiKey);

  return provider;
};

export default getNetworkProvider;
