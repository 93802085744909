// export const REACT_APP_MARKETPLACE_ERC721_ADDRESS='0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0'
// export const REACT_APP_MARKETPLACE_ERC1155_ADDRESS='0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9'
// export const REACT_APP_AUCTION_HOUSE_ADDRESS='0x0165878A594ca255338adfa4d48449f69242Eb8F'
// export const REACT_APP_MAGIC_NFT_ERC721_ADDRESS='0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6'
// export const REACT_APP_MAGIC_NFT_ERC1155_ADDRESS='0x610178dA211FEF7D417bC0e6FeD39F05609AD788'
// export const REACT_APP_MAGIC_FLOWERS_ADDRESS='0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0'
// export const REACT_APP_GAMES_ADDRESS='0x9A676e781A523b5d0C0e43731313A708CB607508'
// export const REACT_APP_GAME_TOKEN_GALLERY_ADDRESS='0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1'
// export const REACT_APP_HELPER_ADDRESS='0x68B1D87F95878fE05B998F19b66F4baba5De1aed'
// export const REACT_APP_FEATURED_ITEMS_ADDRESS='0xc6e7DF5E7b4f2A278906862b61205850344D4e7d'
// export const REACT_APP_COLLECTIONS_ADDRESS='0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1'
// export const REACT_APP_STREAMLINE_ADDRESS='0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f'
// export const REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS='0x7a2088a1bFc9d81c55368AE168C2C02570cB814F'
// export const REACT_APP_MOCK_ERC721_ADDRESS='0xCD8a1C3ba11CF5ECfa6267617243239504a98d90'
// export const REACT_APP_MOCK_ERC1155_ADDRESS='0x2bdCC0de6bE1f7D2ee689a0342D76F52E8EFABa3'

// address on amoy testnet
export const REACT_APP_MARKETPLACE_ERC721_ADDRESS =
  '0x10AdfCf1B9D8336B6c7bEd649aaC6F6f2E136054';
export const REACT_APP_MARKETPLACE_ERC1155_ADDRESS =
  '0xd81D32D5839844caf490f92a785A2C5D30E62c89';
export const REACT_APP_AUCTION_HOUSE_ADDRESS =
  '0x49a7e70934612dC866801B30991C10aaCCFF3B91';
export const REACT_APP_MAGIC_NFT_ERC721_ADDRESS =
  '0x7CBca9B7E484f1e92F9753830593a81b971F6b61';
export const REACT_APP_MAGIC_NFT_ERC1155_ADDRESS =
  '0x87b6A7d131d2a2BEdA71b6C3adC2E0cdF9E4469c';
export const REACT_APP_MAGIC_FLOWERS_ADDRESS =
  '0x43F44fa50314a1E136f4B50e98BA53A201afa9c7';
export const REACT_APP_GAMES_ADDRESS =
  '0x81bf868b501573F65AC159813Ce3DFa1bB8994b1';
export const REACT_APP_GAME_TOKEN_GALLERY_ADDRESS =
  '0x1F805621FF42DE52DaD8EB6eaC485c5Ec81c1797';
export const REACT_APP_HELPER_ADDRESS =
  '0xCfC751dA262A258cd4025Affe07d93698e874164';
export const REACT_APP_FEATURED_ITEMS_ADDRESS =
  '0x9f59db649378C004db2594Af628Db7b4e6bD97cd';
export const REACT_APP_COLLECTIONS_ADDRESS =
  '0x0e61eE1CD68C3bE602579A350F85CfcfDb08fb80';
export const REACT_APP_STREAMLINE_ADDRESS =
  '0x7b6387fF23e65C4E380C17EDC5122Ca988b46991';
export const REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS =
  '0xa730c871Cb6C3AE41014383D90FEA82fA063f226';
export const REACT_APP_MOCK_ERC721_ADDRESS = '';
export const REACT_APP_MOCK_ERC1155_ADDRESS = '';