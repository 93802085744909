import SaleInValley from '../../../components/views/NFTs/elements/singleValleyItem/SaleInValley';
const Auction = () => {
  return (
    <>
      <SaleInValley saleType="Auction" tokenType="Erc721" />
    </>
  );
};

export default Auction;
