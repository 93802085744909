import React from 'react';
const extractVideoId = url => {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;

    if (urlObj.hostname === 'youtu.be') {
      // For shortened URLs
      return pathname.substring(1); // removes the leading '/'
    } else if (pathname.includes('/embed/')) {
      // For embed URLs
      return pathname.split('/embed/')[1];
    } else {
      // For regular watch URLs
      const params = new URLSearchParams(urlObj.search);
      return params.get('v');
    }
  } catch (error) {
    console.error('Invalid URL', error);
    return null;
  }
};


const YouTubeVideo = ({ url }) => {
  const videoId = extractVideoId(url);
  if (!videoId) {
    return <div>Invalid YouTube URL</div>;
  }
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      width="400"
      height="300"
      src={videoSrc}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  );
};

export default YouTubeVideo;
