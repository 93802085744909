import BuyNFTbase from './BuyNFTbase';
const BuyNFTerc1155 = ({ itemId, gameIdsIntegrated }) => {
  return (
    <BuyNFTbase
      itemId={itemId}
      itemPrice={0}
      isErc721={false}
      gameIdsIntegrated={gameIdsIntegrated}
    />
  );
};

export default BuyNFTerc1155;
