import React, { useState } from 'react';
import {
  Link,
  Box,
  Text,
  Stack,
  VStack,
  Grid,
  RadioGroup,
  Radio,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useMultiStyleConfig,
  useTab,
  Button,
} from '@chakra-ui/react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import { TextOnImage1 } from '../../../components/views/NFTs/elements/TextOnImages';
import ValleyItemList from '../../../components/views/NFTs/elements/valleyItems/ValleyItemList';
import ValleyItemListGames from '../../../components/views/NFTs/elements/valleyItems/ValleyItemListGames';
import Search from '../../../components/searches/Search';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import ValleyItemListBase from '../../../components/views/NFTs/elements/valleyItems/ValleyItemListBase';
import { zeroAddress } from '../../../components/utils/helpers';
import FeaturedGalleryScene from '../../../components/views/NFTs/featured/FeaturedGalleryScene';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../components/Constants/ContractAddress';
function SaleBaseGame({ saleType }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const location = useLocation();
  const data = location.state;
  // console.log('data:', data);
  const gameTokenId = Number(data.gameTokenId);
  const tokenContractAddr = REACT_APP_MAGIC_FLOWERS_ADDRESS;

  const [value, setValue] = useState('1');

  const DisplayList = ({ tokenType }) => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    if (value === '1') {
      return (
        <ValleyItemListBase
          provider={provider}
          tokenType={tokenType}
          saleType={saleType}
          marketplaceIds={[]}
          auctionIds={[]}
          byUser={false}
          userAddr=""
          forGame={false}
          forExhibition={false}
          fromSpecificContract={false}
          specificTokenContract={zeroAddress}
          forSpecificGame={true}
          specificGameTokenId={gameTokenId}
        />
      );
    } else if (value === '2') {
      return (
        <ValleyItemListBase
          provider={provider}
          tokenType={tokenType}
          saleType={saleType}
          marketplaceIds={[]}
          auctionIds={[]}
          byUser={false}
          userAddr=""
          forGame={false}
          forExhibition={false}
          fromSpecificContract={true}
          specificTokenContract={tokenContractAddr}
        />
      );
    } else {
      // default
      return (
        <ValleyItemListBase
          provider={provider}
          tokenType={tokenType}
          saleType={saleType}
          marketplaceIds={[]}
          auctionIds={[]}
          byUser={false}
          userAddr=""
          forGame={false}
          forExhibition={false}
          fromSpecificContract={false}
          specificTokenContract={zeroAddress}
          forSpecificGame={true}
          specificGameTokenId={gameTokenId}
        />
      );
    }
  };

  const CustomTab = React.forwardRef((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Button __css={styles.tab} {...tabProps}>
        <Box as="span" mr="2">
          {isSelected ? '😎' : '😐'}
        </Box>
        {tabProps.children}
      </Button>
    );
  });

  return (
    <Box textAlign="center">
      <FeaturedGalleryScene
        domeImage="./textures/artgallery/artgallery3.png"
        featurePage={
          saleType === 'Marketplace' ? 'marketplaceGame' : 'auctionHouseGame'
        }
        gameId={gameTokenId}
        gameName={data.gameName}
        gameVideoUrl={data.gameVideoUrl}
        gamePosterUrl={data.gamePosterUrl}
      />
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <VStack spacing={3}>
            <Search />

            {gameTokenId == 1 && (
              <Box>
                <RadioGroup onChange={setValue} value={value}>
                  <Stack direction="row">
                    <Radio value="1" colorScheme="green">
                      Galleries for Magic Flowers NFTs
                    </Radio>
                    <Radio value="2" colorScheme="green">
                      Magic Flowers NFTs
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            )}

            <Tabs isFitted isLazy colorScheme="green">
              <TabList mb="1em">
                <CustomTab>ERC721</CustomTab>
                <CustomTab>ERC1155</CustomTab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <DisplayList tokenType="Erc721" />
                </TabPanel>
                <TabPanel>
                  <DisplayList tokenType="Erc1155" />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Grid>
      </Box>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default SaleBaseGame;
