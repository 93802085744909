import { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  StackDivider,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import ArtistCollections from '../../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import { addCollectionUrl } from '../../../utils/mintHelpers';
import { extractIntegersFromString } from '../../../utils/helpers';
import CreateNewCollectionForm from '../../../formComponents/CreateNewCollectionForm';
import AddTokensToCollectionForm from '../../../formComponents/AddTokensToCollectionForm';
import RenderModalButton from '../../../formComponents/RenderModalButton';
import RenderModalFooterButton from '../../../formComponents/RenderModalFooterButton';
import AddTokensRadioGroup from '../../../formComponents/AddTokensRadioGroup';
import { REACT_APP_COLLECTIONS_ADDRESS, REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../../Constants/ContractAddress';
const CreateNewCollectionAndAddTokens = ({
  useCase = '',
  tokenContract = '',
  tokenId = 0,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileCollection, setFileCollection] = useState(null);
  const [collectionImageUrl, setCollectionImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [collectionMeta, setCollectionMeta] = useState({
    collectionDescription: '',
    collectionExternalUrl: '',
  });

  const [addToken, setAddToken] = useState('false');
  const [tokenIds, setTokenIds] = useState('');
  const [singleContractAddr, setSingleContractAddr] = useState(
    REACT_APP_MAGIC_NFT_ERC721_ADDRESS
  );

  const create = async () => {
    setLoading(true);
    let collectionUrl;

    // console.log('collectionMeta:', collectionMeta);
    collectionUrl = await addCollectionUrl(fileCollection, collectionMeta);

    // console.log('collectionUrl:', collectionUrl);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const tokenCollectionContract = new ethers.Contract(
        REACT_APP_COLLECTIONS_ADDRESS,
        ArtistCollections.abi,
        signer
      );

      // console.log('tokenCollectionContract:', tokenCollectionContract.address);

      const _tokenIds =
        useCase == 'TokenByUser'
          ? [tokenId]
          : extractIntegersFromString(tokenIds);
      // console.log('_tokenIds:', _tokenIds);

      let _tokenContract =
        useCase == 'TokenByUser' ? tokenContract : singleContractAddr;

      // console.log('_tokenContract:', _tokenContract);

      let collectionUrl = await addCollectionUrl(
        collectionImageUrl,
        collectionMeta
      );

      let transactionList;

      let _addTokens = false;
      if (useCase == 'TokenByUser' || addToken == 'true') {
        _addTokens = true;
      }

      transactionList =
        await tokenCollectionContract.createNewCollectionAndAddTokens(
          newCollectionName,
          collectionUrl,
          _addTokens,
          _tokenContract,
          _tokenIds
        );

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const _title =
    useCase.length > 0
      ? 'Add to New Collection'
      : 'Create New Collection and Add Tokens';

  return (
    <>
      <RenderModalButton label={_title} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{_title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Create New Collection
                </Heading>
                <CreateNewCollectionForm
                  collectionImageUrl={collectionImageUrl}
                  setCollectionImageUrl={setCollectionImageUrl}
                  newCollectionName={newCollectionName}
                  setNewCollectionName={setNewCollectionName}
                  collectionMeta={collectionMeta}
                  setCollectionMeta={setCollectionMeta}
                />
              </Box>

              {useCase.length == 0 && (
                <AddTokensRadioGroup
                  addToken={addToken}
                  setAddToken={setAddToken}
                  formType="collection"
                />
              )}

              {addToken == 'true' && (
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Add Token(s) to Collection
                  </Heading>
                  <AddTokensToCollectionForm
                    tokenIds={tokenIds}
                    setTokenIds={setTokenIds}
                    singleContractAddr={singleContractAddr}
                    setSingleContractAddr={setSingleContractAddr}
                  />
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <RenderModalFooterButton
              loading={loading}
              label="Create"
              create={create}
              onClose={onClose}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateNewCollectionAndAddTokens;
