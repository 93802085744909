import React from 'react';
import '../styles/Fonts.css';
import { Link as ReactLink } from 'react-router-dom';
import { VStack, Wrap, WrapItem, Link, Box } from '@chakra-ui/react';
// import { ReactP5Wrapper } from '@p5-wrapper/react';
// import FlowerLogo from '../components/sketches/FlowerLogo';
// import { GiButterfly, GiTwirlyFlower } from 'react-icons/gi';
// import RenderAboutValley from '../components/views/aboutPage/RenderAboutValley';
import HomeScene from '../components/babylonScenes/homeScenes/HomeScene';
// import {
//   GamingFrame,
//   MagicalFrame,
//   SaleFrame,
// } from '../components/views/NFTs/elements/frames/dreamMintingStuio/homeFrame';
import RenderHomeIntro from '../components/views/aboutPage/RenderHomeIntro';
import WelcomeSign from '../resources/images/valley/welcome1.png';
import MarketplaceSign from '../resources/images/HomeAnimalSign/1.png';
import GameSign from '../resources/images/HomeAnimalSign/2.png';
import AuctionSign from '../resources/images/HomeAnimalSign/3.png';
import MeSign from '../resources/images/HomeAnimalSign/7.png';
import DreamSign from '../resources/images/HomeAnimalSign/5.png';
import MembershipSign from '../resources/images/HomeAnimalSign/6.png';
import ScrollDownButton from '../components/utils/ScrollDownButton';
import Campaign from '../components/views/misc/Campaign';
const Home = () => {
  const RenderSign = ({ imgUrl, altText, toUrl }) => {
    return (
      <Link as={ReactLink} to={toUrl}>
        <img
          src={imgUrl}
          alt={altText}
          style={{ width: '128px', height: '128px' }}
        />
      </Link>
    );
  };
  return (
    <>
      <Box textAlign="center">
        <HomeScene />
      </Box>

      <VStack spacing={6} mt={6}>
        <img src={WelcomeSign} alt="Welcome to Magic NFT Valey" />

        <div id="content-below"></div>

        <Wrap>
          <WrapItem>
            <RenderSign
              imgUrl={MarketplaceSign}
              altText="Marketplace"
              toUrl="/marketplace"
            />
          </WrapItem>
          <WrapItem>
            <RenderSign
              imgUrl={AuctionSign}
              altText="AuctionHouse"
              toUrl="/auctions"
            />
          </WrapItem>
          <WrapItem>
            <RenderSign
              imgUrl={DreamSign}
              altText="Dream Workshop"
              toUrl="/dreamstudio"
            />
          </WrapItem>

          <WrapItem>
            <RenderSign
              imgUrl={GameSign}
              altText="Games Showcase"
              toUrl="/gamelist"
            />
          </WrapItem>
          {/* <WrapItem>
            <RenderSign
              imgUrl={FlowerSign}
              altText="Magic Flowers"
              toUrl="/magicflowers"
            />
          </WrapItem> */}
          <WrapItem>
            <RenderSign
              imgUrl={MembershipSign}
              altText="Membership"
              toUrl="/membership"
            />
          </WrapItem>

          <WrapItem>
            <RenderSign imgUrl={MeSign} altText="Membership" toUrl="/user" />
          </WrapItem>
        </Wrap>

        <RenderHomeIntro />
        <Campaign isDetailed={false} />
        {/* <img
          src={CampaignImage}
          alt="Join Our Indiegogo Campaign"
          style={{ width: '358px', height: 'auto' }}
        /> */}
      </VStack>
    </>
  );
};

export default Home;
