import React from 'react';
import Iframe from './Iframe';

function BabylonViewer(props) {
  const htmlContent = `
    <html>
    <head>
      <title>Babylon.js Viewer - Display a 3D model</title>
      <script src="https://viewer.babylonjs.com/viewer.js"></script> 
    </head>
    <body>
      <babylon model="${props.modelUrl}" templates.main.params.fill-screen="true"></babylon>
    </body>
    </html>
  `;

  return <Iframe content={htmlContent} />;
}

export default BabylonViewer;
