import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

const SellingOptionForm = ({ forSale, setForSale, tokenType, useCase }) => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Choose Selling Option</FormLabel>
          <RadioGroup onChange={setForSale} value={forSale} colorScheme="green">
            <Stack direction="row">
              <Radio value="1">List for Sale</Radio>
              {tokenType === 'Erc721' && (
                <Radio value="2">List for Auction</Radio>
              )}
              {tokenType === 'Erc1155' && (
                <Radio value="2" isDisabled>
                  List for Auction
                </Radio>
              )}

              {useCase !== 'noMint' && (
                <Radio value="3">Keep for Future Sale/Auction</Radio>
              )}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default SellingOptionForm;
