import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  VStack,
} from '@chakra-ui/react';
import ListIntegrateTokensBase from '../ListIntegrateTokensBase';

const AddGameIntegration = ({
  tokenType,
  tokenContract = '',
  tokenId = 0,
  useCase = '',
}) => {
  const _title =
    useCase == 'TokenByUser'
      ? 'Integrate in Game'
      : `Integrate ${tokenType} Token(s) in Game`;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} colorScheme="green" variant="ghost">
        {_title}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <ListIntegrateTokensBase
                tokenType={tokenType}
                specificGameTokenId={0}
                useCase={useCase}
                tokenContract={tokenContract}
                tokenId={tokenId}
                onlyIntegration={true}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddGameIntegration;
