import AuctionItemErc721Base from './AuctionItemErc721Base';
// import AuctionMagicFlower from './AuctionMagicFlower';
// import MarketplaceItemErc721Base from './MarketplaceItemErc721Base';
// import MarketplaceMagicflower from './MarketplaceMagicflower';

function AuctionItemErc721({
  provider,
  item,
  metadata,
  itemCollection,
  itemPriceHistory,
  tokenType,
  saleType,
}) {
  const RenderItem = () => {
    // if (
    //   item.tokenContractAddress === REACT_APP_MAGIC_FLOWERS_ADDRESS
    // ) {
    //   return (
    //     <AuctionMagicFlower
    //       item={item}
    //       itemCollection={itemCollection}
    //       itemPriceHistory={itemPriceHistory}
    //       tokenType={tokenType}
    //       saleType={saleType}
    //     />
    //   );
    // } else {
    return (
      <AuctionItemErc721Base
        provider={provider}
        item={item}
        metadata={metadata}
        itemCollection={itemCollection}
        itemPriceHistory={itemPriceHistory}
        tokenType={tokenType}
        saleType={saleType}
      />
    );
    // }
  };

  return <RenderItem />;
}

export default AuctionItemErc721;
