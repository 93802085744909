import { useState } from 'react';
import { ethers } from 'ethers';
import { VStack, Box, Text, Tooltip } from '@chakra-ui/react';
import WoodHeartFrame from '../../../../../../resources/images/frames/woodFrame/woodHeartFrame1.png';

function RenderMyBid(props) {
  // console.log('props.lastHighestBidder', props.lastHighestBidder)
  const [showBid, setShowBid] = useState(false);
  const [myBidInfo, setMyBidInfo] = useState(0);

  const viewBid = async () => {
    const provider = props.provider;
    const signer = provider.getSigner();
    const signer_address = await signer.getAddress();

    const bid =
      props.lastHighestBidder == signer_address
        ? Number(props.lastHighestBid)
        : 0;
    // console.log('bid:', bid);

    setMyBidInfo(bid);
  };

  const handleShowBid = () => {
    if (showBid) {
      setShowBid(false);
    } else {
      setShowBid(true);
      viewBid();
    }
  };

  const ShowBidButton = () => {
    const _title = showBid ? 'Hide My Bid' : 'My Bid';
    return (
      <Box
        onClick={handleShowBid}
        as="button"
        style={{ position: 'relative', textAlign: 'center', color: 'white' }}
      >
        <img src={WoodHeartFrame} style={{ width: '100%' }} />
        <div
          style={{
            position: 'absolute',
            top: '58%',
            left: '47%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Text color="white" fontSize="2xl" style={{ fontFamily: 'Lobster' }}>
            {_title}
          </Text>
        </div>
      </Box>
    );
  };

  const RenderBid = () => {
    if (myBidInfo > 0) {
      return (
        <VStack>
          <Text
            fontSize={[24, 36, 48]}
            color="teal.500"
            style={{ fontFamily: 'Lobster' }}
          >
            Your bid:
          </Text>

          <Tooltip
            label="Your bid is currently the highest bid."
            aria-label="A tooltip"
          >
            <Box
              display="flex"
              alignItems="baseline"
              style={{
                fontSize: '1.2rem',
                display: 'grid',
                placeItems: 'center',
                border: '8px solid',
                padding: '1rem',
                borderWidth: '10px',
                borderImage:
                  'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
              }}
            >
              {myBidInfo} ether
            </Box>
          </Tooltip>
        </VStack>
      );
    } else {
      return (
        <VStack>
          <Text
            fontSize={[24, 36, 48]}
            color="teal.500"
            style={{ fontFamily: 'Lobster' }}
          >
            Your bid:
          </Text>
          <Tooltip
            label="You haven't placed any bid, or your bid has been returned to you due
            to a higher bid placed."
            aria-label="A tooltip"
          >
            <Box
              display="flex"
              alignItems="baseline"
              style={{
                fontSize: '1.2rem',
                display: 'grid',
                placeItems: 'center',
                border: '8px solid',
                padding: '1rem',
                borderWidth: '10px',
                borderImage:
                  'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
              }}
            >
              {myBidInfo} ether
            </Box>
          </Tooltip>
        </VStack>
      );
    }
  };

  return (
    <VStack>
      <ShowBidButton />
      {showBid && <RenderBid />}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default RenderMyBid;
