import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

const BatchOptionsForm = ({ batch, setBatch, label }) => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">{label}</FormLabel>
          <RadioGroup onChange={setBatch} value={batch} colorScheme="green">
            <Stack direction="row">
              <Radio value="1">One Single NFT</Radio>
              <Radio value="2">NFTs in Bulk</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default BatchOptionsForm;
