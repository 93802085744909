import React, { useEffect, useState } from 'react';
import { HStack, Box, Text } from '@chakra-ui/react';

function Timer(props) {
  const calculateTimeLeft = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const difference = Number(props.gameStartTime) - currentTime;
    let timeLeft = {};

    if (difference > 0) {
      var _d = Math.floor(difference / (24 * 3600));
      var _h = Math.floor((difference % (24 * 3600)) / 3600);
      var _m = Math.floor((difference % 3600) / 60);
      var _s = Math.floor(difference % 60);
      const days =
        _d === 0 ? '' : _d < 10 ? '0' + _d.toString() : _d.toString();
      const hours =
        _h === 0 ? '' : _h < 10 ? '0' + _h.toString() : _h.toString();
      const minutes =
        _m === 0 ? '' : _m < 10 ? '0' + _m.toString() : _m.toString();
      const seconds = _s < 10 ? '0' + _s.toString() : _s.toString();
      timeLeft = {
        days,
        hours,
        minutes,
        seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const digits = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    digits.push([timeLeft[interval], interval.toString().slice(0, 1)]);
  });

  return (
    <div>
      <Box>
        <HStack>
          <Text
            bgGradient="linear(to-l, #FF0080, #7928CA)"
            bgClip="text"
            fontSize="mg"
            fontWeight="extrabold"
            as="i"
          >
            {digits.map((d, key) => (
              <span key={key} style={{ paddingLeft: '3px' }}>
                {d[0]} {d[1]}
              </span>
            ))}
          </Text>
        </HStack>
      </Box>
    </div>
  );
}

export default Timer;
