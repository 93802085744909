import {
  Box,
  Stack,
  StackDivider,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Input
} from '@chakra-ui/react';
import RenderExistingCollectionForm from './RenderExistingCollectionForm';
import CollectionModeForm from './CollectionModeForm';
import CollectionUrlForm from './CollectionUrlForm';

const CollectionForm = ({
  newCollectionUrl,
  setNewCollectionUrl,
  setCollectionIndex,
  newCollectionName,
  setNewCollectionName,
  allCollections,
  setAllCollections,
  collectionMode,
  setCollectionMode,
  useCase,
  isForCreatorCollection,
  setIsForCreatorCollection,
}) => {
  return (
    <Stack divider={<StackDivider />} spacing="4" mt="6">
      <Box>
        <CollectionModeForm
          collectionMode={collectionMode}
          setCollectionMode={setCollectionMode}
          useCase={useCase}
        />

        {collectionMode === '2' && (
          <RenderExistingCollectionForm
            setCollectionIndex={setCollectionIndex}
            allCollections={allCollections}
            setAllCollections={setAllCollections}
          />
        )}
        {collectionMode === '3' && (
          <>
            <Box mt="10px" mb="20px">
              <FormControl isRequired>
                <FormLabel as="legend">Choose Collection Type</FormLabel>
                <RadioGroup
                  onChange={setIsForCreatorCollection}
                  value={isForCreatorCollection}
                >
                  <Stack direction="row">
                    <Radio value="1">Creator Collection</Radio>
                    <Radio value="2">Seller Collection</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box style={{ marginBottom: '20px' }}>
              <FormControl isRequired>
                <FormLabel as="legend">Name of New Collection:</FormLabel>

                <Input
                  value={newCollectionName}
                  onChange={event => setNewCollectionName(event.target.value)}
                  placeholder="New Collection Name"
                  size="sm"
                />
              </FormControl>
            </Box>
            <CollectionUrlForm
              newCollectionUrl={newCollectionUrl}
              setNewCollectionUrl={setNewCollectionUrl}
            />
            {/* <CreateNewCollectionForm
              collectionImageUrl={collectionImageUrl}
              setCollectionImageUrl={setCollectionImageUrl}
              newCollectionName={newCollectionName}
              setNewCollectionName={setNewCollectionName}
              collectionMeta={collectionMeta}
              setCollectionMeta={setCollectionMeta}
            />{' '} */}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default CollectionForm;
