import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  AccordionPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Text,
} from '@chakra-ui/react';
export const HowToFeatureNFT = () => {
  return (
    <AccordionPanel pb={4}>
      To showcase your NFT, simply follow these steps:
      <OrderedList>
        <ListItem>
          Go to the Dream Minting Studio and select 'Feature NFT'. This will
          take you to the feature items page.
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          located in the top right corner. Choose your preferred wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed. Connection is secured through WalletConnect, ensuring
          safety.
        </ListItem>
        <ListItem>
          Choose the page where you want your NFT to be showcased.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          created within Dream Minting Studio in Magic NFT Valley, select either
          'Magic NFT Erc721 contract' or 'Magic NFT Erc1155 contract' based on
          the NFT type. For NFTs created elsewhere, select 'Other contract' and
          input the contract address.
        </ListItem>
        <ListItem>Enter the NFT ID.</ListItem>
        <ListItem>
          Select a Timeslot. Click 'Check Newest Available Time Slots for
          Featuring NFT' to see a list of available slots. Up to 6 slots will be
          displayed, starting from the most recent. You can select more than one
          timeslot.
        </ListItem>
        <ListItem>Click 'Submit' to finalize the process.</ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchListForSale = () => {
  return (
    <AccordionPanel pb={4}>
      To list multiple existing NFTs for sale, navigate to the{' '}
      <Link as={ReactLink} to="/create/listErc721">
        listing page
      </Link>
      , and select 'NFTs in Bulk' at the top of the form. All NFTs must
      originate from the same NFT contract. After completing the form for
      'NFT#1,' click 'Add Token,' which will reveal a form for 'NFT#2.' Continue
      this process until you've provided all the necessary information for each
      NFT. If you need to remove a token, simply click 'Remove this token' on
      the corresponding form. Finally, click 'Create' to complete the listing
      process. For further guidance, please refer to '1. How to list an NFT for
      sale?' in the documentation.
    </AccordionPanel>
  );
};

export const ImageUrl = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        When minting your NFT in Dream Minting Studio, you'll be prompted to
        supply a URL linking to the image of your NFT. Magic NFT Valley operates
        as a fully decentralized platform, empowering creators with complete
        control over their NFT content. By opting to provide a URL to your NFT's
        image instead of hosting it directly on the platform, you retain full
        autonomy over your NFT.
      </Text>
    </AccordionPanel>
  );
};

export const WhatIsFeaturingItem = () => {
  return (
    <AccordionPanel pb={4}>
      Featuring an item involves showcasing it prominently. A featured item will
      stand out in two key ways:
      <OrderedList>
        <ListItem>
          A featured item will be positioned within the 3D scene at the top of
          the page, making it the first thing seen by a viewer.
        </ListItem>
        <ListItem>
          Additionally, a featured item will appear in the 'Spotlight' section,
          situated directly above the main content of the page.
        </ListItem>
      </OrderedList>
      Clicking on a featured item will redirect the viewer to its individual
      page.
    </AccordionPanel>
  );
};

// 'marketplace',
//   'auctionHouse',
//   'marketplaceGame',
//   'auctionHouseGame',
//   'gameNfts',
//   'gameList',

export const FeaturingNFTs = () => {
  return (
    <AccordionPanel pb={4}>
      NFTs can be featured on various pages to enhance visibility:
      <OrderedList>
        <ListItem>
          Marketplace: NFTs available for sale can be featured on the
          'Marketplace' page.
        </ListItem>
        <ListItem>
          Auction House: NFTs up for auction can be featured on the 'Auction
          House' page.
        </ListItem>
        <ListItem>
          Game-specific Marketplace: NFTs integrated into a game and available
          for sale can be featured on the 'Marketplace' page dedicated to that
          game.
        </ListItem>
        <ListItem>
          Game-specific Auction House: NFTs integrated into a game and up for
          auction can be featured on the 'Auction House' page dedicated to that
          game.
        </ListItem>
        <ListItem>
          Game NFTs: NFTs integrated into a game and available for sale or
          auction can be featured on the 'Game NFTs' page.
        </ListItem>
      </OrderedList>
      Featuring your NFT can significantly enhance its exposure to users within
      the NFT ecosystem.
    </AccordionPanel>
  );
};

export const FeaturingGame = () => {
  return (
    <AccordionPanel pb={4}>
      A game showcased can be featured on the 'Game Showcase' page. Highlighting
      your game in this manner can significantly enhance its exposure to
      potential players.
    </AccordionPanel>
  );
};

export const FeaturingNFTcontent = () => {
  return (
    <AccordionPanel pb={4}>
      NFTs are showcased in various formats, each with its own presentation:
      <OrderedList>
        <ListItem>
          Image: If your NFT is an image, it will be displayed on a board within
          the 3D scene at the top of the page, and also appear as a standard
          image in the 'Spotlight' section.
        </ListItem>
        <ListItem>
          Video: If your NFT is a video, it will play on a board within the 3D
          scene at the top of the page. The video and its controls will also be
          visible in the 'Spotlight' section. For 360-degree videos, viewers can
          rotate within the video.
        </ListItem>
        <ListItem>
          Music: If your NFT is a piece of music, the preview image will be
          displayed on a crystal ball within the 3D scene at the top of the
          page. Clicking on the crystal ball will play the music. The preview
          image and audio controls will also appear in the 'Spotlight' section.
        </ListItem>
        <ListItem>
          3D Model: If your NFT is a 3D model, it will be presented as such in
          the 3D scene at the top of the page, and also in the 'Spotlight'
          section.
        </ListItem>
        <ListItem>
          3D Environment Texture: If your NFT is a 3D environment texture, it
          will be displayed on a crystal ball in the 3D scene at the top of the
          page. It will appear as a 3D environment texture in the 'Spotlight'
          section, allowing users to rotate and zoom in/out.
        </ListItem>
        <ListItem>
          Other NFT Content: It is highly recommended to provide a preview image
          for any other NFT content. This preview image will be showcased on a
          board within the 3D scene at the top of the page, and also appear in
          the 'Spotlight' section.
        </ListItem>
      </OrderedList>
      Featuring your NFT can significantly increase its exposure to users within
      the NFT ecosystem. Clicking on a featured item will redirect the viewer to
      its individual page.
    </AccordionPanel>
  );
};
