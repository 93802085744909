import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Text,
  VStack,
  HStack,
  Link,
  Container,
  Icon,
  Button,
  Wrap,
  WrapItem,
  Tooltip,
} from '@chakra-ui/react';
import { GiCutDiamond, GiSunflower } from 'react-icons/gi';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';

function AddGalleryBase({ gameTokenId, gameName }) {
  const MintAndAddToGalleryButton = () => {
    const data = { gameTokenId, gameName };
    return (
      <Link as={ReactLink} to={`/gamelist/mintgallery`} state={data}>
        <Button colorScheme="green" variant="outline">
          Mint and Integrate in Game
        </Button>
      </Link>
    );
  };

  const IntegrateTokensButton = () => {
    const data = { gameTokenId, gameName };
    return (
      <Link as={ReactLink} to={`/gamelist/integratetokens`} state={data}>
        <Button colorScheme="green" variant="outline">
          Integrate Token in Game
        </Button>
      </Link>
    );
  };
  return (
    <Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570546/forest2_xqire2.png"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      <Container style={{ marginTop: '58px' }}>
        <VStack spacing={4}>
          <Box>
            <HomeIcon />
          </Box>
          <Box>
            <Text
              color="green.300"
              fontSize={[39, 58, 66]}
              style={{ fontFamily: 'Lobster' }}
            >
              Integrate NFTs in {gameName}
            </Text>
          </Box>
          <HStack>
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          </HStack>

          <Wrap>
            <WrapItem>
              <IntegrateTokensButton />
            </WrapItem>
            <WrapItem>
              <MintAndAddToGalleryButton />
            </WrapItem>
          </Wrap>

          <Box mt={6}>
            <Text fontSize="sm">
              Back to{' '}
              <Link
                color="teal.500"
                as={ReactLink}
                to={`/gamelist/${gameTokenId}`}
              >
                {gameName}
              </Link>
            </Text>
          </Box>
        </VStack>
      </Container>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default AddGalleryBase;
