import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Games from '../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { Link, VStack, Text, Box } from '@chakra-ui/react';
// import { Player } from 'video-react';
import '../../../../styles/video-react.css';
import DetailButton from '../elements/DetailButton';
import { REACT_APP_GAMES_ADDRESS } from '../../../Constants/ContractAddress'

const GameTokenList = ({ provider }) => {
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const games = new ethers.Contract(
      REACT_APP_GAMES_ADDRESS,
      Games.abi,
      provider
    );

    const data = await games.allGamesProperties();
    // console.log('data:', data);

    let newItems = [];

    for (let i = 0; i < data.length; i++) {
      let _newItem = {};
      _newItem.gameName = data[i].gameName;
      _newItem.gameUrl = data[i].gameUrl;

      _newItem.posterUrl = data[i].posterUrl;
      _newItem.videoUrl = data[i].videoUrl;

      _newItem.gameTokenId = i + 1;
      _newItem.cancelled = data[i].cancelled;
      newItems.push(_newItem);
    }

    // console.log('newItems:', newItems);

    setAllItems(newItems);
  };

  const RenderImage = ({ item }) => {
    // return <Player playsInline poster={item.posterUrl} src={item.videoUrl} />;
    return (
      <Box display="flex" alignItems="baseline" style={{ overflow: 'hidden' }}>
        <img
          src={item.posterUrl}
          alt={item.posterUrl}
          className="hover-zoom"
          style={{ width: '400px' }}
        />
      </Box>
    );
  };

  const RenderGameTokens = () => {
    // console.log('props:', props);
    return (
      <VStack>
        {allItems.length &&
          allItems.map((item, key) => (
            <Box
              key={key}
              w="400px"
              style={{
                display: 'grid',
                minHeight: '200px',
                border: '8px solid',
                padding: '1rem',
                borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
                marginTop: '20px',
              }}
            >
              <Box>
                <RenderImage item={item} />
              </Box>

              <Box
                textAlign="center"
                alignItems="baseline"
                style={{ marginTop: '15px' }}
              >
                <Text style={{ fontFamily: 'Lobster' }} fontSize="2xl">
                  {item.gameName}
                </Text>
              </Box>
              <Box mt={3}>
                <Link as={ReactLink} to={`/gamelist/${item.gameTokenId}`}>
                  <DetailButton buttonSize="lg" label="Details" />
                </Link>
              </Box>
            </Box>
          ))}
      </VStack>
    );
  };

  return (
    <VStack>
      <RenderGameTokens />
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
};

export default GameTokenList;
