import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  AccordionPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Text,
} from '@chakra-ui/react';

export const ListForAuction = () => {
  return (
    <AccordionPanel pb={4}>
      To list an existing NFT for auction, please follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to{' '}
          <Link as={ReactLink} to="/create/listErc721">
            the page for listing an existing NFT for auction
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          in the top right corner, then select your desired wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed. Connection is facilitated through WalletConnect, a trusted
          protocol for security.
        </ListItem>
        <ListItem>Choose 'List for Auction' for the 'Selling Option'.</ListItem>
        <ListItem>
          Indicate whether the NFT is intended for integration into a game. If
          so, provide the corresponding Game ID.
        </ListItem>
        <ListItem>
          Specify if the NFT includes any complimentary tokens to be included in
          the sale. Note that while the NFT has a price, any accompanying tokens
          do not.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          minted in Dream Minting Studio within Magic NFT Valley, select 'Magic
          NFT Erc721 contract' or 'Magic NFT Erc1155 contract' depending on the
          NFT type. For NFTs minted elsewhere, choose 'Other contract' and input
          the contract address.
        </ListItem>
        <ListItem>
          Fill out the form below with the NFT ID, its starting price, minimum
          increment in Matic, bid waiting time, and Bidding Bonus Percentage.
        </ListItem>
        <ListItem>
          If the NFT includes complimentary tokens, complete the Complementary
          Tokens form. Provide the contract address for the complimentary tokens
          and their corresponding Token IDs. Specify whether the contract is
          ERC721 or ERC1155. Then, click the "Add Contract and Token IDs" button
          to include these tokens in the NFT listing. If you have multiple
          complimentary token contracts, repeat the process for each one. All
          added complementary tokens will be displayed at the top of the form.
          For each added token, you can verify its correctness by clicking the
          "Verify Token" button. The token's image will be displayed beneath its
          Token ID. To remove a complimentary token, use the "Remove Token"
          button. Alternatively, clicking "Remove Contract" will delete all
          tokens associated with that specific complimentary token contract. Use
          caution when using "Remove All Contracts," as it will remove all
          tokens from every complimentary token contract added.
        </ListItem>
        <ListItem>Click the 'Create' button to complete the listing.</ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the sale. Follow the
          instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchListForAuction = () => {
  return (
    <AccordionPanel pb={4}>
      To list multiple existing NFTs for auction, navigate to the{' '}
      <Link as={ReactLink} to="/create/listErc721">
        listing page
      </Link>
      , and select 'NFTs in Bulk' at the top of the form. All NFTs must
      originate from the same NFT contract. After completing the form for
      'NFT#1,' click 'Add Token,' which will reveal a form for 'NFT#2.' Continue
      this process until you've provided all the necessary information for each
      NFT. If you need to remove a token, simply click 'Remove this token' on
      the corresponding form. Finally, click 'Create' to complete the listing
      process. For further guidance, please refer to '1. How to list an NFT for
      auction?' in the documentation.
    </AccordionPanel>
  );
};

export const BiddingBonus = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        Bidding Bonus is a beneficial feature in auctions that fosters a win-win
        scenario for all involved parties. Auction owners have the option to
        implement a bidding bonus, which is a percentage of the minimum
        increment set by the auction owner for each bid placed in the auction.
        For instance, if the minimum increment is set at 2 MATIC, a 30% bidding
        bonus percentage results in a 0.6 MATIC bonus distributed to a bidder
        whenever a bid higher than theirs is placed. This means that if a
        bidder's bid is surpassed by a higher bid, not only do they receive
        their original bid amount back, but they also receive a fixed bonus for
        each bid they place. The presence of a bidding bonus not only
        incentivizes bidders but also encourages their active participation in
        the auction.
      </Text>
      <Text>
        Moreover, Bidding bonus is beneficial for the auction owner since it
        attracts more participants to the auction, thereby increasing
        competition and potentially raising the final sale price of the NFT.
        Additionally, the auction owner has control over setting the amount of
        the bidding bonus, which can effectively guide bidders through the
        bidding process and prevent any misunderstandings or misinterpretations
        that may lead bidders to gamble for a higher bid solely to receive a
        larger bonus.
      </Text>
    </AccordionPanel>
  );
};
