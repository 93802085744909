import { Box, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { Player } from 'video-react';
const GamePosterVideoForm = ({ meta, handleMeta, label='Game' }) => {
  const RenderPosterUrlForm = () => {
    const _placeholder = `Url to ${label} Poster`
    return (
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Url to {label} Poster:</FormLabel>

          <Input
            name="posterUrl"
            value={meta.posterUrl}
            onChange={e => handleMeta('posterUrl', e.target.value)}
            placeholder={_placeholder}
            size="sm"
          />
        </FormControl>
      </Box>
    );
  };

  const RenderVideoUrlForm = () => {
    const _placeholder = `Url to ${label} Introduction Video`
    return (
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Url to {label} Introduction Video:</FormLabel>

          <Input
            name="videoUrl"
            value={meta.videoUrl}
            onChange={e => handleMeta('videoUrl', e.target.value)}
            placeholder={_placeholder}
            size="sm"
          />
        </FormControl>
      </Box>
    );
  };
  return (
    <>
      <RenderPosterUrlForm />
      <img src={meta.posterUrl} />
      <RenderVideoUrlForm />
      {meta.videoUrl.length > 0 && <Player playsInline src={meta.videoUrl} />}
    </>
  );
};

export default GamePosterVideoForm;
