import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useState } from 'react';
import { ethers } from 'ethers';
import AuctionHouse from '../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import MarketplaceErc721 from '../../../../contracts/APIs/MarketplaceERC721Interface.sol/MarketplaceERC721Interface.json';
import MarketplaceErc1155 from '../../../../contracts/APIs/MarketplaceERC1155Interface.sol/MarketplaceERC1155Interface.json';
import { REACT_APP_AUCTION_HOUSE_ADDRESS, REACT_APP_MARKETPLACE_ERC1155_ADDRESS, REACT_APP_MARKETPLACE_ERC721_ADDRESS } from '../../../Constants/ContractAddress';
const CancelSaleAuction = ({ itemId, isMarketplace, tokenType }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  console.log('itemId:', itemId);

  const cancel = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();

    try {
      let _contract;
      if (isMarketplace) {
        if (tokenType === 'Erc721') {
          _contract = new ethers.Contract(
            REACT_APP_MARKETPLACE_ERC721_ADDRESS,
            MarketplaceErc721.abi,
            signer
          );
        } else {
          _contract = new ethers.Contract(
            REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
            MarketplaceErc1155.abi,
            signer
          );
        }
      } else {
        _contract = new ethers.Contract(
          REACT_APP_AUCTION_HOUSE_ADDRESS,
          AuctionHouse.abi,
          signer
        );
      }

      let transaction;
      if (isMarketplace) {
        transaction = await _contract.cancelSale(itemId);
      } else {
        transaction = await _contract.cancelAuction(itemId);
      }

      await transaction.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    const _title = isMarketplace ? 'Cancel Sale' : 'Cancel Auction';
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="red"
          variant="outline"
          size="md"
          mr={3}
        >
          {_title}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={cancel}
          colorScheme="red"
          variant="outline"
          size="md"
          mr={3}
        >
          {_title}
        </Button>
      );
    }
  };

  return (
    <>
      <Box>
        <RenderButton />
      </Box>
    </>
  );
};

export default CancelSaleAuction;
