function convertFeatureStringToArray(str, mode) {
  // Convert the string to an array of strings and remove empty strings
  let arr = str
    .split(',')
    .map(s => s.trim())
    .filter(Boolean);

  // Rearrange the array based on the mode
  switch (mode) {
    case 'ascending':
      arr.sort((a, b) => a.localeCompare(b));
      break;
    case 'descending':
      arr.sort((a, b) => b.localeCompare(a));
      break;
    case 'original':
      // No action needed, keep the original order
      break;
    default:
      throw new Error(
        'Invalid mode. Mode must be "ascending", "descending", or "original".'
      );
  }

  return arr;
}

export default convertFeatureStringToArray;

// Example usage:
// let str = 'banana, apple, grape, orange';
// console.log(rearrangeString(str, 'ascending')); // Output: ['apple', 'banana', 'grape', 'orange']
// console.log(rearrangeString(str, 'descending')); // Output: ['orange', 'grape', 'banana', 'apple']
// console.log(rearrangeString(str, 'original')); // Output: ['banana', 'apple', 'grape', 'orange']
