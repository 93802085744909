import React, { useEffect } from 'react';
import {
  Engine,
  Scene,
  PhotoDome,
  ArcRotateCamera,
  Vector3,
  PointerEventTypes,
  EquiRectangularCubeTexture,
  PBRMaterial,
  Color3,
  MeshBuilder
} from '@babylonjs/core';

import '../../styles/Fonts.css';
import { Box } from '@chakra-ui/react';

const DomeScene = ({ tokenUri }) => {
  useEffect(() => {
    // Create a Babylon.js engine
    const canvas = document.getElementById('babylon-canvas');
    const engine = new Engine(canvas);
    // Create a scene
    const scene = new Scene(engine);

    var camera = new ArcRotateCamera(
      'Camera',
      -Math.PI / 2,
      Math.PI / 2,
      5,
      Vector3.Zero(),
      scene
    );
    camera.attachControl(canvas, true);
    camera.inputs.attached.mousewheel.detachControl(canvas);

    var tickCount = -240,
      zoomLevel = 1;

    var dome = new PhotoDome(
      'testdome',
      tokenUri,
      {
        resolution: 32,
        size: 1000,
        useDirectMapping: false,
      },
      scene
    );

    const eqTexture = new EquiRectangularCubeTexture(
      // './textures/valley/night/valley_night_small.png',
      tokenUri,
      scene,
      128
    );

    const makePbrMaterial = (_texture, scene) => {
      const pbr = new PBRMaterial('metal', scene);
      pbr.reflectionTexture = _texture;
      pbr.microSurface = 0.96;
      pbr.reflectivityColor = new Color3(0.85, 0.85, 0.85);
      pbr.albedoColor = new Color3(0.01, 0.01, 0.01);
      pbr.iridescence.isEnabled = true;
  
      return pbr;
    };

    var sphere = MeshBuilder.CreateSphere(
      'sphere',
      { diameter: 0.88, segments: 128 },
      scene
    );
    var pbr = makePbrMaterial(eqTexture, scene)
    sphere.material = pbr

    scene.registerAfterRender(function () {
      tickCount++;
      if (zoomLevel == 1) {
        if (tickCount >= 0) {
          dome.fovMultiplier = Math.sin(tickCount / 100) * 0.5 + 1.0;
        }
      } else {
        dome.fovMultiplier = zoomLevel;
      }
    });

    scene.onPointerObservable.add(function (e) {
      if (dome === undefined) {
        return;
      }
      zoomLevel += e.event.wheelDelta * -0.0005;
      if (zoomLevel < 0) {
        zoomLevel = 0;
      }
      if (zoomLevel > 2) {
        zoomLevel = 2;
      }
      if (zoomLevel == 1) {
        tickCount = -60;
      }
    }, PointerEventTypes.POINTERWHEEL);

    // Start the engine rendering loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    return () => {
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <Box textAlign="center">
      <canvas
        id="babylon-canvas"
        style={{ width: '100%', height: '100vh', touchAction: 'none' }}
      />
    </Box>
  );
};

export default DomeScene;
