import { Text, VStack, Box, Link } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import Web3Gif from '../../../resources/images/valley/web3.gif';
import ShowcaseGif from '../../../resources/images/valley/showcase.gif';
import MarketplaceGif from '../../../resources/images/valley/marketplace.gif';
import AuctionGif from '../../../resources/images/valley/auction.gif';
import NftGif from '../../../resources/images/valley/nfts.gif';
const RenderHomeIntro = () => {
  const descriptions = [
    {
      toUrl: '/',
      title: 'Our Mission',
      description: `
Our mission is to seamlessly integrate web3 technology into games and NFTs, including existing games that do not yet support web3, and to repurpose NFTs for new and innovative uses. By leveraging the capabilities of blockchain, we empower creators and developers to enhance interactivity and redefine digital experiences. Additionally, we provide dedicated marketplaces and auction houses for individual games, as well as a general marketplace and auction house for all users.
   `,
      imgUrl: Web3Gif,
      alt: 'magic flowers and cat',
      bgColor: '',
      backgroundImage:
        'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
      textColor: 'white',
    },
    {
      toUrl: '/gamenfts',
      title: 'Seamless Integration with Game NFTs',
      description: `
Effortlessly integrate Web3 game NFTs with our platform. Our tools and resources streamline the addition of blockchain technology to your games, enriching gameplay with distinctive digital assets and elevating the player experience. Web3 integration of NFTs in games takes place on-chain, utilizing decentralized features to uphold transparency, integrity, and security for game owners and players alike.
   `,

      imgUrl:
        'https://res.cloudinary.com/df9jvvwyw/image/upload/v1719625815/magicnftvalley_budken.gif',
      alt: 'magic flowers and cat',
      bgColor: '',
      backgroundImage:
        'radial-gradient( circle 976px at 51.2% 51%,  rgba(11,27,103,1) 0%, rgba(16,66,157,1) 0%, rgba(11,27,103,1) 17.3%, rgba(11,27,103,1) 58.8%, rgba(11,27,103,1) 71.4%, rgba(16,66,157,1) 100.2%, rgba(187,187,187,1) 100.2% )',
      textColor: 'white',
    },
    {
      toUrl: '/gamelist',
      title: 'Games Showcase',
      description: `Showcase your games and elevate the gaming experience with NFT integration. Magic NFT Valley provides a platform to display and promote your games, attracting a larger audience and enhancing player engagement.`,
      imgUrl: ShowcaseGif,
      alt: 'Game Showcase',
      bgColor: '',
      backgroundImage:
        'radial-gradient( circle 343px at 46.3% 47.5%,  rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )',
      textColor: 'black',
    },
    {
      toUrl: '/marketplace',
      title: 'Marketplace',
      description: `Buy and sell NFTs with ease in our user-friendly marketplace. In addition to our general marketplace, each showcased game features its own dedicated marketplace, providing a tailored experience for both buyers and sellers.`,
      imgUrl: MarketplaceGif,
      alt: 'Marketplace',
      bgColor: '#2F5B4C',
      backgroundImage: '',
      textColor: 'white',
    },
    {
      toUrl: '/auctions',
      title: 'Auction House',
      description: `Our Auction House features dynamic NFT auctions. Sellers can activate
          a unique bonus feature, creating a win-win situation for both sellers
          and bidders, making the auction experience more exciting and
          rewarding. Each showcased game features its own dedicated auction house.`,
      imgUrl: AuctionGif,
      alt: 'Auction House',
      bgColor: '#f2f2f2',
      backgroundImage: '',
      textColor: 'black',
    },
    {
      toUrl: '/dreamstudio',
      title: 'Dream Studio',
      description: `Effortlessly integrate your NFTs into games and other relevant actions. Seamlessly transform your dreams into reality with ease and confidence, while maintaining complete control over your creations and their digital journey.`,
      imgUrl:
        'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370758/butterfly_ox0ftc.gif',
      alt: 'magic flowers and cat',
      bgColor: '#0000b3',
      backgroundImage: '',
      textColor: 'white',
    },
    {
      title: 'NFT Versatility',
      description: `Our Marketplace, Auction House and Dream Workshoop support a wide range of NFTs,
          including images, audio files, videos, 3D models, 3D environment
          textures, and more.`,

      imgUrl: NftGif,
      alt: 'magic flowers and cat',
      bgColor: '', //'#1A202C', //'#f2f2f2',
      backgroundImage:
        'linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% )',
      textColor: 'white',
    },
    // {
    //   toUrl: '/magicflowers',
    //   title: 'Magic Flowers',
    //   description: `Discover the unique Magic Flowers of Magic NFT Valley. These special
    //       NFTs come with rich histories and the ability to evolve, each
    //       transformation being meticulously recorded in your NFT. Collect,
    //       evolve, and uncover the surprises they hold.`,
    //   imgUrl:
    //     'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370825/cat1_edquhz.gif',
    //   alt: 'magic flowers and cat',
    //   bgColor: '',
    //   backgroundImage:
    //     'radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% )',
    //   textColor: 'black',
    // },
  ];

  const RenderColoredTemplate1 = ({
    toUrl,
    title,
    description,
    imgUrl,
    alt,
    bgColor = '#2F5B4C',
    textColor = 'white',
    backgroundImage = '',
  }) => {
    return (
      <div
        style={{
          backgroundColor: bgColor,
          backgroundImage: backgroundImage,
          width: '100%',
          padding: '15px',
          color: textColor,
          margin: 0,
        }}
      >
        <VStack>
          <Box>
            <Link as={ReactLink} to={toUrl}>
              <Text fontSize="5xl" style={{ fontFamily: 'Lobster' }}>
                {title}
              </Text>
            </Link>
          </Box>
          <Box w={['100%', '80%', '67%']}>
            <Text fontSize="2xl">{description}</Text>
          </Box>

          <Box>
            <img src={imgUrl} alt={alt} />
          </Box>
        </VStack>
      </div>
    );
  };

  return (
    <VStack spacing={6}>
      {/* <Box>
        <Text
          color="green.400"
          fontSize={[26, 36, 47]}
          style={{ fontFamily: 'Lobster' }}
        >
          Key Features
        </Text>
      </Box> */}

      {descriptions.map((description, i) => (
        <div key={i} style={{ margin: 0, padding: 0 }}>
          <RenderColoredTemplate1
            toUrl={description.toUrl}
            title={description.title}
            description={description.description}
            imgUrl={description.imgUrl}
            alt={description.alt}
            bgColor={description.bgColor}
            backgroundImage={description.backgroundImage}
            textColor={description.textColor}
          />
        </div>
      ))}

      <Box>
        <Text fontSize="lg" as="i">
          Ready to embark on this journey with us? Support our Indiegogo
          campaign now!
        </Text>
      </Box>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
};

export default RenderHomeIntro;
