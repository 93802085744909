import { VStack, Box, Text } from '@chakra-ui/react';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { ethers } from 'ethers'

import AddGameBase from '../../../components/actions/NFTs/AddGameBase';
const AddGame = () => {
  return (
    <VStack>
      <HomeIcon />
      <Box>
        <Text fontSize={36} color="teal.500" style={{ fontFamily: 'Lobster' }}>
          Add Game
        </Text>
      </Box>
      <AddGameBase />
    </VStack>
  );
};

export default AddGame;
