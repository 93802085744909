import { ethers } from 'ethers';
import ITokenURI from '../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import axios from 'axios';

const getImageUrl = async (provider, _tokenId, _tokenAddr) => {
  let tokenContract = new ethers.Contract(_tokenAddr, ITokenURI.abi, provider);

  let tokenUrl, metadata, imageUrl;
  try {
    tokenUrl = await tokenContract.uri(_tokenId);
  } catch (error) {
    // If ERC1155 `uri` function doesn't work, try ERC721's `tokenURI`
    try {
      tokenUrl = await tokenContract.tokenURI(_tokenId);
    } catch (fallbackError) {
      console.error('Failed to retrieve token URL for tokenId', _tokenId);
      tokenUrl = null;
    }
  }
  // console.log('tokenUrl:', tokenUrl);
  metadata = await axios.get(tokenUrl);
  // console.log('metadata:', metadata);
  imageUrl = metadata.data.image;

  // console.log('imageUrl:', imageUrl);

  return imageUrl;
};

export default getImageUrl;
