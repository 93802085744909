import SaleInValley from '../../../components/views/NFTs/elements/singleValleyItem/SaleInValley';
const MarketplaceItemErc1155Page = () => {
  return (
    <>
      <SaleInValley saleType="Marketplace" tokenType="Erc1155" />
    </>
  );
};

export default MarketplaceItemErc1155Page;
