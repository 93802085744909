import React, { useState } from 'react';
import {
  Text,
  VStack,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  List,
  ListItem,
} from '@chakra-ui/react';
import '../../styles/DashedBorder.css';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
const NFTmetaJsonCreator = () => {
  const [nftData, setNftData] = useState({
    name: '',
    description: '',
    image: '',
    animation_url: '',
    external_url: '',
    youtube_url: '',
    background_color: '',
    attributes: [],
    properties: {
      creator: '',
      creation_date: '',
    },

    customFields: [], // Array to hold custom key-value pairs
  });

  const handleAddAttribute = () => {
    // Add an empty attribute object to attributes array
    setNftData({
      ...nftData,
      attributes: [...nftData.attributes, { trait_type: '', value: '' }],
    });
  };

  const handleRemoveAttribute = indexToRemove => {
    // Remove attribute at specific index from attributes array
    const updatedAttributes = [...nftData.attributes];
    updatedAttributes.splice(indexToRemove, 1);
    setNftData({
      ...nftData,
      attributes: updatedAttributes,
    });
  };

  const handleAddCustomField = () => {
    // Add an empty custom field object to customFields array
    setNftData({
      ...nftData,
      customFields: [...nftData.customFields, { key: '', value: '' }],
    });
  };

  const handleRemoveCustomField = indexToRemove => {
    // Remove custom field at specific index from customFields array
    const updatedCustomFields = [...nftData.customFields];
    updatedCustomFields.splice(indexToRemove, 1);
    setNftData({
      ...nftData,
      customFields: updatedCustomFields,
    });
  };

  const handleJsonSubmit = () => {
    // Convert nftData object to JSON string
    const jsonContent = JSON.stringify(nftData, null, 2);

    // Create blob with JSON content
    const blob = new Blob([jsonContent], { type: 'application/json' });

    // Create URL for blob
    const url = URL.createObjectURL(blob);

    // Create temporary <a> element to trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'myNFTmetadata.json';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <VStack>
      <HomeIcon />
      <Text
        color="green.400"
        fontSize={['lg', 'xl', '2xl', '3xl']}
        style={{ fontFamily: 'Lobster' }}
      >
        NFT meta Json Creator
      </Text>
      <Card>
        <CardBody>
          <Box mt={3}>
            <FormControl>
              <FormLabel>Name:</FormLabel>
              <Input
                type="text"
                value={nftData.name}
                onChange={e => setNftData({ ...nftData, name: e.target.value })}
              />
            </FormControl>
          </Box>
          <Box mt={3}>
            <FormControl>
              <FormLabel>Description:</FormLabel>
              <Textarea
                value={nftData.description}
                onChange={e =>
                  setNftData({ ...nftData, description: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl>
              <FormLabel> Image URL:</FormLabel>
              <Input
                type="text"
                value={nftData.image}
                onChange={e =>
                  setNftData({ ...nftData, image: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl>
              <FormLabel> Animation URL:</FormLabel>
              <Input
                type="text"
                value={nftData.animation_url}
                onChange={e =>
                  setNftData({ ...nftData, animation_url: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl>
              <FormLabel> External URL:</FormLabel>
              <Input
                type="text"
                value={nftData.external_url}
                onChange={e =>
                  setNftData({ ...nftData, external_url: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl>
              <FormLabel> Youtube URL:</FormLabel>
              <Input
                type="text"
                value={nftData.youtube_url}
                onChange={e =>
                  setNftData({ ...nftData, youtube_url: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl>
              <FormLabel> Background Color (hexadecimal color code):</FormLabel>
              <Input
                type="text"
                value={nftData.background_color}
                onChange={e =>
                  setNftData({ ...nftData, background_color: e.target.value })
                }
              />
            </FormControl>
          </Box>

          <Box mt={3}>
            <Text fontSize="xl" as="i">
              Attributes:
            </Text>
            <VStack>
              <Button onClick={handleAddAttribute}>Add Attribute</Button>
              <List>
                {nftData.attributes.map((attribute, index) => (
                  <ListItem key={index}>
                    <Box className="dashed" p={6} mb={3}>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Trait Type</FormLabel>
                          <Input
                            type="text"
                            value={attribute.trait_type}
                            placeholder="Trait Type"
                            onChange={e => {
                              const updatedAttributes = [...nftData.attributes];
                              updatedAttributes[index].trait_type =
                                e.target.value;
                              setNftData({
                                ...nftData,
                                attributes: updatedAttributes,
                              });
                            }}
                          />{' '}
                        </FormControl>
                      </Box>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Value</FormLabel>
                          <Input
                            type="text"
                            value={attribute.value}
                            placeholder="Value"
                            onChange={e => {
                              const updatedAttributes = [...nftData.attributes];
                              updatedAttributes[index].value = e.target.value;
                              setNftData({
                                ...nftData,
                                attributes: updatedAttributes,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Button onClick={() => handleRemoveAttribute(index)}>
                        Remove
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>

          <Box mt={3}>
            <Text fontSize="xl" as="i">
              Properties:
            </Text>

            <Box mt={3}>
              <FormControl>
                <FormLabel> Creator:</FormLabel>
                <Input
                  type="text"
                  value={nftData.properties.creator}
                  onChange={e =>
                    setNftData({
                      ...nftData,
                      properties: {
                        ...nftData.properties,
                        creator: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            <Box mt={3}>
              <FormControl>
                <FormLabel> Creation Date:</FormLabel>
                <Input
                  type="date"
                  value={nftData.properties.creation_date}
                  onChange={e =>
                    setNftData({
                      ...nftData,
                      properties: {
                        ...nftData.properties,
                        creation_date: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
          </Box>

          <Box mt={3}>
            <Text fontSize="xl" as="i">
              Custom Fields:
            </Text>
            <VStack>
              <Button onClick={handleAddCustomField}>Add Custom Field</Button>
              <List>
                {nftData.customFields.map((field, index) => (
                  <ListItem key={index}>
                    <Box className="dashed" mt={3} p={6}>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Key:</FormLabel>
                          <Input
                            type="text"
                            value={field.key}
                            placeholder="Key"
                            onChange={e => {
                              const updatedCustomFields = [
                                ...nftData.customFields,
                              ];
                              updatedCustomFields[index].key = e.target.value;
                              setNftData({
                                ...nftData,
                                customFields: updatedCustomFields,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Value:</FormLabel>
                          <Input
                            type="text"
                            value={field.value}
                            placeholder="Value"
                            onChange={e => {
                              const updatedCustomFields = [
                                ...nftData.customFields,
                              ];
                              updatedCustomFields[index].value = e.target.value;
                              setNftData({
                                ...nftData,
                                customFields: updatedCustomFields,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Button onClick={() => handleRemoveCustomField(index)}>
                        Remove
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>
        </CardBody>
        <CardFooter>
          <Box>
            <Button colorScheme="green" onClick={handleJsonSubmit}>
              Download NFT Metadata
            </Button>
          </Box>
        </CardFooter>
      </Card>
    </VStack>
  );
};

export default NFTmetaJsonCreator;
