import getTokenMetadata from '../../token/getTokenMetadata';
import { ethers } from 'ethers';
import Helper from '../../../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import getHelperTokenInfo from '../../token/getHelperTokenInfo';
import { REACT_APP_HELPER_ADDRESS } from '../../../../../Constants/ContractAddress';
export const getComplimentaryTokenInfo = async (
  provider,
  tokenContract,
  tokenId,
  _isErc1155
) => {
  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );
  tokenId = Number(tokenId);
  let tokenInfo = {};

  // metadata retrieval starts
  let _tokenUri;

  if (_isErc1155) {
    _tokenUri = await tokenContract.uri(tokenId);
  } else {
    _tokenUri = await tokenContract.tokenURI(tokenId);
  }

  // console.log('tokenUri:', _tokenUri);
  const metadata = await getTokenMetadata(_tokenUri);
  const categoryInfo = await getHelperTokenInfo(
    helper,
    tokenContract.address,
    tokenId
  );
  const category = categoryInfo.category;
  // metadata retrieval ends

  tokenInfo = {
    category,
    metadata,
  };

  // console.log('tokenInfo:', tokenInfo);

  return tokenInfo;
};

export default getComplimentaryTokenInfo;
