import React, { useEffect, useRef } from 'react';
import { Engine } from '@babylonjs/core';
import { Box } from '@chakra-ui/react';
import createHomeScene from './createHomeScenes/createHomeScene';
import createHomeVideoScene from './createHomeScenes/createHomeVideoScene';

function HomeScene() {
  const sceneRef = useRef(null);

  useEffect(() => {
    // Create a Babylon.js engine
    // const canvas = document.getElementById('babylon-canvas-marketplace');
    const canvas = sceneRef.current;
    const engine = new Engine(canvas);

    // console.log('domeImage:', domeImage)
    const randomNum = 7//Math.floor(Math.random() * 8) + 1; // 1 to 8

    const randomScene = Math.random();
    let scene;
    // if (randomScene < 0.5) {
    //   scene = createHomeScene(canvas, engine, randomNum);
    // } else {
    //   scene = createHomeVideoScene(canvas, engine);
    // }
    scene = createHomeScene(canvas, engine, randomNum);

    // const scene = createHomeVideoScene(canvas, engine);

    // Start the engine rendering loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    // Clean up the scene when the component unmounts
    return () => {
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <Box textAlign="center">
      <canvas ref={sceneRef} style={{ width: '100%', height: '100vh' }} />
    </Box>
  );
}

export default HomeScene;
