import { ethers } from 'ethers';
import ITokenURI from '../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import axios from 'axios';
import getNetworkProvider from '../../walletConnections/obtainProviders/getNetworkProvider';

const getImageUrlByTokenType = async (
  _tokenId,
  _tokenContractAddr,
  _isErc1155
) => {
  const provider = getNetworkProvider();

  let tokenContract = new ethers.Contract(
    _tokenContractAddr,
    ITokenURI.abi,
    provider
  );

  let tokenUrl = '', imageUrl = ''
  if (_isErc1155) {
    tokenUrl = await tokenContract.uri(_tokenId);
  } else {
    tokenUrl = await tokenContract.tokenURI(_tokenId);
  }

  const metadata = await axios.get(tokenUrl);
  // console.log('metadata:', metadata);
  if (typeof metadata !== undefined && Object.keys(metadata.data).length > 0 && typeof metadata.data.image !== undefined) {
    imageUrl = metadata.data.image
  } 

  // console.log('imageUrl:', imageUrl);
  return imageUrl
};

export default getImageUrlByTokenType;
