import Cat from '../../resources/images/cats/cat.png';
import Penguin from '../../resources/images/cats/penguin.gif';
import Gem0 from '../../resources/images/diamond-heart.png';
import Vehicle from './Vehicle';

function SketchLuckyCat(p5) {
  let gem0;
  let cat;
  // text
  let font;
  let vehicles = [];

  // background stars
  let stars = [];
  let catX = 10;
  let gx = 88,
    gy1 = 0,
    gy2 = 100,
    gspeed = 3;
  const animals = [Cat, Penguin];
  const _index = Math.floor(Math.random() * animals.length);
  let CurrentImg = animals[_index];

  p5.preload = () => {
    gem0 = p5.loadImage(Gem0);
    cat = p5.loadImage(CurrentImg);
    font = p5.loadFont('Lobster-Regular.ttf');
  };
  p5.setup = () => {
    p5.createCanvas(window.innerWidth, window.innerHeight);
    // background stars
    p5.push();
    prepareStars(p5, 88, stars);
    p5.pop();

    //text
    let points = font.textToPoints(
      'Game Showcase',
      p5.width / 3.58,
      p5.height / 5.8,
      88,
      {
        sampleFactor: '0.25',
      }
    );

    for (let i = 0; i < points.length; i++) {
      let pt = points[i];
      let vehicle = new Vehicle(p5, pt.x, pt.y);
      vehicles.push(vehicle);
    }
  };

  p5.draw = () => {
    p5.background(0);
    // stars
    p5.push();
    displayStars(stars);
    p5.pop();

    // cat
    catX = catX + 5;
    if (catX >= p5.width) {
      catX = 0;
    }

    p5.image(cat, catX, p5.mouseY, 100, 100); // icon moving
    p5.image(cat, p5.mouseX, p5.mouseY, 200, 200);
    if (p5.frameCount < 74) {
      p5.image(gem0, gx, gy1, 218, (218 * 1712) / 1920);
      p5.image(gem0, gx + 88, gy2, 118, (118 * 1712) / 1920);
      gy1 += gspeed;
      gy2 += gspeed;
    } else {
      p5.image(gem0, catX - 6, p5.mouseY + 88, 108, (108 * 1712) / 1920);
      p5.image(gem0, p5.mouseX - 10, p5.mouseY + 188, 218, (218 * 1712) / 1920);
    }

    // text
    for (let i = 0; i < vehicles.length; i++) {
      let v = vehicles[i];
      v.behaviors();
      v.update();
      v.show();
    }
  };

  function prepareStars(p5, rStars, stars) {
    p5.push();

    // fill stars array
    for (let i = 0; i < rStars; i++) {
      stars.push(new Star(p5));
    }

    p5.pop();
  }

  function displayStars(stars) {
    for (let i = 0; i < stars.length; i++) {
      stars[i].display();
      stars[i].twinkle();
    }
  }

  function Star(p5) {
    this.x = p5.random(-30, p5.width);
    this.y = p5.random(0, p5.height);
    this.r = p5.random(2, 5);

    this.display = function () {
      p5.push();
      p5.stroke(255); //33, 121, 145
      p5.fill(247, 255, 196); //247, 255, 196
      this.rc = p5.constrain(this.r, 0, 9);
      p5.ellipse(this.x, this.y, this.rc, this.rc);
      p5.pop();
    };

    this.twinkle = function () {
      if (this.r < 3) {
        this.r += p5.random(-0.5, 1.5);
      } else if (this.r >= 3 && this.r < 6) {
        this.r += p5.random(-1, 1);
      } else if (this.r >= 6 && this.r <= 9) {
        this.r += p5.random(-1.5, 0.5);
      }
    };
  }
}

export default SketchLuckyCat;
