import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Text,
} from '@chakra-ui/react';

const TokenCategoryForm = ({
  index,
  category,
  setCategory,
  isMagicflowers = false,
}) => {
  // console.log('meta:', meta);

  const handleMeta = (index, value) => {
    console.log('category:', category)
    const updatedTokens = [...category];
    updatedTokens[index] = value;
    setCategory(updatedTokens);
  };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        {!isMagicflowers && (
          <>
            <FormControl isRequired>
              <FormLabel as="legend">NFT Category:</FormLabel>
              <RadioGroup
                onChange={selected => handleMeta(index, selected)}
                value={category[index]}
              >
                <Stack direction="column">
                  <Text color="teal.500">Image</Text>
                  <Radio value="Image" colorScheme="green">
                    Image
                  </Radio>
                  <Radio value="Gif" colorScheme="green">
                    Gif (Animated Image)
                  </Radio>
                  <Text color="teal.500">Music</Text>
                  <Radio value="Music" colorScheme="green">
                    Music
                  </Radio>
                  <Text color="teal.500">Video</Text>
                  <Radio value="Video" colorScheme="green">
                    Standard Video
                  </Radio>
                  <Radio value="Video360" colorScheme="green">
                    360 Degree Video
                  </Radio>

                  <Text color="teal.500">3D Model</Text>
                  <Radio value="3DModel" colorScheme="green">
                    3D Model
                  </Radio>

                  <Text color="teal.500">3D Environment Textures</Text>
                  <Radio value="Equirectangular" colorScheme="green">
                    3D Environment Textures - Equirectangular Images with .png
                    or .jpg or other extensions
                  </Radio>
                  <Radio value="Hdr" colorScheme="green">
                    3D Environment Textures - Equirectangular Images with .hdr
                    extension
                  </Radio>
                  <Radio value="Env" colorScheme="green">
                    3D Environment Textures - ENV or DDS files with .env or .dds
                    extension
                  </Radio>
                  <Radio value="Skybox" colorScheme="green">
                    3D Environment Textures - Skybox (6 images in total)
                  </Radio>

                  <Text color="teal.500">Others</Text>
                  <Radio value="Others" colorScheme="green">
                    Others
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </>
        )}
      </Box>
    </>
  );
};

export default TokenCategoryForm;
