import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const GameTokenIdForm = ({ gameTokenId, setGameTokenId }) => {
  const handleGameTokenId = e => setGameTokenId(e);
  return (
    <>
      <Box>
        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">Game Token Id:</FormLabel>

            <NumberInput
              min={1}
              value={gameTokenId}
              onChange={e => handleGameTokenId(e)}
              placeholder="Game Token ID"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default GameTokenIdForm;
