import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';
import { BatchListForSale, ImageUrl, ListForSale } from './MarketplaceFAQ';
const MarketplaceSection = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              1. How to list an NFT for sale?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ListForSale />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              2. How to batch list NFTs for sale?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <BatchListForSale />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              3. Why do I need to provide a URL for the NFT image during the
              minting process in Dream Minting Studio?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ImageUrl />
      </AccordionItem>
    </Accordion>
  );
};

export default MarketplaceSection;
