import {
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
const UpdateGameBranchForm = ({
  index,
  gameBranch,
  setGameBranch,
  label = 'Add Game Branch',
}) => {
  const handleGameBranch = (e, idx) => {
    const updatedGameBranch = [...gameBranch];
    updatedGameBranch[idx] = e.target.value;
    console.log('updatedGameBrach:', updatedGameBranch);
    setGameBranch(updatedGameBranch);
  };
  return (
    <FormControl>
      <FormLabel as="legend">{label}:</FormLabel>

      <Input
        name="gameBranch"
        value={gameBranch[index]}
        onChange={e => handleGameBranch(e, index)}
        placeholder="Game Branch"
        size="sm"
      />
    </FormControl>
  );
};

export default UpdateGameBranchForm;
