import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import FeaturingItemBase from '../../featuringItemBase';

function FeatureItemByUser({
  tokenContract = '',
  tokenId = 0,
  gameIdByUser = 0,
  featuringPagesByUser = [],
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button colorScheme="green" onClick={onOpen}>
        Featuring Item
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Featuring Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FeaturingItemBase
              useCase="TokenByUser"
              tokenContractByUser={tokenContract}
              tokenIdByUser={tokenId}
              gameIdByUser={gameIdByUser}
              featuringPagesByUser={featuringPagesByUser}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FeatureItemByUser;
