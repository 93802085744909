import { Text, VStack, Link } from '@chakra-ui/react';
import FlowerFrame4 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame4_1.png';
import FlowerFrame5 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame5_1.png';
import FlowerFrame6 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame6_1.png';
import FlowerFrame7 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame7_1.png';
import FlowerFrame8 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame8.png';
import FlowerFrame9 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame9_1.png';
import FlowerFrame10 from '../../../../../../resources/images/frames/flowerFrames/flowerFrame10.png';
export const MintingFrame = () => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={FlowerFrame4} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '58%',
          left: '53%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <VStack>
          <Text
            color="green.300"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Minting
          </Text>
          <Text
            color="green.300"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Selling
          </Text>
          <Text
            color="green.300"
            fontSize="2xl"
            style={{ fontFamily: 'Lobster' }}
          >
            NFT
          </Text>
        </VStack>
      </div>
    </div>
  );
};

export const FeaturingFrame = () => {
  return (
    <Link href="/create/featuringaction">
      <div
        style={{ position: 'relative', textAlign: 'center', color: 'white' }}
      >
        <img src={FlowerFrame5} style={{ width: '100%' }} />

        <div
          style={{
            position: 'absolute',
            top: '47%',
            left: '53%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <VStack>
            <Text
              color="green.300"
              fontSize="3xl"
              style={{ fontFamily: 'Lobster' }}
            >
              Featuring
            </Text>
            <Text
              color="green.300"
              fontSize="2xl"
              style={{ fontFamily: 'Lobster' }}
            >
              NFT
            </Text>
          </VStack>
        </div>
      </div>
    </Link>
  );
};

export const GamesFrame = () => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={FlowerFrame6} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '48%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <VStack>
          <Text
            color="green.300"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Game
          </Text>
          <Text
            color="green.300"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Integration
          </Text>
        </VStack>
      </div>
    </div>
  );
};

export const CollectionFrame = () => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={FlowerFrame9} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '48%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <VStack>
          <Text
            color="green.300"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Collection
          </Text>
        </VStack>
      </div>
    </div>
  );
};
