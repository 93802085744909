import React from 'react';
import { Switch, FormLabel, FormControl, Box } from '@chakra-ui/react';

const SwitchForm = ({
  item,
  setItem,
  label,
  size = 'md',
  colorScheme = 'blue',
}) => {
  const handleSwitchChange = () => {
    setItem(!item); // Toggle the boolean value and update the state
  };

  return (
    <Box mb='8px'>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor={`${item}-alerts`} mb="0">
          {label}
        </FormLabel>
        <Switch
          id={`${item}-alerts`}
          onChange={handleSwitchChange}
          isChecked={item}
          colorScheme={colorScheme}
          size={size}
        />
      </FormControl>
    </Box>
  );
};

export default SwitchForm;
