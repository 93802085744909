import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';

import UpdateHighlightsBase from '../../UpdateHighlightsBase';
const UpdateHighlights = ({ pageName, initialMeta, lastCid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Update Highlights</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update {pageName} Highlights</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UpdateHighlightsBase
              pageName={pageName}
              initialMeta={initialMeta}
              lastCid={lastCid}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateHighlights;
