import { useParams, Link as ReactLink } from 'react-router-dom';
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import AllTokensCreatedBy from '../../../components/views/NFTs/AllTokensCreatedBy';
import AllCollectionsCreatedBy from '../../../components/views/NFTs/AllCollectionsCreatedBy';
import HighlightsBase from '../../../components/views/NFTs/myPages/HighlightsBase';
function Artist({ useCase = '' }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const { artistAddress } = useParams();
  // console.log('artistAddress:', artistAddress);

  const DisplayList = () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    const RenderTokenCreations = () => {
      return (
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          align="center"
          isLazy
          size="lg"
        >
          <TabList>
            <Tab>ERC721</Tab>
            <Tab>ERC1155</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AllTokensCreatedBy
                provider={provider}
                isErc1155={false}
                addr={artistAddress}
              />
            </TabPanel>
            <TabPanel>
              <AllTokensCreatedBy
                provider={provider}
                isErc1155={true}
                addr={artistAddress}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    };
    return (
      <Tabs isFitted variant="enclosed" isLazy>
        <TabList mb="1em">
          <Tab>All Tokens Created</Tab>
          <Tab>All Collections Created</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RenderTokenCreations />
          </TabPanel>
          <TabPanel>
            <AllCollectionsCreatedBy provider={provider} addr={artistAddress} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  return (
    <>
      <HighlightsBase
        addr={artistAddress}
        pageName="Artist"
        useCase={useCase}
      />

      <VStack spacing={8} mt="10px">
        <DisplayList />

        <style jsx>
          {`
            @import url('https://fonts.googleapis.com/css?family=Lobster');
          `}
        </style>
      </VStack>
    </>
  );
}

export default Artist;
