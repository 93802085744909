import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  AccordionPanel,
  Link,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';

export const IntegrateNFTinGame = () => {
  return (
    <AccordionPanel pb={4}>
      To seamlessly integrate an NFT into a game, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Simply click on the 'Connect Wallet'
          button located in the top right corner, then choose your preferred
          wallet from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We utilize WalletConnect, a trusted
          protocol, for secure connections.
        </ListItem>
        <ListItem>
          Select 'Game Integration', then choose 'Integrate Tokens in Game'.
          From there, click on either 'Integrate Erc721 Token(s) in Game' or
          'Integrate Erc1155 Token(s) in Game' based on your NFT type. A modal
          will appear with a form for game integration.
        </ListItem>
        <ListItem>
          Indicate 'Yes' for 'Integrate Game' and specify the Game Id where your
          NFT is to be integrated. Select whether it's 'For External
          Integration' or 'Reserved for Game Owner / Game Admin'.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          minted in Dream Minting Studio within Magic NFT Valley, choose 'Magic
          NFT Erc721 contract' or 'Magic NFT Erc1155 contract' depending on the
          NFT type. For NFTs minted elsewhere, select 'Other contract' and input
          the contract address.
        </ListItem>
        <ListItem>
          Specify the game branch. If unsure, refer to the game-specific
          instructions on the game showcase page.
        </ListItem>
        <ListItem>
          Click 'Show list of game features', and a list of available game
          features for integration will appear based on the provided Game Id.
          Select the feature(s) you wish to integrate with.
        </ListItem>
        <ListItem>Fill out the form below with the NFT ID.</ListItem>
        <ListItem>
          Finally, click the 'Submit' button at the bottom of the form to
          complete the listing.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet. There is no charge
          for this action, but you may be required to cover the gas fee native
          to the Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchIntegrateNFTsInGame = () => {
  return (
    <AccordionPanel pb={4}>
      To seamlessly integrate multiple NFTs into a game, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          , and select 'NFTs in Bulk' at the top of the form.
        </ListItem>
        <ListItem>
          All NFTs must originate from the same NFT contract. After completing
          the form for 'NFT#1,' click 'Add Token,' which will reveal a form for
          'NFT#2.' Continue this process until you've provided all the necessary
          information for each NFT.
        </ListItem>
        <ListItem>
          If you need to remove a token, simply click 'Remove this token' on the
          corresponding form.
        </ListItem>
        <ListItem>
          Finally, click 'Create' to complete the integration process. For
          further guidance, please refer to '1. How to integrate an NFT in a
          game?' in the documentation.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const CreateNFTcompatibleWithGame = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        To craft an NFT compatible with a game, follow these steps:
        <OrderedList>
          <ListItem>
            Ensure the game supports external integration by checking its
            showcase page.
          </ListItem>
          <ListItem>
            Review the list of external features available in the game.
          </ListItem>
          <ListItem>
            Decide on the feature(s) for which you want to create an NFT.
          </ListItem>
          <ListItem>Create the content for your NFT and mint it.</ListItem>
          <ListItem>
            If the game supports NFT trials, test your NFT in the game using the
            showcase page.
          </ListItem>
          <ListItem>Integrate your NFT into the game.</ListItem>
          <ListItem> Enjoy playing the game with your NFT.</ListItem>
          <ListItem>Optionally, consider selling your NFT for profit.</ListItem>
        </OrderedList>
      </Text>
    </AccordionPanel>
  );
};

export const UpdateGameIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      To update/remove/reactivate game integration for your token, follow these
      steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Simply click on the 'Connect Wallet'
          button located in the top right corner, then choose your preferred
          wallet from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We utilize WalletConnect, a trusted
          protocol, for secure connections.
        </ListItem>
        <ListItem>
          Select 'Game Integration', then choose 'Integrate Tokens in Game'.
          From there, click on 'Update Tokens in Game'. A modal will appear with
          a form for updating game integration.
        </ListItem>
        <ListItem>
          Specify the Game Id for which your NFT integration is to be updated.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          minted in Dream Minting Studio within Magic NFT Valley, choose 'Magic
          NFT Erc721 contract' or 'Magic NFT Erc1155 contract' depending on the
          NFT type. For NFTs minted elsewhere, select 'Other contract' and input
          the contract address.
        </ListItem>
        <ListItem>Provide the NFT ID.</ListItem>
        <ListItem>
          Specify whether you want to 'Update Game Branch', 'Update Game
          Features', 'Remove Game Integration', and/or 'Reactivate Game
          Integration'. Each item you choose to update will display the
          corresponding form. Once you've completed filling out all the new
          data, click the 'Submit' button at the bottom of the form to finalize
          the update process.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet. There is no charge
          for this action, but you may be required to cover the gas fee native
          to the Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchUpdateGameIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      To update game integration for multiple NFTs, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          , select 'Game Integration', then choose 'Integrate Tokens in Game'.
          From there, click on 'Update Tokens in Game'. A modal will appear with
          a form for updating game integration.
        </ListItem>
        <ListItem>Select 'NFTs in Bulk' at the top of the form.</ListItem>
        <ListItem>
          All NFTs must originate from the same NFT contract. After completing
          the form for 'NFT#1,' click 'Add Token,' which will reveal a form for
          'NFT#2.' Continue this process until you've provided all the necessary
          information for each NFT.
        </ListItem>
        <ListItem>
          If you need to remove a token, simply click 'Remove this token' on the
          corresponding form.
        </ListItem>
        <ListItem>
          Finally, click 'Submit' to complete the integration process. For
          further guidance, please refer to '4. How to update/remove/reactivate
          game integration for an NFT?' in the documentation.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};
