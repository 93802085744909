import { Text} from '@chakra-ui/react';
import StickyNote1 from '../../../../../resources/images/frames/stickyNotes/stickyNote1.png';

const RenderErc1155Amounts = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={StickyNote1} alt="heart frame" style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '53%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text color="green.500" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          Total origianl supply
        </Text>
        <Text color="green.300" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          {props.t1}
        </Text>
        <Text color="green.500" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          Selling amount
        </Text>
        <Text color="green.300" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          {props.t2}
        </Text>
        <Text color="green.500" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          Amount sold
        </Text>
        <Text color="green.300" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          {props.t3}
        </Text>
        <Text color="green.500" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          Amount available
        </Text>
        <Text color="green.300" fontSize='sm' style={{ fontFamily: 'Lobster' }}>
          {props.t4}
        </Text>
      </div>
    </div>
  );
};

export default RenderErc1155Amounts;
