import ValleyItemListBase from './ValleyItemListBase';

const ValleyItemList = ({ provider, tokenType, saleType }) => {
  return (
    <ValleyItemListBase
      provider={provider}
      tokenType={tokenType}
      saleType={saleType}
      marketplaceIds={[]}
      auctionIds={[]}
      byUser={false}
      userAddr=""
      forGame={false}
    />
  );
};

export default ValleyItemList;
