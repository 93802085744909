import { VStack, Box, Tooltip } from '@chakra-ui/react';
import { RenderItemName, RenderInfoAccordion } from '../../ShowItem';
import RenderValleyItem from '../RenderValleyItem';
import BuyNFTerc1155 from '../../../../../actions/NFTs/marketplace/BuyNFTerc1155';
import { formatDigits } from '../../../../../utils/helpers';
import Sold from '../../../../../../resources/images/Sold.png';
import RenderGamesIntegrated from '../../token/RenderGamesIntegrated';
import { TextOnPaper } from '../../TextOnImages';
import RenderComplimentaryTokens from './RenderComplimentaryTokens';
import RenderErc1155Amounts from '../../token/RenderErc1155Amounts';
import RenderAttributes from './RenderAttributes';

function MarketplaceItemErc1155({
  provider,
  item,
  metadata,
  itemCollection,
  itemPriceHistory,
  tokenType,
  saleType,
}) {
  const RenderItemErc1155 = ({ item }) => {
    if (item.isCancelled) {
      return (
        <Box mt={8}>
          <TextOnPaper t="Cancelled" />
        </Box>
      );
    } else {
      return (
        <>
          <RenderValleyItem
            category={item.category}
            image={metadata.image}
            animation_url={metadata.animation_url}
          />
          <VStack spacing={6}>
            <Box textAlign="center" alignItems="baseline">
              <Tooltip label={'ID: ' + item.itemId} aria-label="A tooltip">
                <RenderItemName name={metadata.name} />
              </Tooltip>
            </Box>

            <Box
              textAlign="center"
              style={{
                marginTop: '25px',
                fontWeight: 'bold',
                fontSize: '28px',
              }}
            >
              {item.isSold ? (
                <img src={Sold} style={{ width: '218px', height: 'auto' }} />
              ) : (
                <BuyNFTerc1155
                  itemId={item.itemId}
                  gameIdsIntegrated={item.gameIdsIntegrated}
                />
              )}
            </Box>

            <Box textAlign="center" alignItems="baseline" as="i">
              Price: {formatDigits(item.price)} ETH
            </Box>

            <RenderErc1155Amounts
              t1={item.originalSupply}
              t2={item.amount}
              t3={item.amountSold}
              t4={item.amount - item.amountSold}
            />

            <RenderGamesIntegrated
              provider={provider}
              tokenContract={item.tokenContractAddress}
              tokenId={item.tokenId}
              player={item.seller}
            />

            <RenderAttributes attributes={item.attributes} />

            <RenderComplimentaryTokens
              itemId={item.itemId}
              allCtokenContracts={item.allCtokenContracts}
              saleType={saleType}
              tokenType={tokenType}
            />

            <RenderInfoAccordion
              item={item}
              itemCollection={itemCollection}
              itemPriceHistory={itemPriceHistory}
              useCase="ValleyItem"
            />
          </VStack>
        </>
      );
    }
  };

  return (
    <VStack>
      <RenderItemErc1155 item={item} itemCollection={itemCollection} />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default MarketplaceItemErc1155;
