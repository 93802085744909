import React from 'react';
import { Box, Switch, FormLabel } from '@chakra-ui/react';

const SwitchElementForm = ({ index, item, setItem, label = '' }) => {
  const handleSwitchChange = () => {
    console.log('item:', item);
    const updatedItem = [...item]; // Create a copy of the array
    updatedItem[index] = !updatedItem[index]; // Toggle the value at the specified index

    setItem(updatedItem); // Update the state
  };

  return (
    <Box>
      <FormLabel htmlFor="email-alerts" mb="0">
        {label}
      </FormLabel>
      <Switch
        id={`${item}-${index}`}
        onChange={() => handleSwitchChange(index)}
      />
    </Box>
  );
};

export default SwitchElementForm;
