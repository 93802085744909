import MintTokensBase from './mintTokensBase';
const ListIntegrateTokensBase = ({
  tokenType,
  specificGameTokenId,
  useCase,
  tokenContract,
  tokenId,
  onlyIntegration,
}) => {
  const _label = onlyIntegration ? "I would like to integrate" : "I would like to sell / auction"
  return (
    <MintTokensBase
      tokenType={tokenType}
      specificGameTokenId={specificGameTokenId}
      noMinting={true}
      tokenId={tokenId}
      tokenContract={tokenContract}
      useCase={useCase}
      label={_label}
      onlyIntegration={onlyIntegration}
    />
  );
};

export default ListIntegrateTokensBase;
