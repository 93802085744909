import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  BatchIntegrateNFTsInGame,
  BatchUpdateGameIntegration,
  CreateNFTcompatibleWithGame,
  IntegrateNFTinGame,
  UpdateGameIntegration,
} from './GameIntegrationFAQ';
const GameIntegrationSection = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              1. How to integrate an NFT in a game?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <IntegrateNFTinGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              2. How to batch integrate NFTs in a game?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <BatchIntegrateNFTsInGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              3. How to create an NFT that is compatible with a game?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <CreateNFTcompatibleWithGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              4. How to update/remove/reactivate game integration for an NFT?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <UpdateGameIntegration />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              5. How to batch update/remove/reactivate game integration for
              multiple NFTs?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <BatchUpdateGameIntegration />
      </AccordionItem>
    </Accordion>
  );
};

export default GameIntegrationSection;
