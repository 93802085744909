import axios from 'axios';
import getTokenURLandTokenType from '../../../../utils/getTokenURLandTokenType';
import { OpenseaMeta } from '../../../../utils/constants';
import { ethers } from 'ethers';
import getTokenMetadata from './getTokenMetadata';

export const getTokenInfo = async (tokenContract, tokenId) => {
  tokenId = Number(tokenId);
  let tokenInfo = {};

  // const artist = await tokenContract.getCreator(tokenId);
  let artist = ethers.constants.AddressZero;

  try {
    const _info = await tokenContract.royaltyInfo(tokenId, 0);
    // console.log('_info:', _info)
    artist = _info[0];
  } catch (error) {
    console.error('An error occurred while fetching the artist:', error);
    // artist = ethers.constants.AddressZero; // Set artist to an empty string in case of error
  }

  // console.log('artist:', artist);

  let tokenOwner;
  try {
    tokenOwner = await tokenContract.ownerOf(tokenId);
  } catch (error) {
    // console.error('An error occurred while fetching the artist:', error);
    tokenOwner = ''; // Set artist to an empty string in case of error
  }

  // metadata retrieval starts

  let tokenType;
  const _res = await getTokenURLandTokenType(tokenContract, tokenId);
  console.log('_res:', _res);
  let _tokenUri = _res.cid;
  tokenType = _res.tokenType;

  // console.log('tokenUri:', _tokenUri);
  let metadata = await getTokenMetadata(_tokenUri);

  let originalSupply;

  if (tokenType == 'Erc1155') {
    try {
      originalSupply = await tokenContract.getOriginalSupply(tokenId);
      originalSupply = Number(originalSupply.toString());
    } catch (error) {
      // console.error('An error occurred while fetching the artist:', error);
      originalSupply = undefined;
    }

    // console.log('originalSupply:', originalSupply)
  }

  tokenInfo = {
    tokenType,
    tokenId,
    tokenContractAddress: tokenContract.address,
    metadata,
    artist,
    tokenOwner,
    originalSupply,
  };

  // console.log('tokenInfo:', tokenInfo);

  return tokenInfo;
};

export default getTokenInfo;
