import React from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

const RenderModalFooterButton = ({ loading, label, create, onClose }) => {
  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button isLoading loadingText="Submitting" colorScheme="blue" mr={3}>
          {label}
        </Button>
      );
    } else {
      return (
        <Button onClick={create} colorScheme="blue" mr={3}>
          {label}
        </Button>
      );
    }
  };

  return (
    <>
      <HStack spacing={8}>
        <Box>
          <RenderButton />
        </Box>
        <Box>
          <Button onClick={onClose}>Cancel</Button>
        </Box>
      </HStack>
    </>
  );
};

export default RenderModalFooterButton;
