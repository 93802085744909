import React from 'react';
import {
  Box,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Container,
  HStack,
} from '@chakra-ui/react';
import { GiCutDiamond } from 'react-icons/gi';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import CreateNewCollectionAndAddTokens from '../../../components/actions/NFTs/collections/CreateNewCollectionAndAddTokens';
import UpdateTokensInCollection from '../../../components/actions/NFTs/collections/UpdateTokensInCollection';

function collectionAction() {
  return (
    <Box>
      <Container style={{ marginTop: '58px' }}>
        <VStack spacing={4}>
          <Box>
            <HomeIcon />
          </Box>
          <Box>
            <Text
              color="green.300"
              fontSize={[39, 58, 66]}
              style={{ fontFamily: 'Lobster' }}
            >
              Curate Your Artistic Collections
            </Text>
          </Box>

          <VStack spacing={3}>
            <List spacing={3}>
              <ListItem>
                <HStack>
                  <ListIcon as={GiCutDiamond} color="green.300" />
                  <CreateNewCollectionAndAddTokens />
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <ListIcon as={GiCutDiamond} color="green.300" />
                  <UpdateTokensInCollection updateType="Add" />
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <ListIcon as={GiCutDiamond} color="green.300" />
                  <UpdateTokensInCollection updateType="Remove" />
                </HStack>
              </ListItem>
            </List>
          </VStack>
        </VStack>
      </Container>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default collectionAction;
