import React from 'react';
import {
  VStack,
} from '@chakra-ui/react';
import ChooseContractForm from './ChooseContractForm';
import SetContractAddrForm from './SetContractAddrForm';

const TokenContractForm = ({
  singleContractAddr,
  setSingleContractAddr,
  tokenType,
}) => {
  return (
    <VStack>
      <ChooseContractForm
        singleContractAddr={singleContractAddr}
        setSingleContractAddr={setSingleContractAddr}
        tokenType={tokenType}
      />
      {singleContractAddr === 'Other Contract Address' && (
        <SetContractAddrForm
          singleContractAddr={singleContractAddr}
          setSingleContractAddr={setSingleContractAddr}
        />
      )}
    </VStack>
  );
};

export default TokenContractForm;
