import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const PriceForm = ({ index, price, setPrice }) => {
  // const handleTokenId = () => {
  //   console.log('value:', value);
  //   const updatedTokenIds = [...tokenIds];
  //   updatedTokenIds[index] = value;
  //   setTokenIds(updatedTokenIds);
  // };
  const handlePrice = (index, value) => {
    console.log('value:', value);
    const updatedPrices = [...price];
    updatedPrices[index] = value;
    setPrice(updatedPrices);
  };

  return (
    <div>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Price: (in MATIC)</FormLabel>

          <NumberInput
            min={0}
            value={price[index]}
            precision={8}
            onChange={e => handlePrice(index, e)}
            placeholder="NFT price"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Box>
    </div>
  );
};

export default PriceForm;
