// import React from 'react';
// import {
//   Box,
//   FormControl,
//   FormLabel,
//   NumberInput,
//   NumberInputField,
//   NumberInputStepper,
//   NumberIncrementStepper,
//   NumberDecrementStepper,
// } from '@chakra-ui/react';

// const AmountsErc1155Form = ({ index, amounts, setAmounts }) => {
//   const handleAmounts = (index, value) => {
//     console.log('value:', value);
//     const updatedAmounts = [...amounts];
//     updatedAmounts[index] = value;
//     setAmounts(updatedAmounts);
//   };

//   return (
//     <div>
//       <Box style={{ marginBottom: '20px' }}>
//         <FormControl isRequired>
//           <FormLabel as="legend">Amounts:</FormLabel>

//           <NumberInput
//             min={0}
//             value={amounts[index]}
//             onChange={e => handleAmounts(index, e)}
//             placeholder="Amounts"
//           >
//             <NumberInputField />
//             <NumberInputStepper>
//               <NumberIncrementStepper />
//               <NumberDecrementStepper />
//             </NumberInputStepper>
//           </NumberInput>
//         </FormControl>
//       </Box>
//     </div>
//   );
// };

// export default AmountsErc1155Form;

import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const AmountsErc1155Form = ({ index, amounts, setAmounts, label }) => {
  const handleAmounts = (index, value) => {
    console.log('value:', value);
    const updatedAmounts = [...amounts];
    updatedAmounts[index] = value;
    setAmounts(updatedAmounts);
  };

  return (
    <div>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">{label}:</FormLabel>

          <NumberInput
            min={0}
            value={amounts[index]}
            onChange={(e) => handleAmounts(index, e)}
            placeholder="Amounts"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Box>
    </div>
  );
};

export default AmountsErc1155Form;

