import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

import {
  ApproveGameAdmin,
  CancelMyGame,
  ExternalIntegration,
  InternalIntegration,
  NFTtrialPage,
  ShowcaseMyGame,
  UpdateGameMeta,
} from './ShowcaseGameFAQ';
const ShowcaseGameSection = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              1. How to showcase my game?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ShowcaseMyGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              2. How to update my game's meta?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <UpdateGameMeta />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              3. What is External Integration?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ExternalIntegration />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              4. What is Internal Integration?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <InternalIntegration />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              5. How to approve or reject a game administrator?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ApproveGameAdmin />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              6. How to remove my game from the showcase list?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <CancelMyGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              7. How to prepare an NFT trial page for my game?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <NFTtrialPage />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              8. Does my game have a dedicated Marketplace/Auction House?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Yes, your game features both a Marketplace and an Auction House
          exclusively for your game. On your game's individual page, clicking on
          the `Marketplace` image redirects users to the Marketplace designed
          specifically for your game. Similarly, clicking on the `Auction House`
          image directs users to the Auction House specifically for your game.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ShowcaseGameSection;
