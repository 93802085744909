import React, { useState } from 'react';
import { Link, Box, Text, VStack, Grid, Icon, HStack } from '@chakra-ui/react';
import GameTokenList from '../../../components/views/NFTs/gameTokens/GameTokenList';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import FeaturedGalleryScene from '../../../components/views/NFTs/featured/FeaturedGalleryScene';
import TestnetReminder from '../../../components/views/misc/TestnetReminder';

function GameTokens() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const provider =
    address == undefined
      ? getNetworkProvider()
      : new ethers.providers.Web3Provider(walletProvider);
  return (
    <Box textAlign="center">
      <FeaturedGalleryScene
        label="Game Showcase"
        domeImage="./textures/artgallery/artgallery5.png"
        featurePage="gameList"
      />

      <VStack spacing={3} mt="10px">
        {/* <Text
          color="teal.500"
          fontSize={[28, 36, 42]}
          style={{ fontFamily: 'Lobster' }}
        >
          Games Showcase
        </Text> */}
        {/* <AddGameModal useStyle="Fancy" /> */}

        <GameTokenList provider={provider} />
        <Box mt="38px">
          <TestnetReminder />
        </Box>
      </VStack>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default GameTokens;
