import { ethers } from 'ethers';
import axios from 'axios';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { REACT_APP_GAMES_ADDRESS } from '../../../../Constants/ContractAddress';

export const getGameInfo = async (provider, gameTokenIds, isSimple) => {
  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  let res = [];
  let allGameNames = [];

  if (gameTokenIds.length > 0) {
    for (let i = 0; i < gameTokenIds.length; i++) {
      let _game = await games.getGameProperties(gameTokenIds[i]);
      console.log('_game:', _game);

      let trialUrl, gameName;

      // let _tokenUri = getCidUrlNftstorage(_game.gameUrl);
      // let metadata = await axios.get(_tokenUri);
      // console.log('metadata:', metadata.data);
      trialUrl =
        _game.trialUrl == '/magicflowers/magicflowerstrial'
          ? 'https://cozygames.xyz/magicflowers/magicflowerstrial'
          : _game.trialUrl;
      gameName = _game.gameName;

      if (isSimple) {
        // res.push(_game[1]);
        res.push({
          gameTokenId: gameTokenIds[i],
          gameName,
        });
        allGameNames.push(gameName);
      } else {
        res.push({
          gameTokenId: gameTokenIds[i],
          gameName,
          trialUrl,
        });
      }
    }
  }

  return [res, allGameNames];
};

export const getGameName = async (provider, gameTokenIds) => {
  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  let allGameNames = [];

  if (gameTokenIds.length > 0) {
    for (let i = 0; i < gameTokenIds.length; i++) {
      let _game = await games.getGameProperties(gameTokenIds[i]);
      console.log('_game:', _game);

      let gameName = _game.gameName;
      allGameNames.push(gameName);
    }
  }

  return allGameNames;
};
