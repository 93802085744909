import { ethers } from 'ethers';
import axios from 'axios';
import FeaturedItems from '../../../../contracts/APIs/FeaturedItemsInterface.sol/FeaturedItemsInterface.json';
import Games from '../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import Helper from '../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import ITokenURI from '../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import getTokenURLandTokenType from '../../../utils/getTokenURLandTokenType';
import getHelperTokenInfo from '../elements/token/getHelperTokenInfo';
import { REACT_APP_FEATURED_ITEMS_ADDRESS, REACT_APP_GAMES_ADDRESS, REACT_APP_HELPER_ADDRESS } from '../../../Constants/ContractAddress';

const getFeaturedItems = async (provider, space, gameId) => {
  // console.log('provider:', provider);
  const featuredItems = new ethers.Contract(
    REACT_APP_FEATURED_ITEMS_ADDRESS,
    FeaturedItems.abi,
    provider
  );
  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );
  let games;
  if (space == 'gameList') {
    games = new ethers.Contract(REACT_APP_GAMES_ADDRESS, Games.abi, provider);
  }
  let _items = {};

  try {
    const d = await featuredItems.getCurrentFeatured(space, gameId);
    // console.log('d:', d);

    const tokenContract = new ethers.Contract(
      d.tokenContract,
      ITokenURI.abi,
      provider
    );

    let _tokenUri;

    let tokenId = Number(d.tokenId.toString());

    let metadata;
    let isGameList = false;

    if (tokenId > 0) {
      if (space == 'gameList') {
        isGameList = true;
        metadata = await games.getGameProperties(tokenId);
      } else {
        const res = await getTokenURLandTokenType(tokenContract, tokenId);
        // console.log('res:', res)
        _tokenUri = res.cid;
        try {
          const _res = await axios.get(_tokenUri);
          metadata = _res.data;
          // console.log('metadata:', metadata.data);
        } catch (error) {
          // console.log('metadata error:', typeof metadata);
          console.log(error);
        }
      }

      let _categoryInfo = await getHelperTokenInfo(
        helper,
        d.tokenContract,
        tokenId
      );
      // console.log('_categoryInfo.category:', _categoryInfo.category)
      const category =
        Object.keys(_categoryInfo).length > 0 ? _categoryInfo.category : '';

      _items = {
        tokenContract: d.tokenContract,
        tokenId,
        isGameList,
        category,
        metadata,
      };
    }

    // _items = await Promise.all(
    //   currentFeatured.map(async d => {

    //     return {
    //       tokenContract: d.contractAddress,
    //       tokenId: d.tokenId,
    //       category,
    //       name,
    //       tokenUri,
    //       featuringPage: d.featuringPage,
    //       gameId: d.gameId,
    //       posterUrl,
    //       videoUrl,
    //     };
    //   })
    // );
  } catch (error) {
    console.error('An error occurred:', error);
  }

  // console.log('_items:', _items);

  return _items;
};

export default getFeaturedItems;
