import {
  Box,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';

const CollectionImageUrlForm = ({
  collectionImageUrl,
  setCollectionImageUrl,
}) => {
  const RenderInfo = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <IconButton
            variant="outline"
            colorScheme="green"
            aria-label="Get Info"
            size="xs"
            icon={<FaInfo boxSize={8} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>Provide the image url for this collection.</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };
  return (
    <Box style={{ marginBottom: '20px' }}>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">
            Collection Image Url:
            <RenderInfo />
          </FormLabel>

          <Input
            name="collectionImageUrl"
            value={collectionImageUrl}
            onChange={e => setCollectionImageUrl(e.target.value)}
            placeholder="Collection Image Url"
            size="sm"
          />
        </FormControl>
      </Box>
      <img
        src={collectionImageUrl}
        style={{ width: '385px', height: 'auto' }}
      />
    </Box>
  );
};

export default CollectionImageUrlForm;
