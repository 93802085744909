import React, { useRef, useEffect } from "react";

const Iframe = (props) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const writeHTML = (frame) => {
      if (!frame) {
        return;
      }

      const doc = frame.contentDocument;
      doc.open();
      doc.write(props.content);
      doc.close();
      frame.style.width = "385px";
      frame.style.height = "385px";
    };

    writeHTML(iframeRef.current);
  }, [props.content]);

  return <iframe src="about:blank" ref={iframeRef} />;
};

export default Iframe;
