import { MeshBuilder, HDRCubeTexture } from '@babylonjs/core';
import {
  createHdrTexture,
  createSkyboxTexture,
  createPBR1,
  createPBR2,
  createPBR3,
  createPBR4,
  createPBR5,
  createPBR6,
  createPBR7,
  createPBR8,
} from '../createPBRs/createPBR';
import { stringToArray } from '../../utils/helpers';

const createSphere = (
  scene,
  num,
  sphereImage = '',
  isHdr = false,
  isSkybox = false
) => {
  // var sphere = Mesh.CreateSphere('sphere', 128, 2, scene);
  var sphere = MeshBuilder.CreateSphere(
    'sphere',
    { diameter: 3.58, segments: 128 },
    scene
  );

  var hdrTexture;
  if (sphereImage.length > 0 && isHdr) {
    hdrTexture = new HDRCubeTexture(sphereImage, scene, 512);
  }

  var imageArray = [];
  if (sphereImage.length > 0 && isSkybox) {
    imageArray = stringToArray(sphereImage);
  }
  var pbr;
  if (sphereImage.length > 0 && isHdr) {
    pbr = createHdrTexture(scene, hdrTexture);
  } else if (sphereImage.length > 0 && isSkybox) {
    pbr = createSkyboxTexture(scene, imageArray);
  } else if (num === 1) {
    pbr = createPBR1(scene);
  } else if (num === 2) {
    pbr = createPBR2(scene);
  } else if (num === 3) {
    pbr = createPBR3(scene);
  } else if (num === 4) {
    pbr = createPBR4(scene);
  } else if (num === 5) {
    pbr = createPBR5(scene);
  } else if (num === 6) {
    pbr = createPBR6(scene);
  } else if (num === 7) {
    pbr = createPBR7(scene);
  } else if (num === 8) {
    pbr = createPBR8(scene);
  }
  sphere.material = pbr;

  return sphere;
};
export default createSphere;
