import { ethers } from 'ethers';
import getTokenInfo from '../token/getTokenInfo';

export const getValleyItemInfo = async (
  d,
  tokenContract,
  saleType,
  tokenType
) => {
  // console.log('d:', d)
  // console.log('tokenContract:', tokenContract)
  // console.log('sleType:', saleType)
  // console.log('tokenType:', tokenType)
  let itemInfo = {};
  let newItem = {};

  let amount, amountSold;
  if (tokenType == 'Erc1155') {
    amount = d.sellingAmount;
    amount = Number(amount.toString());
    amountSold = d.amountSold;
    amountSold = Number(amountSold.toString());
  }

  // item price
  let price;
  if (saleType === 'Marketplace') {
    price = ethers.utils.formatUnits(d.price.toString(), 'ether');
    // console.log('price:', price)
  }

  // auction specific
  let startingPrice;
  let minimumIncrement;
  let lastHighestBid;
  let lasthHighestBidPlacingTime;
  let lastHighestBidder;
  let duration;

  if (saleType === 'Auction') {
    startingPrice = ethers.utils.formatUnits(
      d.startingPrice.toString(),
      'ether'
    );

    minimumIncrement = ethers.utils.formatUnits(
      d.minimumIncrement.toString(),
      'ether'
    );

    lastHighestBid = ethers.utils.formatUnits(
      d.lastHighestBid.toString(),
      'ether'
    );

    lasthHighestBidPlacingTime = Number(d.lastHighestBidPlacingTime.toString());
    lastHighestBidder = d.lastHighestBidder;
    duration = Number(d.duration.toString());
  }

  const tokenInfo = await getTokenInfo(tokenContract, d.tokenId);
  console.log('tokenInfo:', tokenInfo)

  // // artist
  // const artist = await tokenContract.getCreator(Number(d.tokenId));
  // // metadata retrieval starts
  // let _cid;
  // if (d.isERC1155) {
  //   _cid = await tokenContract.uri(Number(d.tokenId));
  // } else {
  //   _cid = await tokenContract.tokenURI(Number(d.tokenId));
  // }

  // //console.log('_cid:', _cid);
  // const _tokenUri = getCidUrlNftstorage(_cid);
  // //console.log('tokenUri:', _tokenUri);
  // const metadata = await axios.get(_tokenUri);
  // console.log('metadata:', metadata.data);
  // const category = metadata.data.meta.category;
  // const zone = metadata.data.zone;
  // // const featureIndices = metadata.data.featureIndices

  // let branch;

  // let imageUrl;

  // imageUrl = metadata.data.imageUrl;

  // let tokenUri;
  // if (imageUrl !== undefined) {
  //   tokenUri = getCidUrlWeb3storage(imageUrl);
  // }
  // let _musicUrl, musicUrl;
  // if (category === 'Music') {
  //   _musicUrl = metadata.data.musicUrl;
  //   musicUrl = getCidUrlWeb3storage(_musicUrl);
  // }

  // const name = metadata.data.meta.name;
  // const compatibleGame = metadata.data.meta.compatibleGame;
  // const description = metadata.data.meta.description;
  // const otherInfo = metadata.data.meta.otherInfo;
  // const externalNftUrl = metadata.data.meta.externalNftUrl;
  // const externalArtistUrl = metadata.data.meta.externalArtistUrl;
  // const facebook = metadata.data.meta.facebook;
  // const twitter = metadata.data.meta.twitter;
  // const youtube = metadata.data.meta.youbute;
  // const pinterest = metadata.data.meta.pinterest;
  // const instagram = metadata.data.meta.instagram;
  // const dribble = metadata.data.meta.dribble;
  // metadata retrieval ends

  newItem = {
    itemId: d.itemId.toNumber(),
    // category: tokenInfo.category,
    tokenType: tokenInfo.tokenType,
    originalSupply: tokenInfo.originalSupply,
    metadata: tokenInfo.metadata,
    // name: tokenInfo.name,
    // tags: tokenInfo.tags,
    // attributes: tokenInfo.attributes,
    // description: tokenInfo.description,
    // otherInfo: tokenInfo.otherInfo,
    // externalNftUrl: tokenInfo.externalNftUrl,
    // externalArtistUrl: tokenInfo.externalArtistUrl,
    // youtube: tokenInfo.youtube,
    tokenContractAddress: d.nftContract,
    tokenId: d.tokenId.toNumber(),
    tokenUri: tokenInfo.tokenUri,
    previewUrl: tokenInfo.previewUrl,
    musicUrl: tokenInfo.musicUrl,
    seller: d.seller,
    owner: d.currentOwner,
    artist: tokenInfo.artist,
    price,
    startingPrice,
    minimumIncrement,
    lastHighestBid,
    lasthHighestBidPlacingTime,
    lastHighestBidder,
    duration,
    isSold: d.isSold,
    isCancelled: d.isCancelled,
    isERC1155: d.isERC1155,
    amount,
    amountSold,
  };

  console.log('newItem:', newItem);
  itemInfo = {
    newItem,
  };

  return itemInfo;
};
