import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { parse, format } from '../utils/helpers'

const RoyaltyForm = ({ index, royalty, setRoyalty }) => {
  const handleRoyalty = (index, value) => {
    value = parse(value);
    const updatedRoyalties = [...royalty];
    updatedRoyalties[index] = value;
    setRoyalty(updatedRoyalties);
    // console.log('royalty:', royalty);
  };

  return (
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Royalty:</FormLabel>
          <NumberInput
            onChange={valueString => handleRoyalty(index, valueString)}
            value={format(royalty[index])}
            precision={2}
            step={0.1}
            max={100}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Box>
  );
};

export default RoyaltyForm;
