import UpdateTokensInGame from '../../gameIntegration/UpdateTokensInGame';

const UpdateGameIntegrationByUser = ({ tokenContract, tokenId }) => {
  return (
    <>
      <UpdateTokensInGame
        useCase="TokenByUser"
        tokenContract={tokenContract}
        tokenId={tokenId}
      />
    </>
  );
};

export default UpdateGameIntegrationByUser;
