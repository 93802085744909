import {
  Box,
  Button,
  Text,
  Stack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  CardFooter,
  VStack,
  Wrap,
  WrapItem,
  StackDivider,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useState } from 'react';
import { ethers } from 'ethers';
import GameTokenGallery from '../../../contracts/APIs/GameTokenGalleryInterface.sol/GameTokenGalleryInterface.json';
import BatchOptionsForm from '../../formComponents/BatchOptionsForm';
import GameTokenIdForm from '../../formComponents/GameTokenIdForm';
import ChooseContractForm from '../../formComponents/ChooseContractForm';
import SetContractAddrForm from '../../formComponents/SetContractAddrForm';
import TokenIdForm from '../../formComponents/TokenIdForm';
import SwitchElementForm from '../../formComponents/SwitchElementForm';
import UpdateGameBranchForm from '../../formComponents/UpdateGameBranchForm';
import {
  filterArrayByBool,
} from '../../utils/helpers';
import ChooseFeatureForm from '../../formComponents/ChooseFeatureForm';
import { REACT_APP_GAME_TOKEN_GALLERY_ADDRESS, REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../Constants/ContractAddress';

const UpdateTokenInGameBase = ({
  specificGameTokenId = 0,
  useCase = '',
  tokenContract = '',
  tokenId = 0,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  //   console.log('specificGameTokenId:', typeof specificGameTokenId);
  const initialContractAddr = REACT_APP_MAGIC_NFT_ERC721_ADDRESS;
  const [batch, setBatch] = useState('1');
  const [singleContractAddr, setSingleContractAddr] =
    useState(initialContractAddr);
  const [tokenIds, setTokenIds] = useState([0]);
  const [gameTokenId, setGameTokenId] = useState(specificGameTokenId);
  const [updateBranch, setUpdateBranch] = useState([false]);
  const [replaceFeatures, setReplaceFeatures] = useState([false]);
  const [reactivateGallery, setReactivateGallery] = useState([false]);
  const [removeGameIntegration, setRemoveGameIntegration] = useState([false]);
  const [branches, setBranches] = useState(['']);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showGameFeatures, setShowGameFeatures] = useState(false);
  const [listOfGameFeatures, setListOfGameFeatures] = useState([]);
  const [loading, setLoading] = useState(false);

  const addToken = () => {
    setTokenIds([...tokenIds, 0]);
    setUpdateBranch([...updateBranch, false]);
    setBranches([...branches, '']);
    setReplaceFeatures([...replaceFeatures, false]);
    setRemoveGameIntegration([...removeGameIntegration, false]);
    setReactivateGallery([...reactivateGallery, false]);
    // setEmailAlertsEnabled([...emailAlertsEnabled, false]);
    const _newCheckedItem = checkedItems[0].map(c => false);
    console.log('_newCheckedItem', _newCheckedItem);
    setCheckedItems([...checkedItems, _newCheckedItem]);
  };

  const removeToken = index => {
    const updatedTokens = [...tokenIds];
    updatedTokens.splice(index, 1);
    setTokenIds(updatedTokens);
  };

  const create2dArray = n => {
    let arr = [];
    for (let i = 0; i < n; i++) {
      let _arr = [''];
      arr.push(_arr);
    }
    return arr;
  };

  const create = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const _gameTokenId = Number(gameTokenId);

      const gameTokenGallery = new ethers.Contract(
        REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
        GameTokenGallery.abi,
        signer
      );

      const _tokenIds =
        useCase === 'TokenByUser' ? [tokenId] : tokenIds.map(t => Number(t));
      const _tokenContract =
        useCase === 'TokenByUser' ? tokenContract : singleContractAddr;

      // params

      let features = [];
      for (let m = 0; m < checkedItems.length; m++) {
        let _feature = filterArrayByBool(checkedItems[m], listOfGameFeatures);
        _feature = _feature.map(item => item.name);
        console.log('_feature:', _feature);
        features.push(_feature);
      }
      if (features.length === 0) {
        features = create2dArray(_tokenIds.length);
      }
      console.log('features:', features);

      let _param = {
        updateBranch,
        replaceFeatures, // replace all the old features with new features
        removeGameIntegration,
        reactivateGallery,
        tokenContract: _tokenContract,
        tokenIds: _tokenIds,
        gameId: _gameTokenId,
        branches,
        features,
      };

      console.log('_param:', _param);

      let transactionList =
        await gameTokenGallery.updateGameIntegrationInTokens(_param);

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Submit
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Submit
        </Button>
      );
    }
  };

  const RenderTitle = () => {
    const _label =
      useCase === 'TokenByUser'
        ? 'Remove Game Compatibility / Integration '
        : 'Remove Game Compatibility / Integration from Token(s)';
    return (
      <Box>
        <Text fontSize={36} color="teal.500" style={{ fontFamily: 'Lobster' }}>
          Update Token(s) in Game
        </Text>
      </Box>
    );
  };

  return (
    <div>
      <VStack>
        <RenderTitle />
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              {useCase !== 'TokenByUser' && (
                <>
                  <BatchOptionsForm
                    batch={batch}
                    setBatch={setBatch}
                    label="I would like to apply this action on"
                  />
                </>
              )}

              {specificGameTokenId === 0 && (
                <Box>
                  <GameTokenIdForm
                    gameTokenId={gameTokenId}
                    setGameTokenId={setGameTokenId}
                  />
                </Box>
              )}

              {useCase !== 'TokenByUser' && (
                <Box>
                  <ChooseContractForm
                    singleContractAddr={singleContractAddr}
                    setSingleContractAddr={setSingleContractAddr}
                  />

                  {singleContractAddr === 'Other Contract Address' && (
                    <SetContractAddrForm
                      singleContractAddr={singleContractAddr}
                      setSingleContractAddr={setSingleContractAddr}
                    />
                  )}
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>

        <Wrap>
          {tokenIds.map((token, index) => (
            <WrapItem key={index}>
              <Box
                style={{
                  display: 'grid',
                  minHeight: '200px',
                  border: '8px solid',
                  padding: '1rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                  marginTop: '20px',
                }}
              >
                <Card maxW="sm">
                  {useCase !== 'TokenByUser' && (
                    <CardHeader>
                      <Heading
                        fontSize={28}
                        color="teal.500"
                        style={{ fontFamily: 'Lobster' }}
                        textAlign="center"
                      >
                        NFT #{index + 1}
                      </Heading>
                    </CardHeader>
                  )}

                  <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                      {useCase !== 'TokenByUser' && (
                        <TokenIdForm
                          index={index}
                          tokenIds={tokenIds}
                          setTokenIds={setTokenIds}
                        />
                      )}

                      {/* <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Enable email alerts?
                          </FormLabel>
                          <Switch
                            id={`email-alerts-${index}`}
                            onChange={() => handleSwitchChange(index)}
                          />
                        </FormControl> */}

                      <SwitchElementForm
                        index={index}
                        item={updateBranch}
                        setItem={setUpdateBranch}
                        label="Update Game Branch?"
                      />

                      {updateBranch[index] == true && (
                        <UpdateGameBranchForm
                          index={index}
                          gameBranch={branches}
                          setGameBranch={setBranches}
                          label="New Game Branch"
                        />
                      )}

                      <SwitchElementForm
                        index={index}
                        item={replaceFeatures}
                        setItem={setReplaceFeatures}
                        label="Update Game Features?"
                      />

                      {replaceFeatures[index] && gameTokenId > 0 && (
                        <Box
                          display="flex"
                          alignItems="baseline"
                          mb="20px"
                          style={{
                            fontSize: '1.2rem',
                            display: 'grid',

                            border: '8px solid',
                            padding: '1rem',
                            borderWidth: '10px',
                            borderImage:
                              'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
                          }}
                        >
                          <Text align="center" color="teal.500">
                            Choose New Features:
                          </Text>

                          <ChooseFeatureForm
                            index={index}
                            gameTokenId={gameTokenId}
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                            listOfGameFeatures={listOfGameFeatures}
                            setListOfGameFeatures={setListOfGameFeatures}
                            showGameFeatures={showGameFeatures}
                            setShowGameFeatures={setShowGameFeatures}
                            tokenContract={singleContractAddr}
                            tokenId={0}
                            label="Add"
                          />
                        </Box>
                      )}

                      <SwitchElementForm
                        index={index}
                        item={removeGameIntegration}
                        setItem={setRemoveGameIntegration}
                        label="Remove Game Integration?"
                      />

                      <SwitchElementForm
                        index={index}
                        item={reactivateGallery}
                        setItem={setReactivateGallery}
                        label="Reactivate Game Integration?"
                      />
                    </Stack>
                  </CardBody>

                  {batch === '2' && (
                    <CardFooter>
                      <Button
                        onClick={() => removeToken(index)}
                        variant="outline"
                        colorScheme="red"
                      >
                        Remove this token
                      </Button>
                    </CardFooter>
                  )}
                </Card>
              </Box>
            </WrapItem>
          ))}
        </Wrap>

        {batch === '2' && (
          <Button onClick={addToken} variant="outline" colorScheme="green">
            Add Token
          </Button>
        )}

        <RenderButton />
      </VStack>
    </div>
  );
};

export default UpdateTokenInGameBase;
