import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { ethers } from 'ethers';
import AuctionHouse from '../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { REACT_APP_AUCTION_HOUSE_ADDRESS } from '../../../Constants/ContractAddress';
const CloseAuction = ({ auctionId, gameIdsIntegrated = [] }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  const closeAuction = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();

    try {
      const auctionHouse = new ethers.Contract(
        REACT_APP_AUCTION_HOUSE_ADDRESS,
        AuctionHouse.abi,
        signer
      );

      let transferGalleryOwnership = false;
      const idsLength = gameIdsIntegrated.length;
      if (idsLength > 0 && gameIdsIntegrated[idsLength - 1] > 0) {
        transferGalleryOwnership = true;
      }
      const gameId = transferGalleryOwnership
        ? gameIdsIntegrated[idsLength - 1]
        : 0;

      const _helperParam = {
        transferGalleryOwnership,
        gameId,
        updateBranch: false,
        newBranch: '',
      };
      // console.log('_helperParam:', _helperParam);

      const transaction = await auctionHouse.closeAuction(
        auctionId,
        _helperParam
      );

      await transaction.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="whatsapp"
          variant="outline"
          size="md"
          mr={3}
        >
          Close Auction
        </Button>
      );
    } else {
      return (
        <Button
          onClick={closeAuction}
          colorScheme="whatsapp"
          variant="outline"
          size="md"
          mr={3}
        >
          Close Auction
        </Button>
      );
    }
  };

  return (
    <>
      <Box>
        <RenderButton />
      </Box>
    </>
  );
};

export default CloseAuction;
