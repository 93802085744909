import {
  VStack,
  HStack,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaTwitter, FaFacebookF, FaYoutube } from 'react-icons/fa';

const RenderGameTabs = ({ item }) => {
  return (
    <>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>Description</Tab>
          <Tab>Features</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <Box textAlign="center" alignItems="baseline">
                {item.description}
              </Box>

              <Box textAlign="center" alignItems="baseline">
                <Link href={item.externalUrlToGame} isExternal>
                  <Text as="i">Go to game website</Text>
                  <Tooltip
                    label={item.externalUrlToGame}
                    aria-label="A tooltip"
                  >
                    <ExternalLinkIcon mx="2px" />
                  </Tooltip>
                </Link>
              </Box>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Text>Features for External Integration</Text>
            {item.gameFeaturesExternal.length > 0 &&
              item.gameFeaturesExternal.map((feature, i) => (
                <Text>{feature}</Text>
              ))}
            <Text>Features Reserved for Internal Integration</Text>
            {item.gameFeaturesReserved.length > 0 &&
              item.gameFeaturesReserved.map((feature, i) => (
                <Text>{feature}</Text>
              ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <Accordion allowMultiple>
        <RenderDescriptionAccording item={item} />
        <RenderOtherInfoAccording item={item} />
        <RenderGameFeaturesAccording item={item} />
        <RenderTagsAccording item={item} />
        {allowExternalIntegration && (
          <RenderGameIntegrationFeesAccording
            featureAddingFees={featureAddingFees}
          />
        )}
      </Accordion> */}
    </>
  );
};

export default RenderGameTabs;
