import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link, VStack, Box, Wrap, WrapItem } from '@chakra-ui/react';
import getAllGamesOwnedBy from './elements/getAllGamesOwnedBy';
import DetailButton from './elements/DetailButton';
import UpdateGameModal from '../../actions/NFTs/userActions/games/UpdateGameModal';
import { Player } from 'video-react';
import GameOwnerActions from '../../actions/NFTs/userActions/GameOwnerActions';

function AllGamesOwnedBy({ provider, addr }) {
  const [allGames, setAllGames] = useState([]);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const _allGames = await getAllGamesOwnedBy(provider, addr);
    setAllGames(_allGames);
  };

  function RenderImage({ item }) {
    if (Object.keys(item).length > 0) {
      return <Player playsInline poster={item.posterUrl} src={item.videoUrl} />;
    }
  }

  const ShowAllGames = () => {
    return (
      <VStack spacing={4}>
        {allGames.length &&
          allGames.map((g, key) => (
            <Box
              key={key}
              maxW="lg"
              textAlign="center"
              style={{
                display: 'grid',
                minHeight: '200px',
                border: '8px solid',
                padding: '1rem',
                borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
                marginTop: '20px',
              }}
            >
              <Box>
                <img
                  src={g.image}
                  style={{
                    width: '300px',
                    margin: 'auto',
                    display: 'block',
                  }}
                />
                <RenderImage item={g} />
              </Box>

              <Box>{g.gameName}</Box>
              <Box mt={6}>
                <Link as={ReactLink} to={`/gamelist/${g.gameId}`}>
                  <DetailButton buttonSize="sm" label="" />
                </Link>
              </Box>
              {/* <Box mt={6}>
                    <UpdateGameModal gameId={g.gameId} />
                  </Box> */}
              <Box mt={6}>
                <GameOwnerActions gameId={g.gameId} />
              </Box>
            </Box>
          ))}
      </VStack>
    );
  };

  return (
    <VStack>
      <ShowAllGames />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllGamesOwnedBy;
