import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';

const AddTokensRadioGroup = ({ addToken, setAddToken, formType }) => {
  return (
    <Box style={{ marginBottom: '20px' }}>
      <FormControl isRequired>
        <FormLabel as="legend">
          Would you like to add tokens to this {formType} now?
        </FormLabel>
        <RadioGroup onChange={setAddToken} value={addToken} colorScheme="green">
          <Stack direction="row">
            <Radio value="true">Yes</Radio>
            <Radio value="false">No. I will add tokens later.</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default AddTokensRadioGroup;
