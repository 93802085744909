import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

const GameAdminModeForm = ({ approveAdmin, setApproveAdmin }) => {
  return (
    <>
      <Box mb='20px'>
        <FormControl isRequired>
          <FormLabel as="legend">Approve / Didapprove Game Admin</FormLabel>
          <RadioGroup
            onChange={setApproveAdmin}
            value={approveAdmin}
            colorScheme="green"
          >
            <Stack direction="row">
              <Radio value="1">Approve</Radio>
              <Radio value="2">Disapprove</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default GameAdminModeForm;
