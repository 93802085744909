import AddGameIntegration from '../../gameIntegration/AddGameIntegration';

const AddGameIntegrationByUser = ({ tokenType, tokenContract, tokenId }) => {
  return (
    <>
      <AddGameIntegration
        tokenType={tokenType}
        tokenContract={tokenContract}
        tokenId={tokenId}
        useCase="TokenByUser"
      />
    </>
  );
};

export default AddGameIntegrationByUser;
