import {
  Box,
  Button,
  Stack,
  Card,
  CardBody,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import '../../../styles/Frame.css';
import { useState } from 'react';
import { ethers } from 'ethers';
import { deleteFile, makeGameMetaUrl } from '../../utils/mintHelpers';
import Helper from '../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import HighlightTokenMetaForm from '../../formComponents/HighlightTokenMetaForm';
import { REACT_APP_HELPER_ADDRESS } from '../../Constants/ContractAddress';

const UpdateHighlightsBase = ({
  pageName,
  lastCid,
  initialMeta = {
    posterUrl: '',
    videoUrl: '',
    name: '',
    description: '',
    otherInfo: '',
    tags: '',
    externalUrl: '',
    X: '',
    facebook: '',
    youtube: '',
    cTokenContracts: [],
    cTokenIds: [],
    marketplaceIdsErc721: [],
    marketplaceIdsErc1155: [],
    auctionIds: [],
  },
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const provider = new ethers.providers.Web3Provider(walletProvider);
  // no minting starts

  const [meta, setMeta] = useState({
    posterUrl: initialMeta.posterUrl,
    videoUrl: initialMeta.videoUrl,
    name: initialMeta.name,
    description: initialMeta.description,
    otherInfo: initialMeta.otherInfo,
    tags: initialMeta.tags,
    externalUrl: initialMeta.externalUrl,
    X: initialMeta.X,
    facebook: initialMeta.facebook,
    youtube: initialMeta.youtube,
    cTokenContracts: initialMeta.cTokenContracts,
    cTokenIds: initialMeta.cTokenIds,
    marketplaceIdsErc721: initialMeta.marketplaceIdsErc721,
    marketplaceIdsErc1155: initialMeta.marketplaceIdsErc1155,
    auctionIds: initialMeta.auctionIds,
  });

  const [loading, setLoading] = useState(false);

  function removeDuplicatesFromString(str) {
    // Split the string by commas and trim each element to remove leading/trailing spaces
    const array = str.split(',').map(item => item.trim());

    // Create a new Set from the array to automatically remove duplicates
    const uniqueArray = [...new Set(array)];

    return uniqueArray;
  }

  const create = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      if (pageName == 'Shop') {
        meta.marketplaceIdsErc721 = removeDuplicatesFromString(
          meta.marketplaceIdsErc721
        );
        meta.marketplaceIdsErc1155 = removeDuplicatesFromString(
          meta.marketplaceIdsErc1155
        );
        meta.auctionIds = removeDuplicatesFromString(meta.auctionIds);
      }
      console.log('meta:', meta);
      let _cid = await makeGameMetaUrl(meta);
      console.log('_cid:', _cid);

      if (lastCid.length > 0) {
        await deleteFile(lastCid);
      }

      const helper = new ethers.Contract(
        REACT_APP_HELPER_ADDRESS,
        Helper.abi,
        signer
      );

      let transactionList;

      const _isForArtist = pageName == 'Artist' ? true : false;

      transactionList = await helper.updateTokenHighlights(_cid, _isForArtist);

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Submit
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Submit
        </Button>
      );
    }
  };

  return (
    <div>
      <VStack>
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4"></Stack>
          </CardBody>
        </Card>

        <Box
          style={{
            display: 'grid',
            minHeight: '200px',
            border: '8px solid',
            padding: '1rem',
            borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
            marginTop: '20px',
          }}
        >
          <Card maxW="sm">
            <CardBody>
              <>
                <HighlightTokenMetaForm
                  provider={provider}
                  meta={meta}
                  setMeta={setMeta}
                  pageName={pageName}
                />
              </>
            </CardBody>
          </Card>
        </Box>

        <RenderButton />
      </VStack>
    </div>
  );
};

export default UpdateHighlightsBase;
