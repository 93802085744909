import {
  Container,
  Text,
  Card,
  CardHeader,
  CardBody,
  Heading,
  VStack,
  StackDivider,
  Stack,
  Box,
} from '@chakra-ui/react';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';

function TermsAndConditions() {
  return (
    <Container>
      <VStack spacing={3}>
        <Box mt={2}><HomeIcon /></Box>
        <Box mt={8}>
          <Heading size="md">Terms and Conditions</Heading>
        </Box>
        <Card  boxShadow='xl' rounded='md'>
          <CardHeader></CardHeader>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs">User Agreement for Magic NFT Valley</Heading>
                <Text pt="2" fontSize="sm">
                  This User Agreement (the "Agreement") is a legal agreement
                  between you (referred to herein as "User," "you," or "your")
                  and Magic NFT Valley DAO, the operator of the
                  magicnftvalley.com (the "Platform"). By accessing or using the
                  Platform, you agree to be bound by the terms and conditions of
                  this Agreement.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">1. Use of the Platform</Heading>
                <Text pt="2" fontSize="sm">
                  1.1. You acknowledge and understand that the Platform operates
                  as a decentralized marketplace for NFTs. You are solely
                  responsible for your use of the Platform, including the
                  creation, purchase, sale, or auction of NFTs.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">2. NFT Sales and Auctions</Heading>
                <Text pt="2" fontSize="sm">
                  2.1. The Platform allows you to buy, sell, and auction NFTs.
                  Users are solely responsible for the content, descriptions,
                  and pricing of their NFT listings.
                </Text>
                <Text pt="2" fontSize="sm">
                  2.2. You acknowledge and agree that the transactions conducted
                  through the Platform are between users, and the Company is not
                  a party to these transactions. The Company does not guarantee
                  the accuracy, legality, quality, or authenticity of NFT
                  listings.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">
                  3. Intellectual Property and Copyright
                </Heading>
                <Text pt="2" fontSize="sm">
                  3.1. You represent and warrant that you have the legal right
                  to mint and list any NFTs on the Platform. You agree not to
                  list or sell NFTs that infringe on the intellectual property
                  rights of others.
                </Text>
                <Text pt="2" fontSize="sm">
                  3.2. The Platform respects intellectual property rights. If
                  you believe that any NFT listing on the Platform infringes on
                  your copyright or other intellectual property rights, please
                  contact us at [contact@email.com] with a detailed description
                  of the alleged infringement.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">4. Fees and Payments</Heading>
                <Text pt="2" fontSize="sm">
                  4.1. Fees may apply for certain transactions on the Platform,
                  including transaction fees and minting fees. The Company
                  reserves the right to change these fees at its discretion.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">5. Disclaimers</Heading>
                <Text pt="2" fontSize="sm">
                  5.1. The Platform is provided "as is" and "as available"
                  without warranties of any kind, either express or implied.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">6. Limitation of Liability</Heading>
                <Text pt="2" fontSize="sm">
                  6.1. To the fullest extent permitted by applicable law, the
                  Company shall not be liable for any indirect, incidental,
                  special, consequential, or punitive damages, or any loss of
                  profits or revenues, whether incurred directly or indirectly,
                  or any loss of data, use, goodwill, or other intangible
                  losses.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">7. Changes to the Agreement</Heading>
                <Text pt="2" fontSize="sm">
                  7.1. The Company reserves the right to modify this Agreement
                  at any time. You are responsible for regularly reviewing the
                  Agreement. Your continued use of the Platform after the
                  effective date of any modifications constitutes your
                  acceptance of those modifications.
                </Text>
              </Box>
              <Box>
                <Heading size="xs">8. Contact Information</Heading>
                <Text pt="2" fontSize="sm">
                  8.1. For questions, concerns, or feedback regarding this
                  Agreement, please contact us at [contact@email.com].
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </VStack>
    </Container>
  );
}

export default TermsAndConditions;
