import { Link as ReactLink } from 'react-router-dom';
import { Link, HStack, Box, Text } from '@chakra-ui/react';
import AuctionPriceFrame from '../../../../../../resources/images/frames/flowerframe28.png';
import Timer from '../../../../../utils/Timer';
import PlaceBid from '../../../../../actions/NFTs/auctions/PlaceBid';
import CloseAuction from '../../../../../actions/NFTs/auctions/CloseAuction';
import CancelAuction from '../../../../../actions/NFTs/auctions/CancelAuction';
import LotteryFrame from '../../../../../../resources/images/frames/heartflower23.png';

export const RenderButtonGroup = props => {
  // console.log('props:', props.auctionId);
  const currentTime = new Date().getTime() / 1000;
  const endingTime =
    props.item.lasthHighestBidPlacingTime + props.item.duration;
  const lastHighestBid = Number(props.item.lastHighestBid);
  const isEnded = lastHighestBid > 0 ? endingTime <= currentTime : false;

  return (
    <>
      {!props.item.isSold && !isEnded && (
        <PlaceBid auctionId={props.auctionId} />
      )}

      {!props.item.isSold && isEnded && lastHighestBid > 0 && (
        <CloseAuction auctionId={props.auctionId} />
      )}

      {/* {props.item.isSold &&
        Number(props.item.lotteryRewards) > 0 &&
        props.item.lotteryRewardsClaimed === 'false' && (
          <ClaimAuctionLotteryRewards auctionId={props.auctionId} />
        )} */}

      {isEnded && lastHighestBid === 0 && (
        <CancelAuction auctionId={props.auctionId} />
      )}
    </>
  );
};

export const RenderAuctionEndingTime = props => {
  const currentTime = new Date().getTime() / 1000;
  const endingTime =
    props.item.lasthHighestBidPlacingTime + props.item.duration;

  const _lastHighestBid = props.item.lastHighestBid;

  if (_lastHighestBid == 0) {
    return <Box></Box>;
  }

  if (endingTime > currentTime) {
    return (
      <HStack>
        <Text color="teal.500" fontWeight="bold">
          Auction ends in
        </Text>
        <Timer gameStartTime={endingTime} />
      </HStack>
    );
  }

  return (
    <Box>
      <Text color="teal.500" as="i">
        {' '}
        Auction Ended{' '}
      </Text>
    </Box>
  );
};

export const RenderLottery = props => {
  if (Number(props.item.lotteryRewards) > 0) {
    return (
      <>
        <Link as={ReactLink} to={`/lotteries/auctions/${props.auctionID}`}>
          <div
            style={{
              backgroundImage: `url(${LotteryFrame})`,
              width: '318px',
              height: '292px',
              textAlign: 'center',
              paddingTop: '88px',
            }}
          >
            <Text color="teal.500">Lottery Rewards: </Text>
            {props.item.lotteryRewards} ether
          </div>
        </Link>
      </>
    );
  } else if (props.item.lotteryRatio > 0) {
    return (
      <>
        <Link as={ReactLink} to={`/lotteries/auctions/${props.auctionID}`}>
          <div
            style={{
              backgroundImage: `url(${LotteryFrame})`,
              width: '318px',
              height: '292px',
              textAlign: 'center',
              paddingTop: '88px',
            }}
          >
            <Text color="teal.500">Lottery Ratio: </Text>
            {props.item.lotteryRatio / 100} %
          </div>
        </Link>
      </>
    );
  } else {
    return '';
  }
};

// auction specific
export const RenderAuctionPrice = props => {
  return (
    <div
      style={{
        backgroundImage: `url(${AuctionPriceFrame})`,
        width: '385px',
        height: '289px',
        textAlign: 'center',
        paddingTop: '30px',
      }}
    >
      <Text color="teal.500"> Starting Price:&nbsp; </Text>{' '}
      {props.item.startingPrice} MATIC
      <Text color="teal.500"> Last Highest Bid:&nbsp; </Text>{' '}
      {props.item.lastHighestBid} MATIC
      <Text color="teal.500"> Minimum Increment:&nbsp; </Text>{' '}
      {props.item.minimumIncrement} MATIC
    </div>
  );
};
