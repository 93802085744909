import React from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import {
  REACT_APP_MAGIC_NFT_ERC1155_ADDRESS,
  REACT_APP_MAGIC_NFT_ERC721_ADDRESS,
} from '../Constants/ContractAddress';
const ChooseContractForm = ({
  singleContractAddr,
  setSingleContractAddr,
  tokenType = 'Full',
}) => {
  const RenderRadio = () => {
    if (tokenType === 'Erc721') {
      return (
        <Radio value={REACT_APP_MAGIC_NFT_ERC721_ADDRESS}>
          Magic NFT Erc721 contract
        </Radio>
      );
    } else if (tokenType === 'Erc1155') {
      return (
        <Radio value={REACT_APP_MAGIC_NFT_ERC1155_ADDRESS}>
          Magic NFT Erc1155 contract
        </Radio>
      );
    } else if (tokenType === 'Full') {
      return (
        <>
          <Radio value={REACT_APP_MAGIC_NFT_ERC721_ADDRESS}>
            Magic NFT Erc721 contract
          </Radio>
          <Radio value={REACT_APP_MAGIC_NFT_ERC1155_ADDRESS}>
            Magic NFT Erc1155 contract
          </Radio>
        </>
      );
    }
  };
  return (
    <>
      <FormControl isRequired>
        <FormLabel as="legend">NFT Token Contract Address:</FormLabel>
        <RadioGroup
          onChange={setSingleContractAddr}
          value={singleContractAddr}
          colorScheme="green"
        >
          <Stack direction="column">
            <RenderRadio />
            {/* <Radio value={REACT_APP_MAGIC_NFT_ADDRESS}>
              Magic NFT contract
            </Radio>
            <Radio value={REACT_APP_MAGIC_NFT_ERC1155_ADDRESS}>
              Magic NFT Erc1155 contract
            </Radio> */}
            <Radio value="Other Contract Address">Other contract</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default ChooseContractForm;
