import { ethers } from 'ethers';
import axios from 'axios';
import { getCidUrlNftstorage } from '../../../utils/helpers';
import Helper from '../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import ITokenURI from '../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import { REACT_APP_HELPER_ADDRESS, REACT_APP_MAGIC_NFT_ERC1155_ADDRESS, REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../../Constants/ContractAddress';
const getAllTokensCreatedBy = async (provider, _isErc1155, _creator) => {
  let tokenContractAddress;
  if (_isErc1155) {
    tokenContractAddress = REACT_APP_MAGIC_NFT_ERC1155_ADDRESS;
  } else {
    tokenContractAddress = REACT_APP_MAGIC_NFT_ERC721_ADDRESS;
  }

  // const signer = provider.getSigner();
  // const addr = await signer.getAddress();

  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );

  const tokenContract = new ethers.Contract(
    tokenContractAddress,
    ITokenURI.abi,
    provider
  );

  const _allTokensCreated = await helper.getAllTokensCreatedBy(
    _creator,
    tokenContractAddress
  );
  // console.log('_allTokensCreated:', _allTokensCreated);
  const tokens = _allTokensCreated.map(num => Number(num.toString()));
  let allTokensCreated = [];

  for (let i = 0; i < tokens.length; i++) {
    const tokenId = tokens[i];
    let _cid;
    if (_isErc1155) {
      _cid = await tokenContract.uri(tokenId);
    } else {
      _cid = await tokenContract.tokenURI(tokenId);
    }

    const _tokenUri = getCidUrlNftstorage(_cid);
    let metadata, imageUrl, musicUrl, tokenUri, category;
    try {
      metadata = await axios.get(_tokenUri);
      // console.log('metadata:', metadata.data);
    } catch (error) {
      // console.log('metadata error:', typeof metadata);
      console.log(error);
    }

    category = metadata.data.category;

    imageUrl = metadata.data.image;

    tokenUri = imageUrl;

    if (category === 'Music') {
      musicUrl = metadata.data.musicUrl;
      // console.log('_musicUrl:', _musicUrl);
    }
    const tokenName = metadata.data.name;
    allTokensCreated.push({
      tokenId,
      tokenUri,
      musicUrl,
      tokenName,
      tokenContractAddress,
    });
  }
  // console.log('allTokensCreated:', allTokensCreated);
  return allTokensCreated;
};

export default getAllTokensCreatedBy;
