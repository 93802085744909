const getCollectionInfo = async (collectionsContract, _tokenAddr, _tokenId) => {
    const _collection = await collectionsContract.getCollectionByToken(
        _tokenAddr,
        Number(_tokenId)//Number(d.tokenId)
      );
      console.log('_collection:', _collection);
    //   const collectionName = _collectionId.collectionName;
    
      const creatorCollectionId = Number(
        _collection.creatorCollectionId.toString()
      );
      const sellerCollectionId = Number(
        _collection.sellerCollectionId.toString()
      );
      let creatorCollection,
        sellerCollection,
        creatorCollectionName,
        sellerCollectionName;
      if (creatorCollectionId > 0) {
        creatorCollection = await collectionsContract.getCollection(
          creatorCollectionId
        );
        creatorCollectionName = creatorCollection._collectionName;
      }
    
      if (sellerCollectionId > 0) {
        sellerCollection = await collectionsContract.getCollection(
          sellerCollectionId
        );
        sellerCollectionName = sellerCollection._collectionName;
      }
    
     const collectionInfo = {
        creatorCollection: {
          collectionId: Number(creatorCollectionId.toString()),
          collectionName: creatorCollectionName,
        },
        sellerCollection: {
          collectionId: Number(sellerCollectionId.toString()),
          collectionName: sellerCollectionName,
        },
      };
      console.log('collectionInfo:', collectionInfo);
      return collectionInfo
}

export default getCollectionInfo