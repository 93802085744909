import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  HStack,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export const socialMediaFields = [
  'twitter',
  'facebook',
  'youtube',
  'pinterest',
  'instagram',
];

export const socialMediaUrlPrefix = [
  'https://twitter.com/',
  'https://www.facebook.com/',
  'https://www.youtube.com/',
  'https://www.pinterest.com/',
  'https://www.instagram.com/',
];

export const urlPrefix = {
  general: 'https://',
  X: 'https://twitter.com/',
  facebook: 'https://www.facebook.com/',
  youtube: 'https://www.youtube.com/',
  pinterest: 'https://www.pinterest.com/',
  instagram: 'https://www.instagram.com/',
};

export function validUrl(url, urlType) {
  // Use the startsWith() method to check if the URL starts with "https://"
  return url.startsWith(urlPrefix[urlType]);
}

export const SocialMediaUrlChecker = ({ fieldName, url }) => {
  // Find the index of the field name in socialMediaFields
  const index = socialMediaFields.indexOf(fieldName);

  // Check if the index is valid and if the URL starts with the corresponding prefix
  const isValid = index !== -1 && url.startsWith(socialMediaUrlPrefix[index]);
  return isValid;
};

export const areUrlsFromSameDomain = (url1, url2) => {
  const parsedUrl1 = new URL(url1);
  const parsedUrl2 = new URL(url2);

  return parsedUrl1.hostname === parsedUrl2.hostname;
};

export const UrlPrefixWarning = props => {
  if (props.urlType == 'general') {
    return (
      <HStack>
        <InfoIcon />
        <Text as="i" fontSize="sm">
          If your external url doesn't start with {urlPrefix[props.urlType]}, a
          safety warning would be displayed when shown to thers.
        </Text>
      </HStack>
    );
  } else {
    return (
      <HStack>
        <InfoIcon />
        <Text as="i" fontSize="sm">
          If your {props.urlType} url doesn't start with{' '}
          {urlPrefix[props.urlType]}, a safety warning would be displayed when
          shown to thers.
        </Text>
      </HStack>
    );
  }
};

export const ExternalUrlForm = props => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">External Url for NFT:</FormLabel>
          <UrlPrefixWarning urlType="general" />
          <Input
            name="externalNftUrl"
            value={props.token.externalNftUrl}
            onChange={e =>
              props.onChange(props.index, 'externalNftUrl', e.target.value)
            }
            placeholder={'External Url must start with ' + urlPrefix['general']}
            size="sm"
          />
        </FormControl>
      </Box>
    </>
  );
};

export const SocialMediaForm = props => {
  return (
    <>
      {props.socialMediaFields.map((field, socialIndex) => (
        <Box key={socialIndex} style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">{field}</FormLabel>
            <UrlPrefixWarning urlType={field} />
            <Input
              name={field}
              value={props.token[field]}
              onChange={e => props.onChange(props.index, field, e.target.value)}
              placeholder={field + ' url must start with ' + urlPrefix[field]}
              size="sm"
            />
          </FormControl>
        </Box>
      ))}
    </>
  );
};

export const SocialMediaFormSingleToken = props => {
  return (
    <>
      {socialMediaFields.map((field, socialIndex) => (
        <Box key={socialIndex} style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">{field}</FormLabel>
            <UrlPrefixWarning urlType={field} />
            <Input
              name={field}
              value={props.meta[field]}
              onChange={e => props.onChange(field, e.target.value)}
              placeholder={field + ' url must start with ' + urlPrefix[field]}
              size="sm"
            />
          </FormControl>
        </Box>
      ))}
    </>
  );
};

export const RenderShowGameFeatureButton = props => {
  return (
    <Button
      onClick={props.onChange}
      colorScheme="green"
      size="lg"
      variant="ghost"
    >
      Show list of game features
    </Button>
  );
};
