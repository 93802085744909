import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import UpdateTokenInGameBase from '../UpdateTokenInGameBase';

function UpdateTokensInGame({
  specificGameTokenId = 0,
  useCase = '',
  tokenContract = '',
  tokenId = 0,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} colorScheme="green" variant="ghost">
        Update Token(s) in Game
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UpdateTokenInGameBase
              specificGameTokenId={specificGameTokenId}
              useCase={useCase}
              tokenContract={tokenContract}
              tokenId={tokenId}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateTokensInGame;
