import { ethers } from 'ethers';
import ITokenURI from '../../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';

const getErc1155TokenBalance = async (
  provider,
  tokenId,
  tokenAddr,
  tokenOwner
) => {

  let tokenContract = new ethers.Contract(tokenAddr, ITokenURI.abi, provider);

  let tokenCopies = 0;
  try {
    const _tokenCopies = await tokenContract.balanceOf(tokenOwner, tokenId);
    tokenCopies = Number(_tokenCopies.toString())
  } catch (error) {
    console.error('Failed to retrieve token copies');
  }

  return tokenCopies;
};

export default getErc1155TokenBalance;
