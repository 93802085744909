import React from 'react';
import SearchById from './SearchById';

const SearchMarketplaceItem = () => {
  return (
    <SearchById
      useCase="marketplace"
      _placeholder="Marketplace Item Id"
      label="Search Marketplace"
    />
  );
};

export default SearchMarketplaceItem;
