import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Text,
  Input,
  HStack,
  Alert,
  Link,
  InputGroup,
  Icon,
  InputLeftElement,
  InputRightAddon,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { MdFindInPage } from 'react-icons/md';
import { RiArrowGoBackFill } from 'react-icons/ri';

const SearchById = ({useCase, _placeholder, label}) => {
  const [Id, setId] = useState(null);
  const handleId = event => setId(event.target.value);

  return (
    <>
      <InputGroup size="sm">
        <InputLeftElement children={<SearchIcon color="green.300" />} />
        <Input
          value={Id}
          onChange={handleId}
          placeholder={_placeholder}
          size="sm"
        />

        <InputRightAddon children={label} color="green.300" />
      </InputGroup>

      {Id > 0 && (
        <Alert status="success" variant="solid">
          <Link as={ReactLink} to={`/${useCase}/${Id}`}>
            <HStack spacing={[8, 40, 80]}>
              <Icon as={MdFindInPage} />
              <HStack>
              <Text>Id: {Id}</Text>
              <Text>View</Text>
              </HStack>
              
              <Icon as={RiArrowGoBackFill} />
            </HStack>
          </Link>
        </Alert>
      )}
    </>
  );
};

export default SearchById;
