import React from 'react';
import SearchByAddress from './SearchByAddress';

const SearchArtist = () => {
  return (
    <SearchByAddress
      useCase="artists"
      _placeholder="Artist address"
      label="Search artist"
    />
  );
};

export default SearchArtist;
