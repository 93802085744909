import ListIntegrateTokensBase from '../../../components/actions/NFTs/ListIntegrateTokensBase';
const ListErc1155 = () => {
  return (
    <>
      <ListIntegrateTokensBase
        tokenType="Erc1155"
        isSingleItem={false}
        specificGameTokenId={0}
        label='List '
      />
    </>
  );
};

export default ListErc1155;
