import { VStack, Box, Text } from '@chakra-ui/react';
import { HomeIcon } from '../../views/NFTs/elements/MiscDisplays';
import MintTokensBase from './mintTokensBase';

const MintTokens = ({ tokenType }) => {
  const RenderTitle = () => {
    const _title = tokenType === 'Erc721' ? 'Erc721' : 'Erc1155';
    return (
      <Box>
        <Text fontSize={36} color="teal.500" style={{ fontFamily: 'Lobster' }}>
          Mint NFT {_title} and/or sell / auction
        </Text>
      </Box>
    );
  };
  return (
    <VStack>
      <HomeIcon />
      <RenderTitle tokenType={tokenType} />
      <MintTokensBase tokenType={tokenType} specificGameTokenId={0} />
    </VStack>
  );
};

export default MintTokens;
