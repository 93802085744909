import React from 'react';
import {
  Box,
  VStack,
  Container,
  WrapItem,
  Wrap,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { TextOnWoodFrame2 } from '../../components/views/NFTs/elements/TextOnImages';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import MarketplaceSection from '../../components/views/faqs/MarketplaceSection';
import AuctionHouseSection from '../../components/views/faqs/AuctionHouseSection';
import ShowcaseGameSection from '../../components/views/faqs/ShowcaseGameSection';
import GameIntegrationSection from '../../components/views/faqs/GameIntegrationSection';
import MagicFlowersSection from '../../components/views/faqs/MagicFlowersSection';
import FeatureItemSection from '../../components/views/faqs/FeatureItemSection';
import ArtistPageSection from '../../components/views/faqs/ArtistPageSection';
import SellerPageSection from '../../components/views/faqs/SellerPageSection';

function FAQ() {
  return (
    <Box>
      <Container>
        <VStack>
          <HomeIcon />
          <Box w="385px" textAlign="center">
            <TextOnWoodFrame2 t="FAQ" />
          </Box>
        </VStack>
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Wrap>
              <WrapItem>
                <Tab>Marketplace</Tab>
              </WrapItem>
              <WrapItem>
                <Tab>Auction House</Tab>
              </WrapItem>
              <WrapItem>
                <Tab>Game Integration</Tab>
              </WrapItem>
              <WrapItem>
                <Tab>Showcase Your Game</Tab>
              </WrapItem>

              {/* <WrapItem>
                <Tab>Magic Flowers</Tab>
              </WrapItem> */}

              <WrapItem>
                <Tab>Featuring Items</Tab>
              </WrapItem>
              <WrapItem>
                <Tab>Artist Page</Tab>
              </WrapItem>
              <WrapItem>
                <Tab>Seller Shop</Tab>
              </WrapItem>
            </Wrap>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MarketplaceSection />
            </TabPanel>
            <TabPanel>
              <AuctionHouseSection />
            </TabPanel>
            <TabPanel>
              <GameIntegrationSection />
            </TabPanel>
            <TabPanel>
              <ShowcaseGameSection />
            </TabPanel>
            {/* <TabPanel>
              <MagicFlowersSection />
            </TabPanel> */}
            <TabPanel>
              <FeatureItemSection />
            </TabPanel>
            <TabPanel>
              <ArtistPageSection />
            </TabPanel>
            <TabPanel>
              <SellerPageSection />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default FAQ;
