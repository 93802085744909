import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link, VStack, Box } from '@chakra-ui/react';
import getAllTokensOwnedBy from './elements/getAllTokensOwnedBy';

function AllTokensOwnedBy({ provider, addr }) {
  const [allTokensOwned, setAllTokensOwned] = useState([]);
  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const allTokens = await getAllTokensOwnedBy(provider, addr);
    setAllTokensOwned(allTokens);
  };

  const RenderTokenImage = ({ token }) => {
    return (
      <Box display="flex" alignItems="baseline">
        <img
          src={token.metadata.image}
          alt={token.metadata.image}
          style={{ width: '300px' }}
        />
      </Box>
    );
  };

  const ShowAllTokens = () => {
    return (
      <VStack spacing={3}>
        {allTokensOwned.length &&
          allTokensOwned.map((t, key) => (
            <Box
              key={key}
              maxW="sm"
              textAlign="center"
              style={{
                display: 'grid',
                minHeight: '200px',
                border: '8px solid',
                padding: '1rem',
                borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
                marginTop: '20px',
              }}
            >
              <VStack>
                <Link
                  as={ReactLink}
                  to={`/tokens/tokensbyuser/${t.tokenContractAddress}/${t.tokenId}/${addr}`}
                >
                  <Box>
                    <RenderTokenImage token={t} />
                  </Box>
                </Link>

                <Box style={{ marginBottom: '20px' }}>{t.metadata.name}</Box>
                {/* <HStack>
                  <SellAuctionNFT
                    tokenContract={t.tokenContractAddress}
                    tokenId={t.tokenId}
                    tokenType={t.tokenType}
                    saleType="Marketplace"
                    player={props.addr}
                  />
                  <SellAuctionNFT
                    tokenContract={t.tokenContractAddress}
                    tokenId={t.tokenId}
                    tokenType={t.tokenType}
                    saleType="Auction"
                    player={props.addr}
                  />
                </HStack> */}
              </VStack>
            </Box>
          ))}
      </VStack>
    );
  };

  return (
    <VStack>
      <ShowAllTokens />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllTokensOwnedBy;
