import SaleInValley from '../../../components/views/NFTs/elements/singleValleyItem/SaleInValley';
const MarketplaceItemErc721Page = () => {
  return (
    <>
      <SaleInValley saleType="Marketplace" tokenType="Erc721" />
    </>
  );
};

export default MarketplaceItemErc721Page;
