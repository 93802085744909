import { Box, Input, FormControl, FormLabel } from '@chakra-ui/react';
import {
  UrlPrefixWarning,
  urlPrefix,
} from '../views/NFTs/elements/FormElements';
const SocialMediaForm = ({ socialMediaFields, meta, handleMeta }) => {
  return (
    <>
      {socialMediaFields.map((field, socialIndex) => (
        <Box key={socialIndex} style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">{field}</FormLabel>
            <UrlPrefixWarning urlType={field} />
            <Input
              name={field}
              value={meta[field]}
              onChange={e => handleMeta(field, e.target.value)}
              placeholder={field + ' url must start with ' + urlPrefix[field]}
              size="sm"
            />
          </FormControl>
        </Box>
      ))}
    </>
  );
};

export default SocialMediaForm;

// const SocialMediaForm = () => {
//     return(
//         {
//             socialMediaFields.map((field, socialIndex) => (
//               <Box key={socialIndex} style={{ marginBottom: '20px' }}>
//                 <FormControl>
//                   <FormLabel as="legend">{field}</FormLabel>
//                   <UrlPrefixWarning urlType={field} />
//                   <Input
//                     name={field}
//                     value={meta[field]}
//                     onChange={e => handleMeta(field, e.target.value)}
//                     placeholder={
//                       field + ' url must start with ' + urlPrefix[field]
//                     }
//                     size="sm"
//                   />
//                 </FormControl>
//               </Box>
//             ))}
//     )
// }
