import { ReactP5Wrapper } from '@p5-wrapper/react';
import MagicPlant3 from '../../components/sketches/games/magicFlowersSketches/plants/MagicPlant3';
function Temp() {
  const plantFeatures = {
    plantSeed: Math.floor(Math.random() * 100000) + 1,
    plantType: Math.floor(Math.random() * 2) + 1,
    removeDefaultFairy: false,
  };

  const RenderSketch = () => {
    return (
      <ReactP5Wrapper
        sketch={MagicPlant3}
    
      />
    );
  };

  return (
    <>
      <RenderSketch />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default Temp;
