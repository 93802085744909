import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
  Text,
  Stack,
  HStack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import SearchMarketplaceItem from './SearchMarkeplacItem';
import SearchAuctionItem from './SearchAuctionItem';
import SearchCollection from './SearchCollection';
import SearchArtist from './SearchArtist';
import SearchSellerShop from './SearchSellerShop';
import SearchMagicNFT from './SearchMagicNFT';

export default function Search() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState('Marketplace');

  const RenderRadio = () => {
    return (
      <RadioGroup onChange={setValue} value={value} colorScheme="green">
        <Stack direction="row">
          <Radio value="Marketplace">Marketplace</Radio>
          <Radio value="Auction">Auction House</Radio>
          <Radio value="Artist">Artist</Radio>
          <Radio value="Seller">Seller Shop</Radio>
          <Radio value="Collection">Collection</Radio>
          <Radio value="MagicNFT">Magic NFT</Radio>
        </Stack>
      </RadioGroup>
    );
  };

  const RenderSearch = () => {
    if (value === 'Marketplace') {
      return <SearchMarketplaceItem />;
    } else if (value === 'Auction') {
      return <SearchAuctionItem />;
    } else if (value === 'Artist') {
      return <SearchArtist />;
    } else if (value === 'Seller') {
      return <SearchSellerShop />;
    } else if (value === 'Collection') {
      return <SearchCollection />;
    } else if (value === 'MagicNFT') {
      return <SearchMagicNFT />;
    } else {
      return <SearchMarketplaceItem />;
    }
  };

  return (
    <>
      <Button
        colorScheme="green"
        onClick={onOpen}
        rightIcon={<SearchIcon />}
        variant="ghost"
      ></Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <HStack>
              <SearchIcon color="green.300" />
              <Text
                color="green.300"
                fontSize={[24, 28, 36]}
                style={{ fontFamily: 'Lobster' }}
              >
                Search
              </Text>{' '}
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <RenderRadio />
            <RenderSearch />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
