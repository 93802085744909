import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
} from '@chakra-ui/react';
import { parse, format } from '../utils/helpers';

const AuctionValuesForm = ({ index, auctionValues, setAuctionValues }) => {
  const handleAuctionValues = (index, field, value) => {
    const updatedAuctionValues = [...auctionValues];
    updatedAuctionValues[index][field] = value;
    setAuctionValues(updatedAuctionValues);
    console.log('updatedAuctionValues:', updatedAuctionValues);
  };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">Starting Price: (in Matic)</FormLabel>

            <NumberInput
              min={0}
              name="startingPrice"
              value={auctionValues[index].startingPrice}
              precision={2}
              onChange={e => handleAuctionValues(index, 'startingPrice', e)}
              placeholder="NFT starting price"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>

        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">Minimum Increment: (in Matic)</FormLabel>

            <NumberInput
              min={0}
              name="minimumIncrement"
              value={auctionValues[index].minimumIncrement}
              precision={2}
              onChange={e => handleAuctionValues(index, 'minimumIncrement', e)}
              placeholder="NFT auction minimum increment for each bid"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>

        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">Bid Waiting Time:</FormLabel>

            <Stack>
              <InputGroup size="sm">
                <NumberInput
                  name="days"
                  onChange={e => handleAuctionValues(index, 'days', e)}
                  value={auctionValues[index].days}
                  maxW={24}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon children="Days" />
              </InputGroup>

              <InputGroup size="sm">
                <NumberInput
                  name="hours"
                  onChange={e => handleAuctionValues(index, 'hours', e)}
                  value={auctionValues[index].hours}
                  maxW={24}
                  min={0}
                  max={60}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon children="Hours" />
              </InputGroup>

              <InputGroup size="sm">
                <NumberInput
                  name="minutes"
                  onChange={e => handleAuctionValues(index, 'minutes', e)}
                  value={auctionValues[index].minutes}
                  maxW={24}
                  min={0}
                  max={60}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon children="Minutes" />
              </InputGroup>

              <InputGroup size="sm">
                <NumberInput
                  name="seconds"
                  onChange={e => handleAuctionValues(index, 'seconds', e)}
                  value={auctionValues[index].seconds}
                  maxW={24}
                  min={0}
                  max={60}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon children="Seconds" />
              </InputGroup>
            </Stack>
          </FormControl>
        </Box>

        <Box style={{ marginBottom: '20px' }}>
          <FormControl>
            <FormLabel as="legend">Bidding Bonus Percentage:</FormLabel>
            <NumberInput
              name="bonusPercent"
              onChange={e =>
                handleAuctionValues(index, 'bonusPercent', parse(e))
              }
              value={format(auctionValues[index].bonusPercent)}
              precision={2}
              step={1}
              max={100}
              min={0}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default AuctionValuesForm;
