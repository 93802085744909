import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  AccordionPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Text,
} from '@chakra-ui/react';

export const ShowcaseMyGame = () => {
  return (
    <AccordionPanel pb={4}>
      If you're eager to showcase your game, follow these straightforward steps:
      <OrderedList>
        <ListItem>
          Head over to the{' '}
          <Link as={ReactLink} to="/gamelist">
            game showcase page
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Simply click on the 'Connect Wallet'
          button located in the top right corner, then choose your preferred
          wallet from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We utilize WalletConnect, a trusted
          protocol, for secure connections.
        </ListItem>
        <ListItem>
          Click on the 'Add Game' button, and a modal will appear with a form
          for adding your game.
        </ListItem>
        <ListItem>
          Specify the Game Features Allowed for External Integration. These are
          features associated with NFTs that can be created or integrated by
          external parties, such as artists or players. Separate features with
          commas, for example: Butterfly, Background, Evil.
        </ListItem>
        <ListItem>
          Specify the Game Features Reserved for Internal Integration Only.
          These are features associated with NFTs reserved exclusively for game
          owners or administrators. Separate features with commas, for example:
          Butterfly, Background, Evil.
        </ListItem>
        <ListItem>
          Indicate whether your game has branches. Branches allow for
          hierarchical arrangement of NFTs. An NFT can only be associated with
          one branch. For example, a game might have branches like 'Heaven' or
          'World'. Your game can have as many branches as you wish, or none at
          all.
        </ListItem>
        <ListItem>
          Provide a URL for your Game Poster. While optional, a captivating game
          poster can greatly enhance user interest.
        </ListItem>
        <ListItem>
          Share a URL for your Game Introduction Video. Though optional, an
          engaging introduction video can significantly boost user interest.
        </ListItem>
        <ListItem>
          Complete the form below with your game's name, description, and tags.
          The game name is mandatory.
        </ListItem>
        <ListItem>
          Provide an External URL for your Game. This is required as your game
          must be accessible online.
        </ListItem>
        <ListItem>
          Offer an NFT Trial URL for your Game. This allows users to experience
          an NFT in your game. While optional, enabling users to try NFTs can
          substantially increase interest.
        </ListItem>
        <ListItem>
          Optionally, include URLs for Social Media platforms like X, Facebook,
          and YouTube.
        </ListItem>
        <ListItem>
          Finally, click the 'Add Game' button at the bottom of the form to
          complete the listing.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the sale. Simply follow
          the instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const UpdateGameMeta = () => {
  return (
    <AccordionPanel pb={4}>
      Should you wish to update the metadata for your game, simply adhere to
      these steps:
      <OrderedList>
        <ListItem>
          Navigate to your{' '}
          <Link as={ReactLink} to="/user">
            user page
          </Link>
          .
        </ListItem>
        <ListItem>
          Select 'Dashboard' and opt for 'All Games I Owned' from the dropdown
          menu.
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          in the top right corner, then choose your preferred wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed. We employ WalletConnect, a trusted protocol, for secure
          connections.
        </ListItem>
        <ListItem>
          All the games you have added to the showcase list will be listed.
          Click on the 'Game Owner Actions' button, then select 'Update Game'. A
          'Update Game' button will appear. Click on it to access the updating
          form.
        </ListItem>
        <ListItem>
          Specify any updates you require, such as the 'Game Name'. Each item
          you choose to update will display the corresponding form. Once you've
          completed filling out all the new metadata, click the 'Update Game'
          button at the bottom of the form to finalize the update process.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const ExternalIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        External Integration refers to the incorporation of an NFT into a game
        by third parties, such as artists or players. Permitting external
        integration of NFTs in your game can significantly amplify the interest
        of both current and prospective players, which is beneficial for your
        game's popularity in both the short and long term.
      </Text>
    </AccordionPanel>
  );
};

export const InternalIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        Internal Integration refers to the exclusive incorporation of an NFT
        into a game by the game owner or game administrators. Third parties,
        such as artists or players, cannot directly integrate an NFT into the
        features reserved for internal integration. However, they can acquire or
        purchase tokens integrated with features reserved for internal
        integration from the game owners or game administrators.
      </Text>
    </AccordionPanel>
  );
};

export const ApproveGameAdmin = () => {
  return (
    <AccordionPanel pb={4}>
      To approve or reject an account for your game as a game administrator,
      follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to your{' '}
          <Link as={ReactLink} to="/user">
            user page
          </Link>
          .
        </ListItem>
        <ListItem>
          Select 'Dashboard' and choose 'All Games I Owned' from the dropdown
          menu.
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          in the top right corner, then choose your preferred wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed. We utilize WalletConnect, a trusted protocol, for secure
          connections.
        </ListItem>
        <ListItem>
          All the games you have added to the showcase list will be listed.
          Click on the 'Game Owner Actions' button, then select 'Update Game
          Admin'. An 'Update Game Admin' button will appear. Click on it to
          access the updating form.
        </ListItem>
        <ListItem>
          Specify whether you want to approve or reject a game administrator.
          The circle before your selection will turn green.
        </ListItem>
        <ListItem>
          Provide the account address that is to be approved or rejected,
          depending on your choice in the previous step. Click the 'Update Game
          Admin' button at the bottom of the form to finalize the update
          process.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const CancelMyGame = () => {
  return (
    <AccordionPanel pb={4}>
      To remove your game from the showcase list, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to your{' '}
          <Link as={ReactLink} to="/user">
            user page
          </Link>
          .
        </ListItem>
        <ListItem>
          Select 'Dashboard' and choose 'All Games I Owned' from the dropdown
          menu.
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          in the top right corner, then choose your preferred wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed. We utilize WalletConnect, a trusted protocol, for secure
          connections.
        </ListItem>
        <ListItem>
          All the games you have added to the showcase list will be listed.
          Click on the 'Game Owner Actions' button, then select 'Cancel Game'. A
          'Cancel Game' button will appear. Click on it to remove your game from
          the showcase list.
        </ListItem>
        <ListItem>
          Your wallet will guide you through signing or confirming the
          transaction. Just follow the instructions provided. There is no charge
          for this action, but you may be required to cover the gas fee native
          to the Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const NFTtrialPage = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        An NFT trial page serves as a simplified version of your game, allowing
        users to experiment with an NFT within it. This enables users to gain a
        preview of how the NFT looks or feels within the game environment.
        Additionally, it aids artists in refining their NFTs for integration
        with external features within your game. When a user navigates from
        Magic NFT Valley to your game trial page, the token contract address and
        token ID are included as parameters in the link they click. This
        provides you with access to the token contract address and ID, which can
        be utilized to retrieve the image or other content associated with the
        NFT and display it within your game. While an NFT trial page can be as
        simple as desired, a well-designed one not only streamlines the NFT
        integration process but also enhances people's interest in your game.
      </Text>
    </AccordionPanel>
  );
};
