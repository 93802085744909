import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  BatchListForAuction,
  BiddingBonus,
  ListForAuction,
} from './AuctionHouseFAQ';
import { ImageUrl } from './MarketplaceFAQ';
const AuctionHouseSection = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              1. How to list an NFT for auction?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ListForAuction />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              2. How to batch list NFTs for auction?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <BatchListForAuction />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              3. What is Bidding Bonus?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <BiddingBonus />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              4. Why do I need to provide a URL for the NFT image during the
              minting process in Dream Minting Studio?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <ImageUrl />
      </AccordionItem>
    </Accordion>
  );
};

export default AuctionHouseSection;
