import { useState } from 'react';
import {
  VStack,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import Helper from '../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import 'normalize.css';
import { REACT_APP_HELPER_ADDRESS } from '../../../Constants/ContractAddress';

function JoinMembership({ membershipTier = 0 }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();

  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const create = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const helper = new ethers.Contract(
        REACT_APP_HELPER_ADDRESS,
        Helper.abi,
        signer
      );

      let transactionList;

      const _tier = membershipTier > 0 ? membershipTier : Number(value);

      const membershipFee = await helper.getMembershipFee(_tier);

      transactionList = await helper.extendMemebership(_tier, {
        value: membershipFee,
      });

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (membershipTier > 0) {
      if (loading === true) {
        return (
          <Button isLoading loadingText="Submitting" colorScheme="yellow">
            Join
          </Button>
        );
      } else {
        return (
          <Button onClick={create} colorScheme="yellow">
            Join
          </Button>
        );
      }
    } else {
      if (loading === true) {
        return (
          <Button
            isLoading
            loadingText="Submitting"
            colorScheme="green"
            size="lg"
            variant="outline"
          >
            Join
          </Button>
        );
      } else {
        return (
          <Button
            onClick={create}
            colorScheme="green"
            size="lg"
            variant="outline"
          >
            Join
          </Button>
        );
      }
    }
  };
  function JoinButton() {
    return (
      <>
        <Box
          as="button"
          p={4}
          color="black"
          fontWeight="bold"
          borderRadius="lg"
          bgGradient="linear-gradient( 109.6deg,  rgba(247,253,166,1) 11.2%, rgba(128,255,221,1) 57.8%, rgba(255,128,249,1) 85.9% )"
          _hover={{
            bgGradient: 'linear(to-r, teal.500, green.500)',
          }}
          onClick={onOpen}
        >
          <Text as="i">Join Now</Text>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Be a member now</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Please choose your membership tier:</FormLabel>
                <RadioGroup
                  onChange={setValue}
                  value={value}
                  colorScheme="green"
                >
                  <Stack direction="column">
                    <Radio value="1">One-Year Membership</Radio>
                    <Radio value="2">Two-Year Membership</Radio>
                    <Radio value="3">Three-Year Membership</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <RenderButton />
              <Button variant="ghost" ml={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      {membershipTier === 0 && <JoinButton />}

      {membershipTier > 0 && <RenderButton />}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default JoinMembership;
