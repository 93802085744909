import Cat from '../../resources/images/piggy/pigs/9.png';
import CatWithDiamonds from '../../resources/images/piggy/pigs/8.png';
import Gem0 from '../../resources/images/piggy/diamonds/gem-round0.png';
import Gem1 from '../../resources/images/piggy/diamonds/gem-round1.png';
import Gem2 from '../../resources/images/piggy/diamonds/gem-round2.png';
import Gem3 from '../../resources/images/piggy/diamonds/gem-round3.png';
import Gem4 from '../../resources/images/piggy/diamonds/gem-round4.png';
import Gem5 from '../../resources/images/piggy/diamonds/gem-round5.png';
import Gem6 from '../../resources/images/piggy/diamonds/gem-round6.png';
import Gem7 from '../../resources/images/piggy/diamonds/gem-round7.png';
import Gem8 from '../../resources/images/piggy/diamonds/gem-round8.png';
import Gem9 from '../../resources/images/piggy/diamonds/gem-round9.png';
import Gem10 from '../../resources/images/piggy/diamonds/gem-round10.png';
import Gem11 from '../../resources/images/piggy/diamonds/gem-round11.png';

import Vehicle from './Vehicle';

function SketchPiggy(p5) {
  let maxLevel = 10;
  let tree = [];
  let leaves = [];
  let count = 0;
  let gems = [];
  let gem0, gem1, gem2, gem3, gem4, gem5, gem6, gem7, gem8, gem9, gem10, gem11;

  let cat, catWithDiamonds;

  let cx = -120;
  let fillPoint = 2;

  // text
  let font;
  let vehicles = [];

  // background stars
  let stars = [];

  p5.preload = () => {
    gem0 = p5.loadImage(Gem0);
    gem1 = p5.loadImage(Gem1);
    gem2 = p5.loadImage(Gem2);
    gem3 = p5.loadImage(Gem3);
    gem4 = p5.loadImage(Gem4);
    gem5 = p5.loadImage(Gem5);
    gem6 = p5.loadImage(Gem6);
    gem7 = p5.loadImage(Gem7);
    gem8 = p5.loadImage(Gem8);
    gem9 = p5.loadImage(Gem9);
    gem10 = p5.loadImage(Gem10);
    gem11 = p5.loadImage(Gem11);
    gems = [
      gem0,
      gem1,
      gem2,
      gem3,
      gem4,
      gem5,
      gem6,
      gem7,
      gem8,
      gem9,
      gem10,
      gem11,
    ];

    cat = p5.loadImage(Cat);
    catWithDiamonds = p5.loadImage(CatWithDiamonds);

    font = p5.loadFont('Lobster-Regular.ttf');
  };
  p5.setup = () => {
    p5.createCanvas(window.innerWidth, window.innerHeight);
    // background stars
    p5.push();
    prepareStars(p5, 58, stars);
    p5.pop();

    p5.colorMode(p5.HSB, 255);
    let a = p5.createVector(p5.width / 2, p5.height);
    let b = p5.createVector(p5.width / 2, p5.height - p5.height / 3.18);
    let root = new Branch(a, b, 0);

    tree[0] = root;

    //text
    let points = font.textToPoints(
      'Game NFTs',
      p5.width / 2.58,
      p5.height / 11.8,
      47,
      {
        sampleFactor: '0.25',
      }
    );

    for (let i = 0; i < points.length; i++) {
      let pt = points[i];
      let vehicle = new Vehicle(p5, pt.x, pt.y);
      vehicles.push(vehicle);
    }
  };

  p5.draw = () => {
    p5.background(0);
    // stars
    p5.push();
    displayStars(stars);
    p5.pop();

    // tree

    if (count < 10) {
      for (let i = tree.length - 1; i >= 0; i--) {
        if (!tree[i].finished) {
          tree.push(tree[i].branchA());
          tree.push(tree[i].branchB());
        }
        tree[i].finished = true;
      }
      count++;

      if (count === 9) {
        for (let j = 0; j < tree.length; j++) {
          if (!tree[j].finished) {
            let leaf = tree[j].end.copy();
            leaves.push(leaf);
          }
        }
      }
    }

    for (let i = 0; i < tree.length; i++) {
      tree[i].show();
    }

    for (let i = 0; i < leaves.length; i++) {
      p5.fill(255, 0, 100, 100);
      p5.noStroke();
      //ellipse(leaves[i].x, leaves[i].y, 8, 8);
      p5.image(
        gems[p5.int(p5.random(gems.length))],
        leaves[i].x,
        leaves[i].y,
        16,
        16
      );
      leaves[i].y += p5.random(0, 2.88);
    }

    // cat
    cat.resize(360, 0);
    catWithDiamonds.resize(360, 0);

    if (count >= 2 && cx < p5.width / fillPoint) {
      p5.image(cat, cx, p5.height / 1.58);
    } else if (count >= 9 && cx >= p5.width / fillPoint) {
      p5.image(catWithDiamonds, cx, p5.height / 1.58);
    }
    cx += 3.88;
    if (cx > p5.width + 588) {
      // reset variables
      cx = -120;
      tree = [];
      leaves = [];
      count = 0;
      let a = p5.createVector(p5.width / 2, p5.height);
      let b = p5.createVector(p5.width / 2, p5.height - p5.height / 3.18);
      let root = new Branch(a, b, 0);
      tree[0] = root;
    }

    // text
    for (let i = 0; i < vehicles.length; i++) {
      let v = vehicles[i];
      v.behaviors();
      v.update();
      v.show();
    }
  };

  function Branch(begin, end, level) {
    this.begin = begin;
    this.end = end;
    this.level = level;
    this.finished = false;

    this.show = function () {
      // stroke(255);
      p5.stroke(0, 0, 150 * p5.map(this.level, 0, maxLevel, 1, 1.5));
      p5.strokeWeight(maxLevel - this.level + 1);
      p5.line(this.begin.x, this.begin.y, this.end.x, this.end.y);
    };

    this.branchA = function () {
      var dir = p5.constructor.Vector.sub(this.end, this.begin);
      dir.rotate(p5.PI / 6);
      dir.mult(0.67);
      let newEnd = p5.constructor.Vector.add(this.end, dir);
      let b = new Branch(this.end, newEnd, this.level + 1);
      return b;
    };
    this.branchB = function () {
      var dir = p5.constructor.Vector.sub(this.end, this.begin);
      dir.rotate(-p5.PI / 4);
      dir.mult(0.67);
      let newEnd = p5.constructor.Vector.add(this.end, dir);
      let b = new Branch(this.end, newEnd, this.level + 1);
      return b;
    };
  }

  function prepareStars(p5, rStars, stars) {
    p5.push();

    // fill stars array
    for (let i = 0; i < rStars; i++) {
      stars.push(new Star(p5));
    }

    p5.pop();
  }

  function displayStars(stars) {
    for (let i = 0; i < stars.length; i++) {
      stars[i].display();
      stars[i].twinkle();
    }
  }

  function Star(p5) {
    this.x = p5.random(-30, p5.width);
    this.y = p5.random(0, p5.height / 1.88);
    this.r = p5.random(2, 5);

    this.display = function () {
      p5.push();
      p5.stroke(255); //33, 121, 145
      p5.fill(247, 255, 196); //247, 255, 196
      this.rc = p5.constrain(this.r, 0, 9);
      p5.ellipse(this.x, this.y, this.rc, this.rc);
      p5.pop();
    };

    this.twinkle = function () {
      if (this.r < 3) {
        this.r += p5.random(-0.5, 1.5);
      } else if (this.r >= 3 && this.r < 6) {
        this.r += p5.random(-1, 1);
      } else if (this.r >= 6 && this.r <= 9) {
        this.r += p5.random(-1.5, 0.5);
      }
    };
  }
}

export default SketchPiggy;
