import {
  Box,
  Button,
  Text,
  Stack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  CardFooter,
  VStack,
  Wrap,
  WrapItem,
  StackDivider,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import '../../../styles/Frame.css';
import { useState } from 'react';
import { ethers } from 'ethers';
import Streamline from '../../../contracts/APIs/StreamlineInterface.sol/StreamlineInterface.json';
import GameTokenGallery from '../../../contracts/APIs/GameTokenGalleryInterface.sol/GameTokenGalleryInterface.json';
import {
  extractValuesByKey,
  filterArrayByBool,
  getNames,
} from '../../utils/helpers';
import PriceForm from '../../formComponents/PriceForm';
import AuctionValuesForm from '../../formComponents/AuctionValuesForm';
import ChooseFeatureForm from '../../formComponents/ChooseFeatureForm';
import RoyaltyForm from '../../formComponents/RoyaltyForm';
import AmountsErc1155Form from '../../formComponents/AmountsErc1155Form';
import SellingOptionForm from '../../formComponents/SellingOptionForm';
import BatchOptionsForm from '../../formComponents/BatchOptionsForm';
import GameTokenIdForm from '../../formComponents/GameTokenIdForm';
import AddModeForm from '../../formComponents/AddModeForm';
import TokenIdForm from '../../formComponents/TokenIdForm';
import ChooseContractForm from '../../formComponents/ChooseContractForm';
import SetContractAddrForm from '../../formComponents/SetContractAddrForm';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import ComplimentaryTokensForm from '../../formComponents/ComplimentaryTokensForm';
import TokenMetaUrlForm from '../../formComponents/TokenMetaUrlForm';
import TokenCategoryForm from '../../formComponents/TokenCategoryForm';
import CollectionForm from '../../formComponents/CollectionForm';
import { REACT_APP_GAME_TOKEN_GALLERY_ADDRESS, REACT_APP_MAGIC_NFT_ERC1155_ADDRESS, REACT_APP_MAGIC_NFT_ERC721_ADDRESS, REACT_APP_STREAMLINE_ADDRESS } from '../../Constants/ContractAddress';
const zeroAddress = ethers.constants.AddressZero;
const MintTokensBase = ({
  tokenType,
  specificGameTokenId,
  noMinting = false,
  tokenId = 0,
  tokenContract = zeroAddress,
  useCase = '',
  label = 'I would like to mint',
  onlyIntegration = false,
  isMagicflowers = false, // whether the token to be sold is a Magic Flowers NFT
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  // no minting starts
  const initialContractAddr =
    tokenType == 'Erc721'
      ? REACT_APP_MAGIC_NFT_ERC721_ADDRESS
      : REACT_APP_MAGIC_NFT_ERC1155_ADDRESS;
  const [singleContractAddr, setSingleContractAddr] =
    useState(initialContractAddr);
  const [tokenIds, setTokenIds] = useState([0]);
  // no minting ends
  const [batch, setBatch] = useState('1');
  const [gameTokenId, setGameTokenId] = useState(specificGameTokenId);
  const [metaUrl, setMetaUrl] = useState(['']);
  const [category, setCategory] = useState(['']);
  const [tokenAttributes, setTokenAttributes] = useState([
    [
      {
        display_type: 'text',
        trait_type: '',
        value: '',
      },
    ],
  ]);

  const [meta, setMeta] = useState([
    {
      cTokenContracts: [],
      cTokenIds: [],
      isCTokenErc1155s: [],
      cTokenAmounts: [],
    },
  ]);

  const [royalty, setRoyalty] = useState(['0.00']);
  const [price, setPrice] = useState([0]);
  const [mintingAmounts, setMintingAmounts] = useState([0]);
  const [sellingAmounts, setSellingAmounts] = useState([0]);

  const [auctionValues, setAuctionValues] = useState([
    {
      startingPrice: '',
      minimumIncrement: '',
      bonusPercent: 0,
      days: 0,
      hours: 8,
      minutes: 8,
      seconds: 0,
    },
  ]);

  const [gameFeatureAddingMode, setGameFeatureAddingMode] = useState('1');
  const [addCTokensMode, setAddCTokensMode] = useState('1');

  const [forSale, setForSale] = useState('1');
  const [showGameFeatures, setShowGameFeatures] = useState(false);
  const [listOfGameFeatures, setListOfGameFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const [gameBranch, setGameBranch] = useState(['']);
  const [value, setValue] = useState('1');

  // complimentary tokens
  const [newContractAddress, setNewContractAddress] = useState(
    REACT_APP_MAGIC_NFT_ERC721_ADDRESS
  );
  const [newTokenIds, setNewTokenIds] = useState('');
  const [isERC1155s, setIsERc1155s] = useState('1');
  const [amounts, setAmounts] = useState('');

  // collections
  const [collectionMode, setCollectionMode] = useState('1');
  const [newCollectionUrl, setNewCollectionUrl] = useState('');
  const [allCollections, setAllCollections] = useState([]);
  const [collectionIndex, setCollectionIndex] = useState(0);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [isForCreatorCollection, setIsForCreatorCollection] = useState('1');

  const handleGameBranch = (e, idx) => {
    const updatedGameBranch = [...gameBranch];
    updatedGameBranch[idx] = e.target.value;
    // console.log('updatedGameBrach:', updatedGameBranch);
    setGameBranch(updatedGameBranch);
  };

  const addToken = () => {
    setRoyalty([...royalty, ['0.00']]);
    setMintingAmounts([...mintingAmounts], 0);
    setSellingAmounts([...sellingAmounts], 0);
    setPrice([...price], 0);
    setMeta([
      ...meta,
      {
        cTokenContracts: [],
        cTokenIds: [],
        isCTokenErc1155s: [],
        cTokenAmounts: [],
      },
    ]);
    setAuctionValues([
      ...auctionValues,
      {
        startingPrice: 0,
        minimumIncrement: 0,
        bonusPercent: 0,
        days: 0,
        hours: 8,
        minutes: 8,
        seconds: 0,
      },
    ]);
    const _newCheckedItem = checkedItems[0].map(c => false);
    // console.log('_newCheckedItem', _newCheckedItem);
    setCheckedItems([...checkedItems, _newCheckedItem]);

    const _newTokenAttribute = [
      {
        display_type: 'text',
        trait_type: '',
        value: '',
      },
    ];

    setTokenAttributes([...tokenAttributes, _newTokenAttribute]);

    setGameBranch([...gameBranch, '']);
  };

  const removeToken = index => {
    const updatedTokens = [...meta];
    updatedTokens.splice(index, 1);
    setMeta(updatedTokens);
  };

  const singleStringToArray = str => {
    let array = str.split(',').map(item => item.trim());
    return array;
  };

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      let features = [];
      for (let m = 0; m < checkedItems.length; m++) {
        features.push(filterArrayByBool(checkedItems[m], listOfGameFeatures));
      }
      console.log('features:', features);
      let _cids = []; // _tokenUrls

      const _gameTokenId = Number(gameTokenId);

      const _keys = [
        'category',
        'imageUrl',
        'name',
        'description',
        'otherInfo',
        'tags',
        'externalNftUrl',
        'youtube',
        'usePreview',
        'previewUrl',
      ];

      const _cMeta = meta;
      console.log('_cMeta:', _cMeta);

      let hasComplimentaryTokens = [];
      for (let i = 0; i < _cMeta.length; i++) {
        if (_cMeta[i].cTokenContracts.length > 0) {
          hasComplimentaryTokens.push(true);
        } else {
          hasComplimentaryTokens.push(false);
        }
      }

      // console.log('hasComplimentaryTokens:', hasComplimentaryTokens);
      let _cParam = [];

      for (let i = 0; i < _cMeta.length; i++) {
        let _cp;
        if (_cMeta[i].cTokenContracts.length == 0) {
          _cp = {
            complimentaryTokenContracts: [],
            complimentaryTokenIds: [],
            isERC1155s: [],
            amounts: [],
          };
        } else {
          let _cIds = [];
          let _is1155 = [];
          let _cAmounts = [];
          for (let j = 0; j < _cMeta[i].cTokenIds.length; j++) {
            let _c = singleStringToArray(_cMeta[i].cTokenIds[j]);
            console.log('_c:', _c);
            let _id = _c.map(x => Number(x));
            _cIds.push(_id);

            if (_cMeta[i].isCTokenErc1155s[j] == '1') {
              _is1155.push(false);
            } else {
              _is1155.push(true);
            }

            let _cA = singleStringToArray(_cMeta[i].cTokenAmounts[j]);
            let _cAmount = _cA.map(x => Number(x));
            _cAmounts.push(_cAmount);
          }

          _cp = {
            complimentaryTokenContracts: _cMeta[i].cTokenContracts,
            complimentaryTokenIds: _cIds,
            isERC1155s: _is1155,
            amounts: _cAmounts,
          };
        }

        _cParam.push(_cp);
      }

      const addToCollection = collectionMode === '1' ? false : true;
      const isNewCollection = collectionMode === '3' ? true : false;
      const isCreatorCollection = isForCreatorCollection === '1' ? true : false;

      let _collectionParam = {
        newCollectionName,
        newCollectionUrl,
        collectionId: collectionIndex,
        addToCollection,
        isNewCollection,
        isCreatorCollection,
      };

      console.log('_collectionParam:', _collectionParam);

      const _royalty = royalty.map(r => Math.round(Number(r) * 100));
      // console.log('_royalty:', _royalty);

      const _price = price.map(p => ethers.utils.parseEther(p.toString()));
      // console.log('price:', price);
      const streamline = new ethers.Contract(
        REACT_APP_STREAMLINE_ADDRESS,
        Streamline.abi,
        signer
      );

      let gameTokenGallery;

      if (onlyIntegration) {
        gameTokenGallery = new ethers.Contract(
          REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
          GameTokenGallery.abi,
          signer
        );
      }

      const integrateInGame = gameFeatureAddingMode === '1' ? false : true;

      const doesList = forSale === '1' ? true : false;
      const isAuction = forSale === '2' ? true : false;

      let _sellingAmounts = [];

      let _mintingParam = {
        amounts: mintingAmounts.map(m => Number(m)),
        tokenUrls: metaUrl,
        royaltyNumerators: _royalty,
        doesList: false,
        doesAuction: false,
      };

      let featuresForParam = [];
      for (let i = 0; i < features.length; i++) {
        let _featureName = getNames(features[i]);
        // console.log('_featureName:', _featureName);
        featuresForParam.push(_featureName);
      }
      // console.log('featuresForParam:', featuresForParam);

      const isErc1155 = tokenType === 'Erc721' ? false : true;

      const onlyReservedFeatures = value == '1' ? false : true;
      const forFeaturesExternal = value == '2' ? false : true;

      let emptyFeatureArray = [];
      for (let i = 0; i < features.length; i++) {
        emptyFeatureArray.push([]);
      }

      let _integrationParam = {
        gameTokenId: _gameTokenId,
        branches: gameBranch,
        forFeaturesExternal,
        forFeaturesReserved: onlyReservedFeatures,
        featuresExternal: onlyReservedFeatures ? emptyFeatureArray : features,
        featuresReserved: onlyReservedFeatures ? features : emptyFeatureArray,
        isErc1155,
        tokenContract: zeroAddress,
        tokenIds: [],
        integrateInGame,
      };

      // console.log('_integrationParam:', _integrationParam);

      let transactionList;

      let _marketplaceParam = {
        nftContract: zeroAddress,
        tokenIds: [],
        sellingAmounts: [],
        prices: [],
        tokenOwner: zeroAddress,
        categories: category,
        hasComplimentaryTokens: [],
      };
      let _auctionParam = {
        nftContract: zeroAddress,
        tokenIds: [],
        startingPrices: [],
        minimumIncrements: [],
        durations: [],
        bonusPercents: [],
        tokenOwner: zeroAddress,
        categories: category,
        hasComplimentaryTokens: [],
      };

      const _tokenIds =
        useCase === 'TokenByUser' ? [tokenId] : tokenIds.map(t => Number(t));
      const _tokenContract =
        useCase === 'TokenByUser' ? tokenContract : singleContractAddr;

      if (onlyIntegration) {
        _integrationParam.tokenContract = _tokenContract;
        _integrationParam.tokenIds = _tokenIds;

        // console.log('_integrationParam:', _integrationParam);

        transactionList = await gameTokenGallery.integrateTokensInGame(
          _integrationParam
        );
      } else {
        if (tokenType === 'Erc721') {
          // console.log('tokenType:', tokenType);

          if (doesList) {
            // console.log('doesList');

            if (noMinting || isMagicflowers) {
              const _isERC1155 = tokenType === 'Erc721' ? false : true;
              _sellingAmounts = sellingAmounts.map(s => Number(s));
              _marketplaceParam = {
                nftContract: _tokenContract,
                tokenIds: _tokenIds,
                sellingAmounts: _sellingAmounts,
                prices: _price,
                tokenOwner: signer_address,
                categories: category,
                hasComplimentaryTokens,
              };
              transactionList = await streamline.sellTokens(
                _isERC1155,
                _marketplaceParam,
                _cParam,
                _integrationParam,
                _collectionParam
              );
            } else {
              _mintingParam.doesList = true;
              _mintingParam.doesAuction = false;
              _marketplaceParam = {
                nftContract: zeroAddress,
                tokenIds: [],
                sellingAmounts: [],
                prices: _price,
                tokenOwner: signer_address,
                categories: category,
                hasComplimentaryTokens,
              };
              console.log('_mintingParam:', _mintingParam);
              transactionList = await streamline.batchMintTokens(
                false,
                _mintingParam,
                _marketplaceParam,
                _auctionParam,
                _cParam,
                _integrationParam,
                _collectionParam
              );
            }
          } else if (isAuction) {
            _auctionParam = getAuctionParam(
              isAuction,
              signer_address,
              hasComplimentaryTokens
            );
            if (noMinting || isMagicflowers) {
              _auctionParam.nftContract = _tokenContract;
              _auctionParam.tokenIds = _tokenIds;
              // console.log('_auctionParam:', _auctionParam);
              // console.log('_cParam:', _cParam);
              // console.log('_integrationParam:', _integrationParam);

              transactionList = await streamline.createAuctionErc721(
                _auctionParam,
                _cParam,
                _integrationParam,
                _collectionParam
              );
            } else {
              _mintingParam.doesAuction = true;
              _mintingParam.doesList = false;

              transactionList = await streamline.batchMintTokens(
                false,
                _mintingParam,
                _marketplaceParam,
                _auctionParam,
                _cParam,
                _integrationParam,
                _collectionParam
              );
            }
            // console.log('isAuction');
          } else {
            // console.log('else');
            _mintingParam.doesAuction = false;
            _mintingParam.doesList = false;

            transactionList = await streamline.batchMintTokens(
              false,
              _mintingParam,
              _marketplaceParam,
              _auctionParam,
              _cParam,
              _integrationParam,
              _collectionParam
            );
          }
        } else if (tokenType === 'Erc1155') {
          if (doesList) {
            _mintingParam.doesList = true;
          }
          _sellingAmounts = sellingAmounts.map(s => Number(s));
          _marketplaceParam = {
            nftContract: zeroAddress,
            tokenIds: [],
            sellingAmounts: _sellingAmounts,
            prices: _price,
            tokenOwner: signer_address,
            categories: category,
            hasComplimentaryTokens,
          };
          console.log('_marketplaceParam:', _marketplaceParam);
          console.log('_mintingParam:', _mintingParam);
          console.log('_auctionParam:', _auctionParam);
          console.log('_cParam:', _cParam);
          console.log('_integrationParam:', _integrationParam);
          console.log('_collectionParam:', _collectionParam);
          transactionList = await streamline.batchMintTokens(
            true,
            _mintingParam,
            _marketplaceParam,
            _auctionParam,
            _cParam,
            _integrationParam,
            _collectionParam
          );
        }
      }

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAuctionParam = (doesAuction, tokenOwner, hasComplimentaryTokens) => {
    let _auctionParam = {
      nftContract: zeroAddress,
      tokenIds: [],
      startingPrices: [],
      minimumIncrements: [],
      durations: [],
      bonusPercents: [],
      tokenOwner: zeroAddress,
      categories: category,
      hasComplimentaryTokens: [],
    };
    if (doesAuction) {
      const startingPrices = extractValuesByKey(auctionValues, 'startingPrice');
      const _startingPrices = startingPrices.map(sp =>
        ethers.utils.parseEther(sp)
      );
      // console.log('_startingPrices:', _startingPrices);
      const minimumIncrements = extractValuesByKey(
        auctionValues,
        'minimumIncrement'
      );
      const _minimumIncrements = minimumIncrements.map(mi =>
        ethers.utils.parseEther(mi)
      );
      const bonusPercents = extractValuesByKey(auctionValues, 'bonusPercent');
      const _bonusPercents = bonusPercents.map(b => Number(b) * 100);
      // console.log('_minimumIncrements:', _minimumIncrements);
      // console.log('_bonusPercents:', _bonusPercents);
      const days = extractValuesByKey(auctionValues, 'days');
      const hours = extractValuesByKey(auctionValues, 'hours');
      const minutes = extractValuesByKey(auctionValues, 'minutes');
      const seconds = extractValuesByKey(auctionValues, 'seconds');

      let _durations = [],
        duration;
      for (let i = 0; i < auctionValues.length; i++) {
        duration =
          days[i] * 24 * 60 * 60 +
          hours[i] * 60 * 60 +
          minutes[i] * 60 +
          seconds[i];
        _durations.push(duration);
      }

      _auctionParam = {
        nftContract: zeroAddress,
        tokenIds: [],
        startingPrices: _startingPrices,
        minimumIncrements: _minimumIncrements,
        durations: _durations,
        bonusPercents: _bonusPercents,
        tokenOwner,
        categories: category,
        hasComplimentaryTokens,
      };
    }
    return _auctionParam;
  };

  const RenderButtonMinting = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Create
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Create
        </Button>
      );
    }
  };

  return (
    <div>
      <VStack>
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              {useCase.length == 0 && (
                <BatchOptionsForm
                  batch={batch}
                  setBatch={setBatch}
                  label={label}
                />
              )}

              {!onlyIntegration && (
                <SellingOptionForm
                  forSale={forSale}
                  setForSale={setForSale}
                  tokenType={tokenType}
                />
              )}

              {!isMagicflowers && (
                <>
                  <Box style={{ marginBottom: '20px' }}>
                    <AddModeForm
                      addMode={gameFeatureAddingMode}
                      setAddMode={setGameFeatureAddingMode}
                      label="Integrate Game"
                    />
                  </Box>
                </>
              )}

              {gameFeatureAddingMode == '2' && specificGameTokenId === 0 && (
                <Box style={{ marginBottom: '20px' }}>
                  <GameTokenIdForm
                    gameTokenId={gameTokenId}
                    setGameTokenId={setGameTokenId}
                  />
                </Box>
              )}

              {gameFeatureAddingMode == '2' && (
                <Box style={{ marginBottom: '20px' }}>
                  <FormControl isRequired>
                    <FormLabel as="legend">Integrate Game Features:</FormLabel>

                    <RadioGroup
                      onChange={setValue}
                      value={value}
                      colorScheme="green"
                    >
                      <Stack>
                        <Radio value="1">For External Integration</Radio>
                        <Radio value="2">
                          Reserved for Game Owner / Game Admin
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}

              {!onlyIntegration && (
                <Box style={{ marginBottom: '20px' }}>
                  <AddModeForm
                    addMode={addCTokensMode}
                    setAddMode={setAddCTokensMode}
                    label="Add Complimentary Tokens"
                  />
                </Box>
              )}

              {useCase !== 'TokenByUser' && noMinting && (
                <Box>
                  <ChooseContractForm
                    singleContractAddr={singleContractAddr}
                    setSingleContractAddr={setSingleContractAddr}
                  />

                  {singleContractAddr === 'Other Contract Address' && (
                    <SetContractAddrForm
                      singleContractAddr={singleContractAddr}
                      setSingleContractAddr={setSingleContractAddr}
                    />
                  )}
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>
        <Wrap>
          {meta.map((token, index) => (
            <WrapItem key={index}>
              <Box
                style={{
                  display: 'grid',
                  minHeight: '200px',
                  border: '8px solid',
                  padding: '1rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                  marginTop: '20px',
                }}
              >
                <Card maxW="sm">
                  {useCase.length == 0 && (
                    <CardHeader>
                      <Heading
                        fontSize={28}
                        color="teal.500"
                        style={{ fontFamily: 'Lobster' }}
                        textAlign="center"
                      >
                        NFT #{index + 1}
                      </Heading>
                    </CardHeader>
                  )}

                  <CardBody>
                    {gameFeatureAddingMode == '2' &&
                      gameTokenId > 0 &&
                      !isMagicflowers && (
                        <Box
                          display="flex"
                          alignItems="baseline"
                          mb="20px"
                          style={{
                            fontSize: '1.2rem',
                            display: 'grid',

                            border: '8px solid',
                            padding: '1rem',
                            borderWidth: '10px',
                            borderImage:
                              'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
                          }}
                        >
                          <Text align="center" color="teal.500">
                            Game Integration
                          </Text>
                          <Box style={{ marginBottom: '20px' }}>
                            <FormControl>
                              <FormLabel as="legend">
                                Add Game Branch:
                              </FormLabel>

                              <Input
                                name="gameBranch"
                                value={gameBranch[index]}
                                onChange={e => handleGameBranch(e, index)}
                                placeholder="Game Branch"
                                size="sm"
                              />
                            </FormControl>
                          </Box>

                          {value == '1' && (
                            <ChooseFeatureForm
                              index={index}
                              gameTokenId={gameTokenId}
                              checkedItems={checkedItems}
                              setCheckedItems={setCheckedItems}
                              listOfGameFeatures={listOfGameFeatures}
                              setListOfGameFeatures={setListOfGameFeatures}
                              showGameFeatures={showGameFeatures}
                              setShowGameFeatures={setShowGameFeatures}
                              tokenContract={
                                tokenType === 'Erc721'
                                  ? process.env
                                      .REACT_APP_MAGIC_NFT_ERC721_ADDRESS
                                  : process.env
                                      .REACT_APP_MAGIC_NFT_ERC1155_ADDRESS
                              }
                              tokenId={0}
                              label="Add"
                            />
                          )}

                          {value == '2' && (
                            <ChooseFeatureForm
                              index={index}
                              gameTokenId={gameTokenId}
                              checkedItems={checkedItems}
                              setCheckedItems={setCheckedItems}
                              listOfGameFeatures={listOfGameFeatures}
                              setListOfGameFeatures={setListOfGameFeatures}
                              showGameFeatures={showGameFeatures}
                              setShowGameFeatures={setShowGameFeatures}
                              tokenContract={
                                tokenType === 'Erc721'
                                  ? process.env
                                      .REACT_APP_MAGIC_NFT_ERC721_ADDRESS
                                  : process.env
                                      .REACT_APP_MAGIC_NFT_ERC1155_ADDRESS
                              }
                              tokenId={0}
                              label="Add"
                              onlyReserved={true}
                            />
                          )}
                        </Box>
                      )}
                    {noMinting && useCase.length == 0 && (
                      <TokenIdForm
                        index={index}
                        tokenIds={tokenIds}
                        setTokenIds={setTokenIds}
                      />
                    )}

                    {!noMinting && (
                      <>
                        <TokenMetaUrlForm
                          index={index}
                          token={token}
                          metaUrl={metaUrl}
                          setMetaUrl={setMetaUrl}
                        />

                        <RoyaltyForm
                          index={index}
                          royalty={royalty}
                          setRoyalty={setRoyalty}
                        />
                      </>
                    )}

                    {tokenType === 'Erc1155' && !onlyIntegration && (
                      <AmountsErc1155Form
                        index={index}
                        amounts={mintingAmounts}
                        setAmounts={setMintingAmounts}
                        label="Minting Amounts"
                      />
                    )}

                    {forSale === '1' && !onlyIntegration && (
                      <>
                        <PriceForm
                          key={index}
                          index={index}
                          price={price}
                          setPrice={setPrice}
                        />
                        {tokenType === 'Erc1155' && (
                          <AmountsErc1155Form
                            index={index}
                            amounts={sellingAmounts}
                            setAmounts={setSellingAmounts}
                            label="Selling Amounts"
                          />
                        )}
                      </>
                    )}

                    {forSale === '2' &&
                      !onlyIntegration &&
                      tokenType === 'Erc721' && (
                        <AuctionValuesForm
                          key={index}
                          index={index}
                          auctionValues={auctionValues}
                          setAuctionValues={setAuctionValues}
                        />
                      )}

                    <TokenCategoryForm
                      index={index}
                      category={category}
                      setCategory={setCategory}
                    />

                    {addCTokensMode === '2' && (
                      <ComplimentaryTokensForm
                        index={index}
                        token={token}
                        meta={meta}
                        setMeta={setMeta}
                        newContractAddress={newContractAddress}
                        setNewContractAddress={setNewContractAddress}
                        newTokenIds={newTokenIds}
                        setNewTokenIds={setNewTokenIds}
                        isERC1155s={isERC1155s}
                        setIsERc1155s={setIsERc1155s}
                        amounts={amounts}
                        setAmounts={setAmounts}
                      />
                    )}
                  </CardBody>

                  {batch === '2' && (
                    <CardFooter>
                      <Button
                        onClick={() => removeToken(index)}
                        variant="outline"
                        colorScheme="red"
                      >
                        Remove this token
                      </Button>
                    </CardFooter>
                  )}
                </Card>
              </Box>
            </WrapItem>
          ))}
        </Wrap>
        {batch === '2' && (
          <Button onClick={addToken} variant="outline" colorScheme="green">
            Add Token
          </Button>
        )}

        {!noMinting && (
          <Card maxW="xl">
            <CardBody>
              <CollectionForm
                // collectionImageUrl={collectionImageUrl}
                // setCollectionImageUrl={setCollectionImageUrl}
                // setCollectionMeta={setCollectionMeta}
                newCollectionUrl={newCollectionUrl}
                setNewCollectionUrl={setNewCollectionUrl}
                setCollectionIndex={setCollectionIndex}
                newCollectionName={newCollectionName}
                setNewCollectionName={setNewCollectionName}
                allCollections={allCollections}
                setAllCollections={setAllCollections}
                collectionMode={collectionMode}
                setCollectionMode={setCollectionMode}
                // collectionMeta={collectionMeta}
                useCase="general"
                isForCreatorCollection={isForCreatorCollection}
                setIsForCreatorCollection={setIsForCreatorCollection}
              />
            </CardBody>
          </Card>
        )}

        <RenderButtonMinting />
      </VStack>
    </div>
  );
};

export default MintTokensBase;
