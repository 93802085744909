import * as GUI from '@babylonjs/gui';
import '../../../styles/Fonts.css';

const createTexts = (title, subtitle, titleSize = 30, subtitleSize = 22) => {
  // text
  // GUI
  var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');

  const createText = (_text, _fontSize, _color) => {
    var textblock = new GUI.TextBlock();
    textblock.text = _text;
    textblock.fontSize = _fontSize;
    // textblock.top = -188;
    textblock.color = _color;
    // textblock.class = 'myTextBlock';
    textblock.fontFamily = 'Lobster';
    advancedTexture.addControl(textblock);
    return textblock;
  };

  let text1;
  if (title.length > 0) {
    text1 = createText(title, titleSize, 'green');
    text1.top = 258;
  }

  let text2;
  if (subtitle.length > 0) {
    text2 = createText(subtitle, subtitleSize, 'green');
    text2.top = 300;
  }
};

export default createTexts;
