import { VStack, Box, Tooltip, Wrap, WrapItem, Text } from '@chakra-ui/react';
import { RenderItemName, RenderInfoAccordion } from '../../ShowItem';
import RenderValleyItem from '../RenderValleyItem';
import Sold from '../../../../../../resources/images/Sold.png';
import {
  RenderAuctionPrice,
  RenderButtonGroup,
  RenderAuctionEndingTime,
} from './ShowAuctionItem';
import RenderMyBid from './RenderMyBid';
import RenderGamesIntegrated from '../../token/RenderGamesIntegrated';
import RenderAttributes from './RenderAttributes';
import RenderComplimentaryTokens from './RenderComplimentaryTokens';
import BidderBonusImg from '../../../../../../resources/images/valley/BidderBonus.png';

function AuctionItemErc721Base({
  provider,
  item,
  metadata,
  itemCollection,
  itemPriceHistory,
  tokenType,
  saleType,
}) {
  const RenderItem = () => {
    // console.log('item:', item);
    return (
      <VStack>
        <RenderValleyItem
          category={item.category}
          image={metadata.image}
          animation_url={metadata.animation_url}
        />
        <Box textAlign="center" alignItems="baseline">
          <Tooltip
            label={'Auction ID: ' + item.auctionId}
            aria-label="A tooltip"
          >
            <RenderItemName item={item} />
          </Tooltip>
        </Box>
      </VStack>
    );
  };

  const RenderAuctionPriceInfo = () => {
    return <RenderAuctionPrice item={item} />;
  };

  const RenderBonusButton = () => {
    const _bonus = '2.50';
    return (
      <Box
        as="button"
        style={{ position: 'relative', textAlign: 'center', color: 'white' }}
      >
        <img
          src={BidderBonusImg}
          alt="Bidder Bonus"
          style={{ width: '100%' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Text color="white" fontSize="xl" as="b">
            {_bonus} MATIC
          </Text>
        </div>
      </Box>
    );
  };

  const RenderAuction = () => {
    return (
      <>
        <RenderItem />

        <Box textAlign="center" alignItems="baseline">
          {item.isSold ? (
            <img src={Sold} style={{ width: '258px', height: 'auto' }} />
          ) : (
            ''
          )}
        </Box>

        <RenderButtonGroup item={item} auctionId={item.itemId} />
        <Box display="flex" alignItems="baseline">
          <RenderAuctionEndingTime item={item} />
        </Box>

        <RenderAuctionPriceInfo />

        {/* <ViewMyBidModal /> */}

        <RenderMyBid
          provider={provider}
          lastHighestBidder={item.lastHighestBidder}
          lastHighestBid={item.lastHighestBid}
        />

        <Wrap spacing={3}>
          <WrapItem>
            <RenderGamesIntegrated
              provider={provider}
              tokenContract={item.tokenContractAddress}
              tokenId={item.tokenId}
              player={item.seller}
            />
          </WrapItem>
          <WrapItem>
            <RenderComplimentaryTokens
              itemId={item.itemId}
              allCtokenContracts={item.allCtokenContracts}
              saleType={saleType}
              tokenType={tokenType}
            />
          </WrapItem>
          <WrapItem>
            <RenderBonusButton />
          </WrapItem>
        </Wrap>

        <RenderAttributes attributes={item.attributes} />

        <VStack>
          <RenderInfoAccordion
            item={item}
            itemCollection={itemCollection}
            itemPriceHistory={itemPriceHistory}
          />
        </VStack>
      </>
    );
  };

  return (
    <VStack spacing={6}>
      <RenderAuction />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AuctionItemErc721Base;
