import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Stack,
  RadioGroup,
  Radio,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
const AddFeaturesForm = ({ gameFeature, setGameFeature }) => {
  return (
    <Box style={{ marginBottom: '20px' }}>
      <FormControl isRequired>
        <FormLabel as="legend">
          Add Game Features:
          <Popover>
            <PopoverTrigger>
              <IconButton
                variant="outline"
                colorScheme="green"
                aria-label="Get Info"
                size="xs"
                icon={<FaInfo boxSize={8} />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                Separate features with comma, for example: Butterfly, Background, Evil
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormLabel>
        <Input
          value={gameFeature}
          onChange={e => setGameFeature(e.target.value)}
          autoComplete="off"
          placeholder="Features allowed for external integration"
          size="sm"
        />
      </FormControl>
    </Box>
  );
};

export default AddFeaturesForm;
