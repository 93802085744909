import React from 'react';
import { Icon, Box } from '@chakra-ui/react';
import { FaArrowDown } from 'react-icons/fa';
const ScrollDownButton = () => {
  const scrollToContent = () => {
    document
      .getElementById('content-below')
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    // <IconButton
    //   isRound={true}
    //   variant="outline"
    //   colorScheme="green"
    //   aria-label="Done"
    //   fontSize="18px"
    //   icon={<FaArrowDown color="green.400" />}
    //   onClick={scrollToContent}
    //   onTouchStart={scrollToContent} // Adding touch event handler
    // />
    <Box
      as="button"
      onClick={scrollToContent}
      onTouchStart={scrollToContent} // Adding touch event handler
    >
      <Icon as={FaArrowDown} w={7} h={7} color="green.300" />
    </Box>
  );
};

export default ScrollDownButton;
