import { useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  VStack,
} from '@chakra-ui/react';
import FeatureItemByUser from './featured/FeatureItemByUser';
import UpdateGameModal from './games/UpdateGameModal';
import CancelGame from './games/CancelGame';
import UpdateGameAdmin from './games/UpdateGameAdmin';

function GameOwnerActions({ gameId }) {
  const [showSellerActions, setShowSellerActions] = useState(false);
  const handleShowSellerActions = () => {
    if (showSellerActions) {
      setShowSellerActions(false);
    } else {
      setShowSellerActions(true);
    }
  };
  const SellerActionsButton = () => {
    if (showSellerActions) {
      return (
        <>
          <Button
            size="md"
            colorScheme="green"
            onClick={handleShowSellerActions}
          >
            Hide Owner Actions
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="md"
            colorScheme="green"
            onClick={handleShowSellerActions}
          >
            Game Owner Actions
          </Button>
        </>
      );
    }
  };
  return (
    <VStack>
      <SellerActionsButton />
      {showSellerActions && (
        <Box
          mt={3}
          display="flex"
          alignItems="baseline"
          style={{
            fontSize: '1.2rem',
            display: 'grid',
            placeItems: 'center',
            border: '8px solid',
            padding: '1rem',
            borderWidth: '10px',
            borderImage:
              'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
          }}
        >
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab>Featuring Game</Tab>
              <Tab>Update Game</Tab>
              <Tab>Update Game Admin</Tab>
              <Tab>Cancel Game</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box mt={6}>
                  <FeatureItemByUser
                    tokenContract=""
                    tokenId={gameId}
                    gameIdByUser={gameId}
                    featuringPagesByUser={['gameList']}
                  />
                </Box>
              </TabPanel>

              <TabPanel>
                <Box mt={6}>
                  <UpdateGameModal gameId={gameId} />
                </Box>
              </TabPanel>

              <TabPanel>
                <Box mt={6}>
                  <UpdateGameAdmin gameId={gameId} />
                </Box>
              </TabPanel>

              <TabPanel>
                <Box mt={6}>
                  <CancelGame gameId={gameId} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </VStack>
  );
}

export default GameOwnerActions;
