import { Box, Input, FormControl, FormLabel } from '@chakra-ui/react';

const RenderDisplayName = ({ newDisplayName, setNewDisplayName, label }) => {
  const handleNewDisplayName = event => setNewDisplayName(event.target.value);

  return (
    <Box style={{ marginBottom: '20px' }}>
      <FormControl isRequired>
        <FormLabel as="legend">{label}:</FormLabel>

        <Input
          value={newDisplayName}
          onChange={handleNewDisplayName}
          placeholder={label}
          size="sm"
        />
      </FormControl>
    </Box>
  );
};

export default RenderDisplayName;
