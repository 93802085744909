import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  VStack,
  Box,
  Text,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Input
} from '@chakra-ui/react';

function TokenGameTrialModal(props) {
  console.log('TokenGameTrialModal props:', props);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFeature, setSelectedFeature] = useState('');
  const [gameBranch, setGameBranch] = useState(props.branch);
  const [toUrl, setToUrl] = useState('');

  const handleSelectedFeature = event => {
    console.log('event:', event);
    setSelectedFeature(event.target.value);
    const _toUrl = getUrl();
    console.log('_toUrl:', _toUrl);
    setToUrl(_toUrl);
  };

  const handleGameBranch = e => {
    setGameBranch(e.target.value);
  };

  const getUrl = () => {
    // Construct the URL with parameters
    const params = new URLSearchParams();
    params.append('contract', props.tokenContract);
    params.append('tokenId', props.tokenId);
    params.append('feature', selectedFeature);
    params.append('category', props.category);
    params.append('branch', gameBranch);

    const _to = props.trialUrl + '?' + params.toString();
    console.log('_to:', _to);

    return _to;
  };

  const RenderGameFeatureForm = () => {
    return (
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Select Game Feature:</FormLabel>

          <Select
            placeholder="Select game feature"
            onChange={handleSelectedFeature}
          >
            {props.features.length &&
              props.features.map((feat, i) => (
                <option key={i} value={feat}>
                  {feat}
                </option>
              ))}
          </Select>
        </FormControl>
      </Box>
    );
  };
  return (
    <>
      <Button onClick={onOpen}>Try NFT in Game</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RenderGameFeatureForm />
            <Box style={{ marginBottom: '20px' }}>
              <FormControl>
                <FormLabel as="legend">Add Game Branch:</FormLabel>

                <Input
                  name="gameBranch"
                  value={gameBranch}
                  onChange={e => handleGameBranch(e)}
                  placeholder="Game Branch"
                  size="sm"
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3}>
              <Text>
                {' '}
                <Link href={toUrl} isExternal>
                  Try this token
                </Link>
              </Text>
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TokenGameTrialModal;
