import UpdateTokensInCollection from '../../collections/UpdateTokensInCollection';
const UserRemoveTokenFromCollection = ({ tokenContract, tokenId }) => {
  return (
    <UpdateTokensInCollection
      useCase="TokenByUser"
      tokenContract={tokenContract}
      tokenId={tokenId}
      updateType="Remove"
    />
  );
};

export default UserRemoveTokenFromCollection;
