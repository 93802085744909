import { Box, FormControl, FormLabel } from '@chakra-ui/react';

const RenderDisplayCoverImage = ({
  objUrlDisplay,
  setObjUrlDisplay,
  setFileDisplay,
  setData,
  err,
  setErr,
  label
}) => {
  const handleImageDisplay = e => {
    const reader = new FileReader();
    // console.log(e.target.files.length);
    if (e.target.files.length === 0) {
      return false;
    }
    setObjUrlDisplay(URL.createObjectURL(e.target.files[0]));
    setData(null);
    setErr(false);

    setFileDisplay(e.target.files[0]);
    reader.onload = loadEvt => {
      setData(loadEvt.target?.result);
    };
  };

  return (
    <Box>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Add {label} Cover Image:</FormLabel>

          <input type="file" onChange={handleImageDisplay} />
        </FormControl>
      </Box>
      <img src={objUrlDisplay} />

      {err && <p>Unable to upload image: {err}</p>}
    </Box>
  );
};

export default RenderDisplayCoverImage;
