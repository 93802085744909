import { Button } from '@chakra-ui/react';
const DetailButton = ({ buttonSize, label='' }) => {
  const _label = label.length === 0 ? 'View Details' : label;
  return (
    <>
      <Button
        size={buttonSize}
        as="button"
        p={4}
        color="white"
        fontWeight="bold"
        borderRadius="xl"
        bgGradient="linear(to-r, #439cfb, #f187fb)"
        _hover={{
          bgGradient: 'linear(to-r, teal.300, green.300)',
        }}
      >
        {_label}
      </Button>
    </>
  );
};

export default DetailButton;
