import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const TokenIdForm = ({ index, tokenIds, setTokenIds }) => {
  const handleTokenId = (index, value) => {
    console.log('value:', value);
    const updatedTokenIds = [...tokenIds];
    updatedTokenIds[index] = value;
    setTokenIds(updatedTokenIds);
  };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">NFT Id:</FormLabel>

          <NumberInput
            min={0}
            value={tokenIds[index]}
            onChange={e => handleTokenId(index, e)}
            placeholder="NFT ID"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Box>
    </>
  );
};

export default TokenIdForm;
