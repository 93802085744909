import ValleyItemListBase from './ValleyItemListBase';

const ValleyItemListGames = ({ saleType}) => {
  return (
    <ValleyItemListBase
      saleType={saleType}
      marketplaceIds={[]}
      auctionIds={[]}
      byUser={false}
      userAddr=''
      forGame={true}
    />
  );
};

export default ValleyItemListGames;