import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  HStack,
  VStack,
  Box,
  Text,
  Icon,
  Tooltip,
  Card,
  CardBody,
  Container,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaTwitter, FaFacebookF, FaYoutube } from 'react-icons/fa';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import UpdateHighlights from '../../../actions/NFTs/userActions/myPages/UpdateHighlights';
import Helper from '../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import { getCidUrlNftstorage } from '../../../utils/helpers';
import axios from 'axios';
import { Player } from 'video-react';
import StickyNote from '../../../../resources/images/frames/stickyNotes/stickyNote5.png';
import getImageUrl from '../../../utils/getImageUrl';
import RenderValleyItem from '../elements/singleValleyItem/RenderValleyItem';
import getAllValleyItems from '../elements/valleyItems/getAllValleyItems';
import { _MeshCollisionData } from '@babylonjs/core';
import { REACT_APP_HELPER_ADDRESS } from '../../../Constants/ContractAddress';
function HighlightsBase({ addr, pageName, useCase }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  // console.log('artistAddress:', artistAddress);
  const [lastCid, setLastCid] = useState('');
  const [highlights, setHighlights] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [featuredMarketplaceErc721, setFeaturedMarketplaceErc721] = useState(
    []
  );
  const [featuredMarketplaceErc1155, setFeaturedMarketplaceErc1155] = useState(
    []
  );
  const [featuredAuctions, setFeaturedAuctions] = useState([]);

  useEffect(() => {
    getHighlights();
  }, []);

  const ArtistAction = () => {
    if (Object.keys(highlights).length == 0) {
      return <UpdateHighlights pageName={pageName} lastCid={lastCid} />;
    } else {
      return (
        <UpdateHighlights
          pageName={pageName}
          lastCid={lastCid}
          initialMeta={highlights}
        />
      );
    }
  };

  const getHighlights = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const helper = new ethers.Contract(
      REACT_APP_HELPER_ADDRESS,
      Helper.abi,
      provider
    );

    let _data = await helper.getTokenHighlights(addr);
    // console.log('_data:', _data);
    let _highlightsData =
      pageName == 'Artist' ? _data.artistHighlight : _data.sellerHighlight;
    if (_highlightsData.length > 0) {
      setLastCid(_highlightsData);
    }
    let _highlightsUrl = getCidUrlNftstorage(_highlightsData);
    let _highlights = await axios.get(_highlightsUrl);

    _highlights = _highlights.data;
    // console.log('_highlights:', _highlights);

    setHighlights(_highlights);

    if (pageName == 'Artist') {
      if (_highlights.cTokenContracts.length > 0) {
        const _tokenContracts = _highlights.cTokenContracts;
        const _tokenIds = _highlights.cTokenIds;
        let _featured = [];
        for (let i = 0; i < _tokenContracts.length; i++) {
          let _featTokenIds = removeDuplicatesFromString(_tokenIds[i]);
          // console.log('_featTokenIds:', _featTokenIds);
          for (let j = 0; j < _featTokenIds.length; j++) {
            let imageUrl = await getImageUrl(
              provider,
              _featTokenIds[j],
              _tokenContracts[i]
            );
            // console.log('imageUrl:', imageUrl);
            _featured.push({
              tokenContract: _tokenContracts[i],
              tokenId: _featTokenIds[j],
              imageUrl,
            });
          }
        }
        // console.log('_featured:', _featured);
        setFeaturedItems(_featured);
      }
    } else {
      if (_highlights.marketplaceIdsErc721.length > 0) {
        const _ids = stringToNumber(_highlights.marketplaceIdsErc721);
        if (_ids.length > 0) {
          const _allItems = await getAllValleyItems(
            provider,
            'Erc721',
            'Marketplace',
            _ids,
            [],
            true
          );
          // console.log('_allItems:', _allItems);
          setFeaturedMarketplaceErc721(_allItems);
        }
      }

      if (_highlights.marketplaceIdsErc1155.length > 0) {
        const _ids = stringToNumber(_highlights.marketplaceIdsErc1155);
        if (_ids.length > 0) {
          const _allItems = await getAllValleyItems(
            provider,
            'Erc1155',
            'Marketplace',
            _ids,
            [],
            true
          );
          // console.log('_allItems:', _allItems);
          setFeaturedMarketplaceErc1155(_allItems);
        }
      }

      if (_highlights.auctionIds.length > 0) {
        const _ids = stringToNumber(_highlights.auctionIds);
        if (_ids.length > 0) {
          const _allItems = await getAllValleyItems(
            provider,
            'Erc721',
            'Aucton',
            [],
            _ids,
            true
          );
          // console.log('_allItems:', _allItems);
          setFeaturedAuctions(_allItems);
        }
      }
    }
  };

  function stringToNumber(arr) {
    return arr.filter(item => item !== '').map(item => Number(item));
  }

  function removeDuplicatesFromString(str) {
    // Split the string by commas and trim each element to remove leading/trailing spaces
    const array = str.split(',').map(item => item.trim());

    // Create a new Set from the array to automatically remove duplicates
    const uniqueArray = [...new Set(array)];

    return uniqueArray;
  }

  const handleShowDescription = () => {
    if (showDescription) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
  };

  const DescriptionFrame = () => {
    const _title = showDescription ? 'Hide Description' : 'Description';
    return (
      <Box as="button" onClick={handleShowDescription}>
        <div
          style={{ position: 'relative', textAlign: 'center', color: 'white' }}
        >
          <img src={StickyNote} style={{ width: '100%' }} />

          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '47%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <VStack>
              <Text
                color="teal.500"
                fontSize="4xl"
                style={{ fontFamily: 'Lobster' }}
              >
                {_title}
              </Text>
            </VStack>
          </div>
        </div>
      </Box>
    );
  };

  const RenderHighlights = () => {
    const _title = pageName == 'Artist' ? 'Artist' : 'Shop';
    if (Object.keys(highlights).length > 0) {
      return (
        <Box textAlign="center" alignItems="baseline">
          <Player
            playsInline
            poster={highlights.posterUrl}
            src={highlights.videoUrl}
          />
          <Text
            color="green.400"
            fontSize={[36, 42, 68]}
            style={{ fontFamily: 'Lobster' }}
          >
            {highlights.name}
          </Text>

          <Box>
            <DescriptionFrame />
          </Box>

          {showDescription && (
            <Container>
              <Card>
                <CardBody>
                  <VStack>
                    <Box>{highlights.description}</Box>
                    <Box textAlign="center" alignItems="baseline">
                      <Link href={highlights.externalUrl} isExternal>
                        <Text as="i">Visit {pageName} Website</Text>
                        <Tooltip
                          label={highlights.externalUrl}
                          aria-label="A tooltip"
                        >
                          <ExternalLinkIcon mx="2px" />
                        </Tooltip>
                      </Link>
                    </Box>
                    <HStack spacing={3}>
                      <Box>
                        <Link href={highlights.youtube} isExternal>
                          <Tooltip
                            label={highlights.youtube}
                            aria-label="A tooltip"
                          >
                            <Icon as={FaYoutube} color="#FF0000" />
                          </Tooltip>
                        </Link>
                      </Box>
                      <Box>
                        <Link href={highlights.X} isExternal>
                          <Tooltip label={highlights.x} aria-label="A tooltip">
                            <Icon as={FaTwitter} color="#1DA1F2" />
                          </Tooltip>
                        </Link>
                      </Box>

                      <Box>
                        <Link href={highlights.facebook} isExternal>
                          <Tooltip
                            label={highlights.facebook}
                            aria-label="A tooltip"
                          >
                            <Icon as={FaFacebookF} color="#4267B2" />
                          </Tooltip>
                        </Link>
                      </Box>
                    </HStack>
                  </VStack>
                </CardBody>
              </Card>
            </Container>
          )}
        </Box>
      );
    } else {
      return (
        <Box textAlign="center" alignItems="baseline">
          <Text
            color="green.400"
            fontSize={[36, 42, 68]}
            style={{ fontFamily: 'Lobster' }}
          >
            {_title}
          </Text>
          <Text color="green.400">{addr}</Text>
        </Box>
      );
    }
  };

  const ShowFeaturedSales = ({ featuredSales }) => {
    return (
      <>
        {featuredSales.length > 0 &&
          featuredSales.map((item, i) => (
            <Box key={i}>
              <VStack>
                <Link
                  as={ReactLink}
                  to={`/marketplace/erc721/${item.valleyId}`}
                >
                  <RenderValleyItem
                    category={item.category}
                    tokenUri={item.tokenUri}
                    enableZoom={false}
                  />
                </Link>

                <Text
                  color="teal.500"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  {item.name}
                </Text>
              </VStack>
            </Box>
          ))}
      </>
    );
  };

  const RenderFeaturedSales = () => {
    if (
      featuredMarketplaceErc721.length > 0 ||
      featuredMarketplaceErc1155.length > 0 ||
      featuredAuctions.length > 0
    ) {
      return (
        <VStack>
          <Text
            color="teal.500"
            fontSize={[24, 36, 42]}
            style={{ fontFamily: 'Lobster' }}
          >
            Today's Spotlight
          </Text>
          <ShowFeaturedSales featuredSales={featuredMarketplaceErc721} />
          <ShowFeaturedSales featuredSales={featuredMarketplaceErc1155} />
          <ShowFeaturedSales featuredSales={featuredAuctions} />
        </VStack>
      );
    }
  };

  const RenderFeaturedItems = () => {
    if (featuredItems.length > 0) {
      return (
        <VStack>
          <Text
            color="teal.500"
            fontSize={[24, 36, 42]}
            style={{ fontFamily: 'Lobster' }}
          >
            Today's Spotlight
          </Text>
          <VStack
            spacing={8}
            style={{ paddingTop: '30px', paddingBottom: '30px' }}
          >
            {featuredItems.map((item, key) => (
              <Box key={key}>
                <Link
                  as={ReactLink}
                  to={`/tokens/${item.tokenContract}/${item.tokenId}`}
                >
                  <img
                    src={item.imageUrl}
                    alt={item.imageUrl}
                    className="hover-zoom"
                    // style={{ width: '400px' }}
                    style={{
                      width: item.width < 358 ? item.width : '358px',
                      height: 'auto',
                    }}
                  />
                </Link>
              </Box>
            ))}
          </VStack>
        </VStack>
      );
    }
  };

  const RenderFeatured = () => {
    if (pageName == 'Artist') {
      return <RenderFeaturedItems />;
    } else {
      return <RenderFeaturedSales />;
    }
  };

  return (
    <>
      <RenderHighlights />
      <VStack mt="10px" mb="10px">
        {useCase == 'byUser' && <ArtistAction />}
      </VStack>
      <RenderFeatured />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default HighlightsBase;
