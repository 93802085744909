import React from 'react';
import { Box, Text, Container, VStack } from '@chakra-ui/react';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import FeaturingItemBase from '../../../components/actions/NFTs/featuringItemBase';
const FeaturingAction = () => {
  return (
    <>
      <Container>
        <VStack spacing={4}>
          <Box>
            <HomeIcon />
          </Box>
          <Box mt='10px'>
            <Text
              color="green.300"
              fontSize={[39, 58, 66]}
              style={{ fontFamily: 'Lobster' }}
            >
              Featuring Your NFTs
            </Text>
          </Box>
          <FeaturingItemBase />
        </VStack>
      </Container>
    </>
  );
};

export default FeaturingAction;
