import { FaStarAndCrescent } from 'react-icons/fa';
import { VStack, Link, Text, Icon, Box } from '@chakra-ui/react';

const Campaign = ({ isDetailed = true, isBlueGradient = true }) => {
  const GreenGradient =
    'linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))';
  const BlueGradient =
    'radial-gradient(circle farthest-corner at 12.3% 19.3%, rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2%)';
  const _gradient = isBlueGradient ? BlueGradient : GreenGradient;
  return (
    <VStack mt="18px">
      <Icon as={FaStarAndCrescent} w={8} h={8} color="yellow.200" />
      <Link href="https://igg.me/at/magicnftvalley" isExternal>
        <Box
          bgGradient={_gradient}
          color="white"
          width={['358px', '458px', '580px']}
          boxShadow="2xl"
          p={6}
          rounded="md"
        >
          <VStack spacing={6}>
            <Text as="b" fontSize="3xl" fontFamily="Lobster">
              Support Our Indiegogo Campaign
            </Text>

            <Text as="b" fontSize="xl">
              Save Over 70% on Membership Fees!
            </Text>
            {isDetailed && (
              <Text fontSize="lg">
                Membership registration is currently only available through our
                Indiegogo campaign. Once the campaign ends, registration will
                resume on our website. Don’t miss out—join us today by visiting
                the campaign page.
              </Text>
            )}
          </VStack>
        </Box>
      </Link>
    </VStack>
  );
};

export default Campaign;
