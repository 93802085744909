import { ethers } from 'ethers';
import Helper from '../../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import { REACT_APP_HELPER_ADDRESS } from '../../../../Constants/ContractAddress';

const getCurrentListingForToken = async (
  provider,
  tokenContractAddress,
  tokenId
) => {
  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );

  const res = await helper.getItemByTokenId(tokenContractAddress, tokenId);
  // console.log('helperTokenInfo:', res);

  let saleIdsAuction = res.saleIdsAuction;
  let saleIdsMarketplace = res.saleIdsMarketplace;
  if (saleIdsAuction.length > 0) {
    saleIdsAuction = saleIdsAuction.map(auction => Number(auction.toString()));
  }
  if (saleIdsMarketplace.length > 0) {
    saleIdsMarketplace = saleIdsMarketplace.map(m => Number(m.toString()));
  }

  let _currentListing = {
    saleIdsAuction,
    saleIdsMarketplace,
  };

  // console.log('_currentListing:', _currentListing);

  return _currentListing;
};

export default getCurrentListingForToken;
