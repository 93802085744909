import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  VStack,
  Box,
  Text,
  Button,
  Link,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  CardBody,
  Card,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import MarketplaceERC721 from '../../../../../../contracts/APIs/MarketplaceERC721Interface.sol/MarketplaceERC721Interface.json';
import MarketplaceERC1155 from '../../../../../../contracts/APIs/MarketplaceERC1155Interface.sol/MarketplaceERC1155Interface.json';
import AuctionHouse from '../../../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import ITokenURI from '../../../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import getComplimentaryTokenInfo from './getComplimentaryTokenInfo';
import getNetworkProvider from '../../../../../../walletConnections/obtainProviders/getNetworkProvider';
import ComplimentaryTokensImg from '../../../../../../resources/images/valley/complimentary-tokens.gif';
import HideComplimentaryTokensImg from '../../../../../../resources/images/valley/complimentary-tokens-hide.gif';
import {
  REACT_APP_AUCTION_HOUSE_ADDRESS,
  REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
  REACT_APP_MARKETPLACE_ERC721_ADDRESS,
} from '../../../../../Constants/ContractAddress';
function RenderComplimentaryTokens({
  itemId,
  allCtokenContracts,
  saleType,
  tokenType,
}) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [allComplimentaryTokens, setAllComplimentaryTokens] = useState([]);

  const [showCtokens, setShowCtokens] = useState(false);

  const getComplimentaryTokens = async (
    itemId,
    allCtokenContracts,
    saleType,
    tokenType
  ) => {
    // console.log('itemId', typeof itemId)
    // const web3Modal = new Web3Modal(REACT_APP_PROJ_ADDRESS);
    // const connection = await web3Modal.connect();
    // const provider = new ethers.providers.Web3Provider(connection);

    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    // console.log('provider', provider);
    // console.log('itemId:', itemId);
    // console.log('allCtokenContracts:', allCtokenContracts);
    // console.log('saleType:', saleType);
    // console.log('tokenType:', tokenType);

    let saleContract;

    if (saleType === 'Marketplace') {
      if (tokenType === 'Erc721') {
        saleContract = new ethers.Contract(
          REACT_APP_MARKETPLACE_ERC721_ADDRESS,
          MarketplaceERC721.abi,
          provider
        );
      } else if (tokenType === 'Erc1155') {
        saleContract = new ethers.Contract(
          REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
          MarketplaceERC1155.abi,
          provider
        );
      }
    } else if (saleType === 'Auction') {
      saleContract = new ethers.Contract(
        REACT_APP_AUCTION_HOUSE_ADDRESS,
        AuctionHouse.abi,
        provider
      );
    }

    let allComplimentaryTokens = [];
    for (let i = 0; i < allCtokenContracts.length; i++) {
      let token_contract = new ethers.Contract(
        allCtokenContracts[i],
        ITokenURI.abi,
        provider
      );
      let _complimentaryTokens = await saleContract.getComplimentaryTokens(
        allCtokenContracts[i],
        itemId
      );

      let cTokens = [];

      console.log('_complimentaryTokens:', _complimentaryTokens);

      let _cTokenIds = _complimentaryTokens.tokenIds.map(x =>
        Number(x.toString())
      );
      // console.log('_cTokenIds:', _cTokenIds);
      let _cAmounts = [];
      if (_complimentaryTokens.isERC1155) {
        _cAmounts = _complimentaryTokens.amounts.map(x => Number(x.toString()));
      }

      for (let j = 0; j < _cTokenIds.length; j++) {
        let tokenInfo = await getComplimentaryTokenInfo(
          provider,
          token_contract,
          _cTokenIds[j],
          _complimentaryTokens.isERC1155
        );
        // console.log('cTokenInfo:', tokenInfo);
        let _cToken = {
          tokenContract: allCtokenContracts[i],
          tokenId: _cTokenIds[j],
          tokenInfo,
          amount: _complimentaryTokens.isERC1155
            ? Number(_cAmounts[j].toString)
            : 0,
        };
        // console.log('_cToken:', _cToken);
        cTokens.push(_cToken);
      }

      // console.log('_complimentaryTokens.isERC1155:', _complimentaryTokens.isERC1155)

      let complimentaryTokens = {
        cTokenContract: allCtokenContracts[i],
        isERC1155: _complimentaryTokens.isERC1155 ? true : false,
        cTokens,
      };

      // console.log('complimentaryTokens:', complimentaryTokens);

      allComplimentaryTokens.push(complimentaryTokens);
    }
    // console.log('allComplimentaryTokens:', allComplimentaryTokens);

    return allComplimentaryTokens;
  };

  const handleClick = async () => {
    const _allComplimentaryTokens = await getComplimentaryTokens(
      itemId,
      allCtokenContracts,
      saleType,
      tokenType
    );
    setAllComplimentaryTokens(_allComplimentaryTokens);
    handleShowCtokens();
  };

  const handleShowCtokens = async () => {
    if (showCtokens) {
      setShowCtokens(false);
    } else {
      setShowCtokens(true);
    }
  };

  const RenderTokensButton = () => {
    // const _title = showCtokens
    //   ? 'Hide Complimentary Tokens'
    //   : 'Complimentary Tokens';
    if (showCtokens) {
      return (
        <Box onClick={handleClick} as="button" p={4}>
          <img src={HideComplimentaryTokensImg} alt="Complimentary Tokens" />
        </Box>
      );
    } else {
      return (
        <Box onClick={handleClick} as="button" p={4}>
          <img src={ComplimentaryTokensImg} alt="Complimentary Tokens" />
        </Box>
      );
    }
  };

  const truncateAddress = address => {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  return (
    <VStack>
      <>
        <RenderTokensButton />
        {showCtokens && allComplimentaryTokens.length === 0 && (
          <Text fontSize="xl" color="teal.500">
            No complimentary token
          </Text>
        )}
        {showCtokens && allComplimentaryTokens.length > 0 && (
          <Box
            style={{
              display: 'grid',
              minHeight: '200px',
              border: '8px solid',
              padding: '1rem',
              borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
              marginTop: '20px',
            }}
          >
            {allComplimentaryTokens.map((token, index) => {
              return (
                <Box key={index} p="2" rounded="md" textAlign="center">
                  <VStack>
                    {token.cTokens.length > 0 &&
                      token.cTokens.map((t, i) => {
                        return (
                          <Box key={i}>
                            <VStack>
                              <Box>
                                <img
                                  style={{ width: '180px' }}
                                  src={t.tokenInfo.metadata.image}
                                />
                              </Box>
                              <Box>
                                <Text as="i" fontSize="md">
                                  {t.tokenInfo.metadata.name}
                                </Text>
                              </Box>
                              {token.isERC1155 && (
                                <Box>Amount: {token.amount}</Box>
                              )}
                              <Popover>
                                <PopoverTrigger>
                                  <Button colorScheme="green" variant="outline">
                                    Info
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />

                                  <PopoverBody textAlign="left">
                                    <Card>
                                      <CardBody>
                                        <HStack>
                                          <Text
                                            as="i"
                                            fontSize="md"
                                            textAlign="left"
                                          >
                                            Token Contract:
                                          </Text>
                                          <Text as="i" fontSize="md">
                                            {truncateAddress(
                                              token.cTokenContract
                                            )}
                                          </Text>
                                        </HStack>
                                        <HStack>
                                          <Text as="i" fontSize="md">
                                            Token ID:
                                          </Text>
                                          <Text as="i" fontSize="md">
                                            {t.tokenId}
                                          </Text>
                                        </HStack>
                                      </CardBody>
                                    </Card>
                                  </PopoverBody>
                                  <PopoverFooter>
                                    {' '}
                                    <Link
                                      as={ReactLink}
                                      to={`/tokens/${token.cTokenContract}/${t.tokenId}`}
                                    >
                                      <Button colorScheme="green">
                                        View Details
                                      </Button>
                                    </Link>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </VStack>
                          </Box>
                        );
                      })}
                  </VStack>
                </Box>
              );
            })}
          </Box>
        )}
      </>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default RenderComplimentaryTokens;
