import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  AccordionPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Text,
} from '@chakra-ui/react';
export const WhatIsArtistPage = () => {
  return (
    <AccordionPanel pb={4}>
      An artist page is a dedicated space for NFT creators. Every creator who
      has crafted an NFT in Magic NFT Valley will automatically have their own
      artist page generated. Within an artist page, the 'All Tokens Created'
      section displays all tokens crafted by the creator, while the 'All
      Collections Created' section showcases all collections they've curated.
    </AccordionPanel>
  );
};
export const AccessArtistPage = () => {
  return (
    <AccordionPanel pb={4}>
      <OrderedList>
        <ListItem>
          When an NFT is listed for sale or auction, you can easily find the
          creator's artist page link on its individual sale or auction page.
        </ListItem>
        <ListItem>
          Alternatively, you can use the search icon, typically located on most
          pages. Select 'Artist', input the artist's account address, and the
          link to their artist page will be displayed.
        </ListItem>
        <ListItem>
          To access your own artist page, navigate to your User page, click on
          'Dashboard', and choose 'My Pages' from the dropdown menu. A list of
          pages tailored specifically for you will appear. Simply click on 'My
          Artist Page'.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

const customizeArtistPageText = () => {
  return (
    <Text>
      To personalize your artist page, follow these steps: 1. Connect your
      wallet by clicking the 'Connect Wallet' button in the top right corner.
      Choose your preferred wallet from the dropdown menu. Once connected, your
      wallet balance and address will be securely displayed via WalletConnect.
      2. Navigate to the User page, then click on 'Dashboard' and select 'My
      Pages' from the dropdown menu. Choose 'My Artist Page' to access your
      individual artist page. 3. Click the 'Update Highlights' button, and a
      modal will appear containing the update form. 4. If you've previously set
      metadata for your artist page, the form will populate with the information
      you last entered. Simply modify any details you wish to update with new
      information. 5. If you haven't set any metadata for your artist page
      before, please fill out the form with the URL to your poster and/or
      introduction video, display name, description, tags, external URL, and
      links to your social media accounts. Adding highlight tokens is optional
      but can enhance your page's visibility. 6. Click 'Submit' to finalize the
      process. 7. Your wallet will prompt you to sign or confirm the
      transaction. Follow the instructions provided by your wallet to complete
      the process securely.
    </Text>
  );
};
export const CustomizeArtistPage = () => {
  return (
    <AccordionPanel pb={4}>
      To personalize your artist page, follow these steps:
      <OrderedList>
        <ListItem>
          Connect your wallet by clicking the 'Connect Wallet' button in the top
          right corner. Choose your preferred wallet from the dropdown menu.
          Once connected, your wallet balance and address will be securely
          displayed via WalletConnect.
        </ListItem>
        <ListItem>
          Navigate to the User page, then click on 'Dashboard' and select 'My
          Pages' from the dropdown menu. Choose 'My Artist Page' to access your
          individual artist page.
        </ListItem>
        <ListItem>
          Click the 'Update Highlights' button, and a modal will appear
          containing the update form.
        </ListItem>
        <ListItem>
          If you've previously set metadata for your artist page, the form will
          populate with the information you last entered. Simply modify any
          details you wish to update with new information.
        </ListItem>

        <ListItem>
          If you haven't set any metadata for your artist page before, please
          fill out the form with the URL to your poster and/or introduction
          video, display name, description, tags, external URL, and links to
          your social media accounts. Adding highlight tokens is optional but
          can enhance your page's visibility.{' '}
        </ListItem>
        <ListItem>Click 'Submit' to finalize the process.</ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Follow
          the instructions provided by your wallet to complete the process
          securely.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

const UpdateMetaText = () => {
  <Text>
    To update the meta for your artist page, follow these straightforward steps:
    1. Ensure your wallet is connected. Click on the 'Connect Wallet' button
    located in the top right corner. Choose your preferred wallet from the
    dropdown menu. Once connected, your wallet balance and address will be
    displayed securely through WalletConnect. 2. Navigate to the User page,
    click on 'Dashboard', then select 'My Pages' from the dropdown menu, and
    click 'My Artist Page'. This action will lead you to your individual artist
    page. 3. Click the 'Update Highlights' button, triggering a modal to appear,
    carrying the updating form. 4. The form will contain all the meta
    information you previously set. Simply modify the parts you wish to update
    with new information. 5. Click 'Submit' to finalize the process. 6. Your
    wallet will prompt you to sign or confirm the transaction. Follow the
    instructions provided by your wallet to complete the update.
  </Text>;
};

export const UpdateMeta = () => {
  return (
    <AccordionPanel pb={4}>
      To update the meta for your artist page, follow these straightforward
      steps:
      <OrderedList>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          located in the top right corner. Choose your preferred wallet from the
          dropdown menu. Once connected, your wallet balance and address will be
          displayed securely through WalletConnect.
        </ListItem>
        <ListItem>
          {' '}
          Navigate to the User page, click on 'Dashboard', then select 'My
          Pages' from the dropdown menu, and click 'My Artist Page'. This action
          will lead you to your individual artist page.{' '}
        </ListItem>

        <ListItem>
          Click the 'Update Highlights' button, triggering a modal to appear,
          carrying the updating form.{' '}
        </ListItem>
        <ListItem>
          The form will contain all the meta information you previously set.
          Simply modify the parts you wish to update with new information.{' '}
        </ListItem>
        <ListItem> Click 'Submit' to finalize the process. </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Follow
          the instructions provided by your wallet to complete the update.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const ArtistCollection = () => {
  return (
    <AccordionPanel pb={4}>
      An artist collection serves as a meticulously curated assortment of NFTs
      crafted by the creator, aiding in the expression and embodiment of their
      artistic vision and ideas.
    </AccordionPanel>
  );
};

const CreateNewCollectionText = () => {
  <Text>
    To create a new artist collection, please follow these steps: 1. Ensure your
    wallet is connected. Click on the 'Connect Wallet' button in the top right
    corner, then select your desired wallet from the dropdown menu. Once
    connected, your wallet balance and address will be displayed. Connection is
    facilitated through WalletConnect, a trusted protocol for security. 2.
    Navigate to the page `Dream Minting Studio`, click `Collection`, and then
    click `Createt New Collection and Add Tokens`. A modal will appear
    displaying a form. 3. Fill out the form with an image url to the image for
    your collection, name/description of your collection, and external url for
    the NFT or the creator. 4. Specify if you want to add tokens to this new
    collection now. If yes, provide the token Id(s) of the token(s) to be added
    to this collection, as well as the token contract address where the tokens
    originaged. If you have minted the tokens in Magic NFT Valley, simply choose
    `Magic NFT Erc721 contract` or `Magic NFT Erc1155 contract` depending on the
    token type. If the tokens were minted else outside Magic NFT Valley, choose
    `Other contract` and input the contract address. 5.Click the 'Create' button
    to complete the process. 6. Your wallet will prompt you to sign or confirm
    the sale. Follow the instructions provided by your wallet. 7. Please note
    that you can only add tokens you to a collection if the tokens are created
    by you and owned by you. If you have sold a token you created, you can no
    longer change anything on the token with the only exception of removing
    royalty from that token. If you are not the token creator, even if you own
    the token, you cannot add them to an artist collection.
  </Text>;
};

export const CreateNewCollection = () => {
  return (
    <AccordionPanel pb={4}>
      To create a new artist collection, please follow these steps:
      <OrderedList>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          located in the top right corner, then select your desired wallet from
          the dropdown menu. Once connected, your wallet balance and address
          will be displayed securely through WalletConnect, ensuring the safety
          of your transactions.
        </ListItem>

        <ListItem>
          Navigate to the 'Dream Minting Studio' page, then click on
          'Collection', followed by 'Create New Collection and Add Tokens'. This
          action will prompt a modal to appear, displaying a form.
        </ListItem>

        <ListItem>
          Fill out the form with the necessary details, including an image URL
          for your collection, a name/description, and an external URL for the
          NFT or creator.
        </ListItem>

        <ListItem>
          Specify whether you want to add tokens to this new collection
          immediately. If yes, provide the token ID(s) of the token(s) to be
          added, along with the token contract address from which they
          originated. If the tokens were minted within Magic NFT Valley, simply
          select either 'Magic NFT Erc721 contract' or 'Magic NFT Erc1155
          contract' depending on the token type. For tokens minted elsewhere,
          choose 'Other contract' and input the contract address.
        </ListItem>

        <ListItem>Click the 'Create' button to finalize the process.</ListItem>

        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Follow
          the instructions provided by your wallet to complete the creation of
          your collection.
        </ListItem>
      </OrderedList>
      Please note that you can only add tokens to a collection if you are the
      creator and owner of those tokens. Once a token you created has been sold,
      you can no longer make changes to it, except for removing royalties. If
      you are not the token creator, even if you own the token, you cannot add
      it to an artist collection.
    </AccordionPanel>
  );
};

export const AccessCollection = () => {
  return (
    <AccordionPanel pb={4}>
      <OrderedList>
        <ListItem>
          When an NFT is listed for sale or auction, you can readily locate the
          link to the collection it belongs to on its respective sale or auction
          page.
        </ListItem>
        <ListItem>
          {' '}
          Alternatively, you can access the collection through the individual
          token page.
        </ListItem>
        <ListItem>
          {' '}
          Your own artist collections are accessible via your User page or your
          individual Artist or Seller Shop page.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const ImageUrl = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        When minting your NFT in Dream Minting Studio, you'll be prompted to
        supply a URL linking to the image of your NFT. Magic NFT Valley operates
        as a fully decentralized platform, empowering creators with complete
        control over their NFT content. By opting to provide a URL to your NFT's
        image instead of hosting it directly on the platform, you retain full
        autonomy over your NFT.
      </Text>
    </AccordionPanel>
  );
};

export const WhatIsFeaturingItem = () => {
  return (
    <AccordionPanel pb={4}>
      Featuring an item involves showcasing it prominently. A featured item will
      stand out in two key ways:
      <OrderedList>
        <ListItem>
          A featured item will be positioned within the 3D scene at the top of
          the page, making it the first thing seen by a viewer.
        </ListItem>
        <ListItem>
          Additionally, a featured item will appear in the 'Spotlight' section,
          situated directly above the main content of the page.
        </ListItem>
      </OrderedList>
      Clicking on a featured item will redirect the viewer to its individual
      page.
    </AccordionPanel>
  );
};

// 'marketplace',
//   'auctionHouse',
//   'marketplaceGame',
//   'auctionHouseGame',
//   'gameNfts',
//   'gameList',

export const FeaturingNFTs = () => {
  return (
    <AccordionPanel pb={4}>
      NFTs can be featured on various pages to enhance visibility:
      <OrderedList>
        <ListItem>
          Marketplace: NFTs available for sale can be featured on the
          'Marketplace' page.
        </ListItem>
        <ListItem>
          Auction House: NFTs up for auction can be featured on the 'Auction
          House' page.
        </ListItem>
        <ListItem>
          Game-specific Marketplace: NFTs integrated into a game and available
          for sale can be featured on the 'Marketplace' page dedicated to that
          game.
        </ListItem>
        <ListItem>
          Game-specific Auction House: NFTs integrated into a game and up for
          auction can be featured on the 'Auction House' page dedicated to that
          game.
        </ListItem>
        <ListItem>
          Game NFTs: NFTs integrated into a game and available for sale or
          auction can be featured on the 'Game NFTs' page.
        </ListItem>
      </OrderedList>
      Featuring your NFT can significantly enhance its exposure to users within
      the NFT ecosystem.
    </AccordionPanel>
  );
};

export const FeaturingGame = () => {
  return (
    <AccordionPanel pb={4}>
      A game showcased can be featured on the 'Game Showcase' page. Highlighting
      your game in this manner can significantly enhance its exposure to
      potential players.
    </AccordionPanel>
  );
};

export const FeaturingNFTcontent = () => {
  return (
    <AccordionPanel pb={4}>
      NFTs are showcased in various formats, each with its own presentation:
      <OrderedList>
        <ListItem>
          Image: If your NFT is an image, it will be displayed on a board within
          the 3D scene at the top of the page, and also appear as a standard
          image in the 'Spotlight' section.
        </ListItem>
        <ListItem>
          Video: If your NFT is a video, it will play on a board within the 3D
          scene at the top of the page. The video and its controls will also be
          visible in the 'Spotlight' section. For 360-degree videos, viewers can
          rotate within the video.
        </ListItem>
        <ListItem>
          Music: If your NFT is a piece of music, the preview image will be
          displayed on a crystal ball within the 3D scene at the top of the
          page. Clicking on the crystal ball will play the music. The preview
          image and audio controls will also appear in the 'Spotlight' section.
        </ListItem>
        <ListItem>
          3D Model: If your NFT is a 3D model, it will be presented as such in
          the 3D scene at the top of the page, and also in the 'Spotlight'
          section.
        </ListItem>
        <ListItem>
          3D Environment Texture: If your NFT is a 3D environment texture, it
          will be displayed on a crystal ball in the 3D scene at the top of the
          page. It will appear as a 3D environment texture in the 'Spotlight'
          section, allowing users to rotate and zoom in/out.
        </ListItem>
        <ListItem>
          Other NFT Content: It is highly recommended to provide a preview image
          for any other NFT content. This preview image will be showcased on a
          board within the 3D scene at the top of the page, and also appear in
          the 'Spotlight' section.
        </ListItem>
      </OrderedList>
      Featuring your NFT can significantly increase its exposure to users within
      the NFT ecosystem. Clicking on a featured item will redirect the viewer to
      its individual page.
    </AccordionPanel>
  );
};

const CreatorOfTokensText = () => {
  <Text>
    If you've created an NFT but find yourself unable to add it to a collection,
    a couple of reasons could be at play: 1. Only the creator and owner of
    tokens can add them to a collection. Once a token you've created is sold,
    you lose the ability to make further alterations, except for removing
    royalties. 2. Alternatively, the token contract where your NFT was created
    might not be returning your account address through the `royaltyInfo()`
    function. `royaltyInfo()` is a standard function in OpenZeppelin contracts,
    designed to provide information about the creator's address and any
    associated royalties, if set, for the NFT they've created.
  </Text>;
};

export const CreatorOfTokens = () => {
  return (
    <AccordionPanel pb={4}>
      If you've created an NFT but find yourself unable to add it to a
      collection, a couple of reasons could be at play:
      <OrderedList>
        <ListItem>
          Only the creator and owner of tokens can add them to a collection.
          Once a token you've created is sold, you lose the ability to make
          further alterations, except for removing royalties.
        </ListItem>
        <ListItem>
          Alternatively, the token contract where your NFT was created might not
          be returning your account address through the `royaltyInfo()`
          function. `royaltyInfo()` is a standard function in OpenZeppelin
          contracts, designed to provide information about the creator's address
          and any associated royalties, if set, for the NFT they've created.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};
