import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { BatchListForSale, ImageUrl, ListForSale } from './MarketplaceFAQ';
import {
  FeaturingGame,
  FeaturingNFTcontent,
  FeaturingNFTs,
  HowToFeatureNFT,
  WhatIsFeaturingItem,
} from './FeatureItemFAQ';
const FeatureItemSection = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              1. What is featuring an item in Magic NFT Valley?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <WhatIsFeaturingItem />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              2. Which pages can my NFT be featured on?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <FeaturingNFTs />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              3. Which pages can my game be featured on?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <FeaturingGame />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              4. How long can my NFT/game remain featured?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          The duration of featuring depends on your preference. When you
          designate your NFT/game as featured, the shortest duration is 72 hours
          (equivalent to 3 days and nights as one time slot). You have the
          flexibility to register as many time slots as you wish, and they need
          not be continuous. Once the registered time slots for an NFT have
          elapsed, it will no longer appear in the featured scene/section and
          will only remain in the normal section. However, you can re-register
          an NFT/game as featured as many times as desired.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              5. How many featured items will appear on a page simultaneously?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          On any page featuring NFTs or games, the maximum number of featured
          items displayed simultaneously is limited to 3. This means that
          viewers will only see up to 3 items in the featured scene/section at
          any given time. This deliberate restriction ensures that each featured
          item receives ample prominence to capture viewers' attention
          effectively.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              6. Can I feature my NFT which is not an image?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <FeaturingNFTcontent />
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              7. How to feature my NFT?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <HowToFeatureNFT />
      </AccordionItem>
    </Accordion>
  );
};

export default FeatureItemSection;
