import { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  StackDivider,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import ArtistCollections from '../../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import { extractIntegersFromString } from '../../../utils/helpers';
import AddTokensToCollectionForm from '../../../formComponents/AddTokensToCollectionForm';
import RenderExistingCollectionForm from '../../../formComponents/RenderExistingCollectionForm';
import RenderModalButton from '../../../formComponents/RenderModalButton';
import RenderModalFooterButton from '../../../formComponents/RenderModalFooterButton';
import { REACT_APP_COLLECTIONS_ADDRESS, REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../../Constants/ContractAddress';
const UpdateTokensInCollection = ({
  useCase = '',
  tokenContract = '',
  tokenId = 0,
  updateType,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [tokenIds, setTokenIds] = useState('');
  const [singleContractAddr, setSingleContractAddr] = useState(
    REACT_APP_MAGIC_NFT_ERC721_ADDRESS
  );
  const [allCollections, setAllCollections] = useState([]);
  const [collectionNames, setCollectionNames] = useState([]);
  const [collectionIndex, setCollectionIndex] = useState(0);
  const [label, setLabel] = useState('');

  useEffect(() => {
    getLabel();
  }, []);

  const getLabel = () => {
    if (useCase == 'TokenByUser') {
      if (updateType === 'Remove') {
        setLabel('Remove from Existing Collection');
      } else {
        setLabel('Add to Existing Collection');
      }
    } else {
      if (updateType === 'Remove') {
        setLabel('Remove Token(s) from Collection');
      } else {
        setLabel('Add Token(s) to Collection');
      }
    }
  };

  const create = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const tokenCollectionContract = new ethers.Contract(
        REACT_APP_COLLECTIONS_ADDRESS,
        ArtistCollections.abi,
        signer
      );

      // console.log('tokenCollectionContract:', tokenCollectionContract.address);

      const _tokenIds =
        useCase == 'TokenByUser'
          ? [tokenId]
          : extractIntegersFromString(tokenIds);
      // console.log('_tokenIds:', _tokenIds);

      let _tokenContract =
        useCase == 'TokenByUser' ? tokenContract : singleContractAddr;

      // console.log('_tokenContract:', _tokenContract);

      let transactionList;

      if (updateType === 'Add') {
        transactionList = await tokenCollectionContract.addTokensToCollection(
          _tokenContract,
          _tokenIds,
          collectionIndex
        );
      } else if (updateType === 'Remove') {
        transactionList =
          await tokenCollectionContract.removeTokensFromCollection(
            _tokenContract,
            _tokenIds,
            collectionIndex
          );
      }

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <RenderModalButton label={label} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <RenderExistingCollectionForm
                  setCollectionIndex={setCollectionIndex}
                  setCollectionNames={setCollectionNames}
                  allCollections={allCollections}
                  setAllCollections={setAllCollections}
                />
              </Box>

              {useCase != 'TokenByUser' && (
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {label}
                  </Heading>
                  <AddTokensToCollectionForm
                    tokenIds={tokenIds}
                    setTokenIds={setTokenIds}
                    singleContractAddr={singleContractAddr}
                    setSingleContractAddr={setSingleContractAddr}
                  />
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <RenderModalFooterButton
              loading={loading}
              label={label}
              create={create}
              onClose={onClose}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateTokensInCollection;
