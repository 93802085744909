import React from 'react';
import SearchById from './SearchById';

const SearchCollection = () => {
  return (
    <SearchById
      useCase="collections"
      _placeholder="Collection Id"
      label="Search Collection"
    />
  );
};

export default SearchCollection;
