import { Box } from '@chakra-ui/react';
const TestnetReminder = () => {
  return (
    <Box
      boxShadow="2xl"
      p="3"
      rounded="md"
      mt="18px"
      color="white"
      style={{
        backgroundImage:
          'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
      }}
    >
      🔔 <strong>Polygon Amoy Testnet Environment</strong>: Please use testnet
      MATIC. Real MATIC will be lost.
    </Box>
  );
};

export default TestnetReminder;
