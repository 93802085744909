import React, { useEffect } from 'react';
import {
  Engine,
  Scene,
  ArcRotateCamera,
  Vector3,
  Color3,
  MeshBuilder,
  PointLight,
  HDRCubeTexture,
  StandardMaterial,
  PBRMaterial,
  Texture,
} from '@babylonjs/core';

import '../../styles/Fonts.css';
import { Box } from '@chakra-ui/react';

const HdrScene = ({ tokenUri, canvasW='100%', canvasH='100vh' }) => {
  useEffect(() => {
    // Create a Babylon.js engine
    const canvas = document.getElementById('babylon-canvas');
    const engine = new Engine(canvas);
    // Create a scene
    const scene = new Scene(engine);

    var camera = new ArcRotateCamera(
      'Camera',
      -Math.PI / 4,
      Math.PI / 2.5,
      200,
      Vector3.Zero(),
      scene
    );
    camera.attachControl(canvas, true);
    camera.minZ = 0.1;

    // Light
    new PointLight('point', new Vector3(20, 20, 10), scene);

    const hdrTexture = new HDRCubeTexture(tokenUri, scene, 512);

    // Skybox
    var skybox = MeshBuilder.CreateBox('SkyBox', { size: 1000.0 }, scene);
    var skyboxMaterial = new StandardMaterial('skyBox', scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = hdrTexture;
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skybox.material = skyboxMaterial;

    // Create meshes
    var sphereGlass = MeshBuilder.CreateSphere(
      'sphereGlass',
      { segments: 48, diameter: 50.0 },
      scene
    );

    // Create materials
    var glass = new PBRMaterial('glass', scene);
    glass.reflectionTexture = hdrTexture;
    glass.indexOfRefraction = 0.52;
    glass.alpha = 0.5;
    glass.directIntensity = 0.0;
    glass.environmentIntensity = 0.7;
    glass.cameraExposure = 0.66;
    glass.cameraContrast = 1.66;
    glass.microSurface = 1;
    glass.reflectivityColor = new Color3(0.2, 0.2, 0.2);
    glass.albedoColor = new Color3(0.95, 0.95, 0.95);
    sphereGlass.material = glass;
    // Start the engine rendering loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    return () => {
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <Box textAlign="center">
      <canvas
        id="babylon-canvas"
        style={{ width: canvasW, height: canvasH, touchAction: 'none' }}
      />
    </Box>
  );
};

export default HdrScene;
