import BuyNFTbase from './BuyNFTbase';

const BuyNFTerc721 = ({ itemId, itemPrice, gameIdsIntegrated }) => {
  return (
    <BuyNFTbase
      itemId={itemId}
      itemPrice={itemPrice}
      isErc721={true}
      gameIdsIntegrated={gameIdsIntegrated}
    />
  );
};

export default BuyNFTerc721;
