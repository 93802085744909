const getTokenURLandTokenType = async (tokenContract, tokenId) => {
    try {
      const tokenURI = await tokenContract.tokenURI(tokenId);
      return { cid: tokenURI, tokenType: 'Erc721' };
    } catch (error) {
      try {
        const uri = await tokenContract.uri(tokenId);
        return { cid: uri, tokenType: 'Erc1155' };
      } catch (fallbackError) {
        console.error('Failed to retrieve token URL for tokenId', tokenId);
        return { cid: null, tokenType: 'Unknown' };
      }
    }
  };

export default getTokenURLandTokenType