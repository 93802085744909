import React, { useEffect } from 'react';
import {
  Engine,
  Scene,
  CubeTexture,
  Mesh,
  Color3,
  PBRMaterial,
  ArcRotateCamera,
  Vector3,
} from '@babylonjs/core';

import '../../styles/Fonts.css';
import { Box } from '@chakra-ui/react';

const EnvTextureScene = ({ tokenUri }) => {
  useEffect(() => {
    // Create a Babylon.js engine
    const canvas = document.getElementById('babylon-canvas');
    const engine = new Engine(canvas);
    // Create a scene
    const scene = new Scene(engine);

    scene.clearColor = Color3.Black();

    var camera = new ArcRotateCamera(
      'camera',
      -Math.PI / 2.8,
      Math.PI / 2,
      10,
      new Vector3(0, 0, 0),
      scene
    );
    camera.attachControl(canvas, true);

    // scene.createDefaultSkybox(scene.environmentTexture);

    // Detach mouse wheel control from the camera
    camera.inputs.attached.mousewheel.detachControl(canvas);

    scene.environmentTexture = CubeTexture.CreateFromPrefilteredData(
      tokenUri,
      scene
    );

    var sphere = Mesh.CreateSphere('sphere', 128, 2.58, scene);

    var pbr = new PBRMaterial('pbr', scene);
    sphere.material = pbr;

    pbr.metallic = 0.0;
    pbr.roughness = 0;    
    
    pbr.subSurface.isRefractionEnabled = true;
    pbr.subSurface.indexOfRefraction = 1.8;

    var a = 0;
    scene.afterRender = () => {
        a += 0.01;
        pbr.subSurface.indexOfRefraction = 1.5 + Math.cos(a) * 0.5;
    };

    // scene.createDefaultCamera(true, true, true);

    scene.createDefaultSkybox(scene.environmentTexture);

    // scene.debugLayer.show({ showExplorer: false });
    // scene.debugLayer.select(pbr, 'SUBSURFACE');

    // Start the engine rendering loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    return () => {
      scene.dispose();
      engine.dispose();
    };
  }, []);

  return (
    <Box textAlign="center">
      <canvas
        id="babylon-canvas"
        style={{ width: '100%', height: '100vh', touchAction: 'none' }}
      />
    </Box>
  );
};

export default EnvTextureScene;
