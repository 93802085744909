import CreateNewCollectionAndAddTokens from '../../collections/CreateNewCollectionAndAddTokens';
const UserAddTokenToNewCollection = ({ tokenContract, tokenId }) => {
  return (
    <CreateNewCollectionAndAddTokens
      useCase="TokenByUser"
      tokenContract={tokenContract}
      tokenId={tokenId}
    />
  );
};

export default UserAddTokenToNewCollection;
