import {
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import BabylonViewer from '../../../../utils/BabylonViewer';
import { Player } from 'video-react';
import Video360 from '../../../../babylonScenes/Video360';
import DomeScene from '../../../../babylonScenes/DomeScene';
import HdrScene from '../../../../babylonScenes/HdrScene';
import EnvTextureScene from '../../../../babylonScenes/EnvTextureScene';
import SkyboxScene from '../../../../babylonScenes/SkyboxScene';

const RenderValleyItem = ({
  category,
  image,
  animation_url,
  enableZoom = true,
}) => {
  const RenderPreviewImage = () => {
    if (image.length > 0) {
      return (
        <Box mt="20px">
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    image
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box style={{ overflow: 'hidden' }}>
                  <img
                    src={image}
                    alt={image}
                    className={enableZoom ? 'hover-zoom' : ''}
                    style={{
                      width: '285px',
                      padding: '50px',
                      marginTop: '50px',
                    }}
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      );
    }
  };
  const stringToArray = str => {
    return str
      .split(',')
      .map(item => item.trim())
      .filter(item => item !== '');
  };
  if (category === 'Music') {
    return (
      <VStack>
        {/* <Box>
          <Text
            style={{ fontFamily: 'Lobster' }}
            fontSize="2xl"
            color="teal.500"
          >
            Music
          </Text>
        </Box> */}
        <img
          src={image}
          alt={image}
          className="hover-zoom"
          style={{ width: '300px' }}
        />
        <audio controls>
          <source src={animation_url} />
        </audio>
      </VStack>
    );
  } else if (category === '3DModel') {
    return (
      <Box>
        <BabylonViewer modelUrl={animation_url} />
        <RenderPreviewImage previewUrl={image} />
      </Box>
    );
  } else if (category === 'Video') {
    return (
      <>
        {' '}
        <Box mt="30px"></Box>
        <Player playsInline poster={image} src={animation_url} />
      </>
    );
  } else if (category === 'Video360') {
    return (
      <>
        <Video360 tokenUri={animation_url} />
        <RenderPreviewImage previewUrl={image} />
      </>
    );
  } else if (category === 'Equirectangular') {
    return (
      <>
        <DomeScene tokenUri={animation_url} />
        <RenderPreviewImage previewUrl={image} />
      </>
    );
  } else if (category === 'Env') {
    return (
      <>
        <EnvTextureScene tokenUri={animation_url} />
        <RenderPreviewImage previewUrl={image} />
      </>
    );
  } else if (category === 'Hdr') {
    return (
      <>
        <HdrScene tokenUri={animation_url} />
        <RenderPreviewImage previewUrl={image} />
      </>
    );
  } else if (category === 'Skybox') {
    const imageUrls = stringToArray(animation_url);
    return (
      <></>
      // <>
      //   {imageUrls.length == 6 && <SkyboxScene tokenUri={tokenUri} />}

      //   <Accordion allowToggle>
      //     <AccordionItem>
      //       <h2>
      //         <AccordionButton>
      //           <Box as="span" flex="1" textAlign="left">
      //             Individual Skybox Images
      //           </Box>
      //           <AccordionIcon />
      //         </AccordionButton>
      //       </h2>
      //       <AccordionPanel pb={4}>
      //         <Wrap>
      //           {imageUrls.length > 0 &&
      //             imageUrls.map((img, i) => (
      //               <WrapItem key={i}>
      //                 <Box style={{ overflow: 'hidden' }}>
      //                   <img
      //                     src={img}
      //                     alt={img}
      //                     className={enableZoom ? 'hover-zoom' : ''}
      //                     style={{
      //                       width: '285px',
      //                       padding: '50px',
      //                       marginTop: '50px',
      //                     }}
      //                   />
      //                 </Box>
      //               </WrapItem>
      //             ))}
      //         </Wrap>
      //       </AccordionPanel>
      //     </AccordionItem>
      //   </Accordion>

      //   {previewUrl.length > 0 && (
      //     <VStack mt="20px">
      //       <Text>Preview</Text>
      //       <Box style={{ overflow: 'hidden' }}>
      //         <img
      //           src={previewUrl}
      //           alt={previewUrl}
      //           className={enableZoom ? 'hover-zoom' : ''}
      //           style={{ width: '385px', padding: '50px', marginTop: '50px' }}
      //         />
      //       </Box>{' '}
      //     </VStack>
      //   )}
      // </>
    );
  } else {
    return (
      <Box style={{ overflow: 'hidden' }}>
        <img
          src={image}
          alt={image}
          className={enableZoom ? 'hover-zoom' : ''}
          style={{ width: '385px', padding: '50px', marginTop: '50px' }}
        />
      </Box>
    );
  }
};

export default RenderValleyItem;
