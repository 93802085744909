import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import ListIntegrateTokensBase from '../ListIntegrateTokensBase';

const SellAuctionNFT = ({ tokenType, useCase, tokenContract, tokenId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} colorScheme="green" variant="ghost">
        Sell / Auction
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ListIntegrateTokensBase
              tokenType={tokenType}
              specificGameTokenId={0}
              useCase={useCase}
              tokenContract={tokenContract}
              tokenId={tokenId}
              label="Sell / Auction "
            />
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SellAuctionNFT;
