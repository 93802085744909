import { useParams } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  VStack,
  HStack,
  Stack,
  Box,
  Text,
  Button,
  ButtonGroup,
  Link,
  Card,
  CardBody,
  CardFooter,
  StackDivider,
  List,
  ListItem,
  ListIcon,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { GiCutDiamond } from 'react-icons/gi';
import '../../../App.css';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import getGameTokenInfo from '../../../components/views/NFTs/elements/getGameTokenInfo';
import TokenContractForm from '../../../components/formComponents/TokenContractForm';
import TokenIdFormSingle from '../../../components/formComponents/TokenIdFormSingle';
import TokenGameTrialModal from '../../../components/views/NFTs/elements/TokenGameTrialModal';
import RenderGameTabs from '../../../components/views/NFTs/elements/RenderGameTabs';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import BackdropFilter from '../../../components/views/NFTs/elements/frames/dreamMintingStuio/BackdropFilter';
import Mirror from '../../../resources/images/Mirror/2.png';
import YouTubeVideo from '../../../components/utils/YoutubeVideo';
import { REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../../components/Constants/ContractAddress';

function GameTokenView() {
  const { gameTokenId } = useParams();
  // console.log('gameTokenId:', gameTokenId);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const initialContractAddr = REACT_APP_MAGIC_NFT_ERC721_ADDRESS;

  const [item, setItem] = useState({});

  const [singleContractAddr, setSingleContractAddr] =
    useState(initialContractAddr);
  const [tokenId, setTokenId] = useState(0);
  const [showTokenTrialInfo, setShowTokenTrialInfo] = useState(false);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const gameTokenInfo = await getGameTokenInfo(provider, gameTokenId);
    // console.log('gameTokenInfo:', gameTokenInfo);

    setItem(gameTokenInfo.newItem);
  };

  const handleShowTokenTrialInfo = () => {
    if (showTokenTrialInfo) {
      setShowTokenTrialInfo(false);
    } else {
      setShowTokenTrialInfo(true);
    }
  };

  const RenderTokenTrialButton = () => {
    if (!showTokenTrialInfo) {
      return (
        <Button
          colorScheme="green"
          variant="link"
          onClick={handleShowTokenTrialInfo}
        >
          Try NFT in This Game
        </Button>
      );
    }
  };

  const RenderCloseTokenTrialButton = () => {
    if (showTokenTrialInfo) {
      return (
        <Button variant="outline" onClick={handleShowTokenTrialInfo}>
          Close Token Trial
        </Button>
      );
    }
  };

  const AllowExternalIntegrationButton = () => {
    return (
      <HStack>
        <Icon as={GiCutDiamond} color="#e81cff" />
        <Text
          bgGradient="linear(to-r, #40c9ff, #e81cff)"
          bgClip="text"
          fontSize="xl"
          fontWeight="extrabold"
        >
          External Integration Allowed
        </Text>
        <Icon as={GiCutDiamond} color="#40c9ff" />
      </HStack>
    );
  };

  const AddGalleryButton = () => {
    const data = { gameTokenId, gameName: item.gameName };
    return (
      <>
        <Link as={ReactLink} to={`/gamelist/addgallery`} state={data}>
          <Button colorScheme="green" variant="link">
            Integrate tokens in This Game
          </Button>
        </Link>
      </>
    );
  };

  return (
    <Box>
      {Object.keys(item).length > 0 && (
        <VStack spacing={8}>
          <HomeIcon />
          <Box>
            <Text
              color="green.300"
              fontSize={[39, 58, 66]}
              style={{ fontFamily: 'Lobster' }}
            >
              {item.gameName}
            </Text>
          </Box>
          <YouTubeVideo url={item.videoUrl} />
          {item.gameFeaturesExternal.length > 0 && (
            <AllowExternalIntegrationButton />
          )}

          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="center">
                    <img src={Mirror} />
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <RenderGameTabs item={item} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <HStack spacing={8} mt="10px">
            <Box>
              <BackdropFilter
                title="Marketplace"
                gameTokenId={gameTokenId}
                gameName={item.gameName}
                gameVideoUrl={item.videoUrl}
                gamePosterUrl={item.posterUrl}
                isMarketplace={true}
              />
            </Box>
            <Box>
              <BackdropFilter
                title="Auction House"
                gameTokenId={gameTokenId}
                gameName={item.gameName}
                gameVideoUrl={item.videoUrl}
                isMarketplace={false}
              />
            </Box>
          </HStack>
          <VStack spacing={3} mt="20px">
            <List spacing={3}>
              <ListItem>
                <ListIcon as={GiCutDiamond} color="green.300" />
                <AddGalleryButton />
              </ListItem>
              <ListItem>
                <ListIcon as={GiCutDiamond} color="green.300" />
                <RenderTokenTrialButton />
              </ListItem>
            </List>
            {showTokenTrialInfo && (
              <Card boxShadow="2xl" p="6" rounded="md">
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <TokenContractForm
                        singleContractAddr={singleContractAddr}
                        setSingleContractAddr={setSingleContractAddr}
                        tokenType="Full"
                      />
                    </Box>
                    <Box>
                      <TokenIdFormSingle
                        tokenId={tokenId}
                        setTokenId={setTokenId}
                      />
                    </Box>
                  </Stack>
                </CardBody>
                <CardFooter>
                  <ButtonGroup spacing="2">
                    <TokenGameTrialModal
                      features={item.featureNames}
                      tokenContract={singleContractAddr}
                      tokenId={tokenId}
                      trialUrl={item.info.trialUrl}
                    />
                    <RenderCloseTokenTrialButton />
                  </ButtonGroup>
                </CardFooter>
              </Card>
            )}
          </VStack>
        </VStack>
      )}
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default GameTokenView;
