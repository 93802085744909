import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import AddGameBase from '../AddGameBase';
import RenderModalButton from '../../../formComponents/RenderModalButton';

const AddGameModal = ({ useStyle = '' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ShowModalButton = () => {
    if (useStyle == 'Fancy') {
      return (
        <Button
          p={4}
          size='lg'
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
          onClick={onOpen}
        >
         Add Game
        </Button>
      );
    } else {
      return <RenderModalButton label="Add Game" onClick={onOpen} />;
    }
  };
  return (
    <>
      <ShowModalButton />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Game</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddGameBase />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="link" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddGameModal;
