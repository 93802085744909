import React, { useState } from 'react';
import {
  Text,
  VStack,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  List,
  ListItem,
} from '@chakra-ui/react';
import '../../styles/DashedBorder.css';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
const CollectionJsonCreator = () => {
  const [nftData, setNftData] = useState({
    name: '',
    description: '',
    image: '',
    website: '',
    customFields: [], // Array to hold custom key-value pairs
  });

  const handleAddCustomField = () => {
    // Add an empty custom field object to customFields array
    setNftData({
      ...nftData,
      customFields: [...nftData.customFields, { key: '', value: '' }],
    });
  };

  const handleRemoveCustomField = indexToRemove => {
    // Remove custom field at specific index from customFields array
    const updatedCustomFields = [...nftData.customFields];
    updatedCustomFields.splice(indexToRemove, 1);
    setNftData({
      ...nftData,
      customFields: updatedCustomFields,
    });
  };

  const handleJsonSubmit = () => {
    // Convert nftData object to JSON string
    const jsonContent = JSON.stringify(nftData, null, 2);

    // Create blob with JSON content
    const blob = new Blob([jsonContent], { type: 'application/json' });

    // Create URL for blob
    const url = URL.createObjectURL(blob);

    // Create temporary <a> element to trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'myCollecctionMetadata.json';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <VStack>
      <HomeIcon />
      <Text
        color="green.400"
        fontSize={['lg', 'xl', '2xl', '3xl']}
        style={{ fontFamily: 'Lobster' }}
      >
        Collection meta Json Creator
      </Text>
      <Card>
        <CardBody>
          <Box mt={3}>
            <FormControl>
              <FormLabel>Name:</FormLabel>
              <Input
                type="text"
                value={nftData.name}
                onChange={e => setNftData({ ...nftData, name: e.target.value })}
              />
            </FormControl>
          </Box>
          <Box mt={3}>
            <FormControl>
              <FormLabel>Description:</FormLabel>
              <Textarea
                value={nftData.description}
                onChange={e =>
                  setNftData({ ...nftData, description: e.target.value })
                }
              />
            </FormControl>
          </Box>
          {/* <Box>
          <label>
            Description:
            <textarea
              value={nftData.description}
              onChange={e =>
                setNftData({ ...nftData, description: e.target.value })
              }
            />
          </label>
        </Box> */}
          <Box mt={3}>
            <FormControl>
              <FormLabel> Image URL:</FormLabel>
              <Input
                type="text"
                value={nftData.image}
                onChange={e =>
                  setNftData({ ...nftData, image: e.target.value })
                }
              />
            </FormControl>
          </Box>
          {/* <Box>
          <label>
            Image URL:
            <input
              type="text"
              value={nftData.image}
              onChange={e =>
                setNftData({ ...nftData, image: e.target.value })
              }
            />
          </label>
        </Box> */}
          <Box mt={3}>
            <FormControl>
              <FormLabel> Website:</FormLabel>
              <Input
                type="text"
                value={nftData.website}
                onChange={e =>
                  setNftData({ ...nftData, website: e.target.value })
                }
              />
            </FormControl>
          </Box>
          {/* <Box>
          <label>
            Animation URL:
            <input
              type="text"
              value={nftData.animation_url}
              onChange={e =>
                setNftData({ ...nftData, animation_url: e.target.value })
              }
            />
          </label>
        </Box> */}

          <Box mt={3}>
            <Text fontSize="xl" as="i">
              Custom Fields:
            </Text>
            <VStack>
              <Button onClick={handleAddCustomField}>Add Custom Field</Button>
              <List>
                {nftData.customFields.map((field, index) => (
                  <ListItem key={index}>
                    <Box className="dashed" mt={3} p={6}>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Key:</FormLabel>
                          <Input
                            type="text"
                            value={field.key}
                            placeholder="Key"
                            onChange={e => {
                              const updatedCustomFields = [
                                ...nftData.customFields,
                              ];
                              updatedCustomFields[index].key = e.target.value;
                              setNftData({
                                ...nftData,
                                customFields: updatedCustomFields,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel> Value:</FormLabel>
                          <Input
                            type="text"
                            value={field.value}
                            placeholder="Value"
                            onChange={e => {
                              const updatedCustomFields = [
                                ...nftData.customFields,
                              ];
                              updatedCustomFields[index].value = e.target.value;
                              setNftData({
                                ...nftData,
                                customFields: updatedCustomFields,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Button onClick={() => handleRemoveCustomField(index)}>
                        Remove
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>
        </CardBody>
        <CardFooter>
          <Box>
            <Button colorScheme="green" onClick={handleJsonSubmit}>
              Download Collection Metadata
            </Button>
          </Box>
        </CardFooter>
      </Card>
    </VStack>
  );
};

export default CollectionJsonCreator;
