import React from 'react';
import {
  Box,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const TokenMetaUrlForm = ({
  index,
  token,
  metaUrl,
  setMetaUrl,
  isMagicflowers = false,
}) => {
  // console.log('meta:', meta);

  const handleMeta = (index, value) => {
    console.log('metaUrl:', metaUrl)
    const updatedTokens = [...metaUrl];
    updatedTokens[index] = value;
    setMetaUrl(updatedTokens);
  };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        {!isMagicflowers && (
          <>
            <Box style={{ marginBottom: '20px' }}>
              <FormControl isRequired>
                <FormLabel as="legend">
                  Url to NFT meta(including image):
                </FormLabel>

                <Input
                  name="metaUrl"
                  value={metaUrl[index]}
                  onChange={e => handleMeta(index, e.target.value)}
                  placeholder="Meta Url"
                  size="xl"
                />
              </FormControl>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TokenMetaUrlForm;
