const getHelperTokenInfo = async (helper, tokenContractAddress, tokenId) => {
  try {
    const tokenInfo = await helper.getItemByTokenId(
      tokenContractAddress,
      tokenId
    );
    return tokenInfo;
  } catch (error) {
    return {};
  }
};

export default getHelperTokenInfo;
