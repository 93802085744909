// Daniel Shiffman
// http://codingtra.in
// Steering Text Paths
// Video: https://www.youtube.com/watch?v=4hA7G3gup-4

export default function Vehicle(p5, x, y, _color='#ffffff') {
    this.p5 = p5
    this.pos = this.p5.createVector(this.p5.random(this.p5.width), this.p5.random(this.p5.height));
    this.target = this.p5.createVector(x, y);
    this.vel = this.p5.constructor.Vector.random2D();
    this.acc = this.p5.createVector();
    this.r = 3;
    this.maxspeed = 10;
    this.maxforce = 1;
    this.color = _color
  }
  
  Vehicle.prototype.behaviors = function() {
    var arrive = this.arrive(this.target);
    var mouse = this.p5.createVector(this.p5.mouseX, this.p5.mouseY);
    var flee = this.flee(mouse);
  
    arrive.mult(1);
    flee.mult(5);
  
    this.applyForce(arrive);
    this.applyForce(flee);
  };
  
  Vehicle.prototype.applyForce = function(f) {
    this.acc.add(f);
  };
  
  Vehicle.prototype.update = function() {
    this.pos.add(this.vel);
    this.vel.add(this.acc);
    this.acc.mult(0);
  };
  
  Vehicle.prototype.show = function() {
    this.p5.stroke(this.color);
    this.p5.strokeWeight(this.r);
    this.p5.point(this.pos.x, this.pos.y);
  };
  
  Vehicle.prototype.arrive = function(target) {
    var desired = this.p5.constructor.Vector.sub(target, this.pos);
    var d = desired.mag();
    var speed = this.maxspeed;
    if (d < 100) {
      speed = this.p5.map(d, 0, 100, 0, this.maxspeed);
    }
    desired.setMag(speed);
    var steer = this.p5.constructor.Vector.sub(desired, this.vel);
    steer.limit(this.maxforce);
    return steer;
  };
  
  Vehicle.prototype.flee = function(target) {
    var desired = this.p5.constructor.Vector.sub(target, this.pos);
    var d = desired.mag();
    if (d < 50) {
      desired.setMag(this.maxspeed);
      desired.mult(-1);
      var steer = this.p5.constructor.Vector.sub(desired, this.vel);
      steer.limit(this.maxforce);
      return steer;
    } else {
      return this.p5.createVector(0, 0);
    }
  };