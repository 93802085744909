import {
  Box,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import getAllCollectionsBy from '../views/NFTs/elements/getAllCollectionsBy';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';

const RenderExistingCollectionForm = ({
  setCollectionIndex,
  allCollections,
  setAllCollections,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
 
  useEffect(() => {
    getAllCollections();
  }, []);

  const handleCollectionIndex = event => setCollectionIndex(event.target.value);

  const getAllCollections = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
   
    const _allCollections = await getAllCollectionsBy(provider, address, false)
    setAllCollections(_allCollections);
  };

  return (
    <Box>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Select Collection:</FormLabel>
          <Select
            placeholder="Select collection"
            onChange={handleCollectionIndex}
          >
            {allCollections.length &&
              allCollections.map((c, i) => (
                <option value={c.collectionId}>{c.collectionName}</option>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default RenderExistingCollectionForm;
