import { Text, VStack, Link, Icon, Tooltip, Box } from '@chakra-ui/react';
import { BiDoorOpen } from 'react-icons/bi';
import { Link as ReactLink } from 'react-router-dom';
import HeartFrame1 from '../../../../resources/images/diamond-heart.png';
import WoodBoard2 from '../../../../resources/images/frames/wood-board-2.png';
import WoodBoard3 from '../../../../resources/images/frames/wood-board-3.png';
import WoodBoard1 from '../../../../resources/images/frames/wood-board-1.png';
import Blackboard1 from '../../../../resources/images/frames/blackboard1.png';
import WoodBoard7 from '../../../../resources/images/frames/wood-board-7.png';
import FlowerFrame1 from '../../../../resources/images/frames/flowerframe28.png';
import Paper1 from '../../../../resources/images/frames/scroll-paper.png';

export const TextOnImage1 = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={HeartFrame1} alt="heart frame" style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text color="green.300" fontSize="xl" style={{ fontFamily: 'Lobster' }}>
          {props.t}
        </Text>
      </div>
    </div>
  );
};

export const TextOnWoodFrame2 = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={WoodBoard2} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '74%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text
          color="green.300"
          fontSize="5xl"
          style={{ fontFamily: 'Lobster' }}
        >
          {props.t}
        </Text>
      </div>
    </div>
  );
};

export const TextOnWoodFrame = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={WoodBoard1} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '68%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text color="teal.500" fontSize="4xl" style={{ fontFamily: 'Lobster' }}>
          {props.t}
        </Text>
      </div>
    </div>
  );
};

export const TextOnWoodFrame5 = ({ t }) => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={WoodBoard7} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '68%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text
          color="green.500"
          fontSize={['sm', 'md', 'lg', 'xl']}
          fontWeight="bold"
          style={{ fontFamily: 'Lobster' }}
        >
          {t}
        </Text>
      </div>
    </div>
  );
};

export const TextOnPicture2 = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={FlowerFrame1} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <VStack spacing={5}>
          <Text fontSize={['md', 'lg', 'xl']} color="teal.500">
            {props.t}
          </Text>
          <Link as={ReactLink} to={props.linkUrl}>
            <Tooltip label="Enter" aria-label="A tooltip" placement="top">
              <Box>
                {' '}
                <Icon as={BiDoorOpen} w={9} h={9} color="green.300" />
              </Box>
            </Tooltip>
          </Link>
        </VStack>
      </div>
    </div>
  );
};

export const TextOnPaper = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={Paper1} style={{ width: '100%' }} />

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text color="teal.500" fontSize="4xl" style={{ fontFamily: 'Lobster' }}>
          {props.t}
        </Text>
      </div>
    </div>
  );
};

export const TextOnBlackboard1 = props => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={Blackboard1} alt="blackboard" style={{ width: '385px' }} />

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Text color="white.500" fontSize="xl" style={{ fontFamily: 'Lobster' }}>
          {props.t}
        </Text>
      </div>
    </div>
  );
};

