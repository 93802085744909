import {
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ExistingGameProperty = ({ pContent, pLabel }) => {
  return (
    <Box mb="20px">
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Existing {pLabel}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box mt="5px" mb="5px">
              <CopyToClipboard text={pContent}>
                <Button size="sm" colorScheme="green">
                  Copy
                </Button>
              </CopyToClipboard>
            </Box>
            {pContent}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ExistingGameProperty;
