import ITokenURI from '../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import { ethers } from 'ethers';
const getTokenContract = (_tokenAddr, provider) => {
  const tokenContract = new ethers.Contract(
    _tokenAddr,
    ITokenURI.abi,
    provider
  );

  return tokenContract;
};

export default getTokenContract;
