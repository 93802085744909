import {
  Vector3,
  MeshBuilder,
  Color4,
  Texture,
  Effect,
  GPUParticleSystem,
  ParticleSystem,
} from '@babylonjs/core';

const createEmitter1 = (engine, scene, slow=false) => {
  // Emitters
  // var emitter0 = Mesh.CreateBox('emitter0', 0.1, scene);
  const _emitRate = slow ? 30 : 100
  const _maxEmitPower = slow ? 1.8 : 3.0
  const _maxLifeTime = slow ? 8.0 : 5.0
  const _numParticles = 1588//slow ? 1880: 2580//slow ? 1000 : 4000
  var emitter0 = MeshBuilder.CreateBox('emitter0', { size: 0.1 }, scene);

  emitter0.isVisible = false;
  emitter0.position.y = 0;

  // Custom shader for particles
  Effect.ShadersStore['myParticleFragmentShader'] =
    '#ifdef GL_ES\n' +
    'precision highp float;\n' +
    '#endif\n' +
    'varying vec2 vUV;\n' + // Provided by babylon.js
    'varying vec4 vColor;\n' + // Provided by babylon.js
    'uniform sampler2D diffuseSampler;\n' + // Provided by babylon.js
    'uniform float time;\n' + // This one is custom so we need to declare it to the effect
    'void main(void) {\n' +
    'vec2 position = vUV;\n' +
    'float color = 0.0;\n' +
    'vec2 center = vec2(0.5, 0.5);\n' +
    'color = sin(distance(position, center) * 10.0+ time * vColor.g);\n' +
    'vec4 baseColor = texture2D(diffuseSampler, vUV);\n' +
    'gl_FragColor = baseColor * vColor * vec4( vec3(color, color, color), 1.0 );\n' +
    '}\n' +
    '';

  // Particles
  var particleSystem = new GPUParticleSystem('particles', _numParticles, scene, effect);
  particleSystem.particleTexture = new Texture('textures/flare.png', scene);
  particleSystem.minSize = 0.1;
  particleSystem.maxSize = 1.0;
  particleSystem.minLifeTime = 0.5;
  particleSystem.maxLifeTime = _maxLifeTime//5.0;
  particleSystem.minEmitPower = 0.5;
  particleSystem.maxEmitPower = _maxEmitPower//3.0;
  particleSystem.emitter = emitter0;
  particleSystem.emitRate = _emitRate//100;
  particleSystem.blendMode = ParticleSystem.BLENDMODE_ONEONE;
  particleSystem.direction1 = new Vector3(-1, 1, -1);
  particleSystem.direction2 = new Vector3(1, 1, 1);
  particleSystem.color1 = new Color4(1, 1, 0, 1);
  particleSystem.color2 = new Color4(1, 0.5, 0, 1);
  particleSystem.gravity = new Vector3(0, -1.0, 0);

  // Effect
  var effect = engine.createEffectForParticles(
    'myParticle',
    ['time'],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    particleSystem
  );
  particleSystem.setCustomEffect(effect, 0);
  particleSystem.start();

  var time = 0;
  var order = 0.1;

  effect.onBind = function () {
    effect.setFloat('time', time);
    time += order;

    if (time > 100 || time < 0) {
      order *= -1;
    }
  };
};

export default createEmitter1;
