import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

const AddModeForm = ({
  addMode,
  setAddMode,
  label,
}) => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">{label}</FormLabel>
          <RadioGroup
            onChange={setAddMode}
            value={addMode}
            colorScheme="green"
          >
            <Stack direction="row">
              <Radio value="1">No</Radio>
              <Radio value="2">Yes</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default AddModeForm;
