import { ethers } from 'ethers';
import ITokenGeneral from '../../contracts/APIs/TokenInterface.sol/TokenInterface.json';

const isTokenOwner = async (provider, tokenId, tokenContractAddr, user) => {
  let tokenContract = new ethers.Contract(
    tokenContractAddr,
    ITokenGeneral.abi,
    provider
  );

  try {
    const owner = await tokenContract.ownerOf(tokenId);
    // console.log('owner:', owner);
    return owner === user;
  } catch (ownerError) {
    try {
      const balance = await tokenContract.balanceOf(user, tokenId);
      // console.log('balance:', balance);
      return balance > 0;
    } catch (balanceError) {
      console.error('Failed to check ownership:', balanceError);
      return false;
    }
  }
};

export default isTokenOwner;
