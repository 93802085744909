import { useState } from 'react';
import { VStack, Box, Text, HStack } from '@chakra-ui/react';
import StickyNote2 from '../../../../../../resources/images/frames/stickyNotes/stickyNote3.png';

function RenderAttributes({ attributes }) {
  const [showAttributes, setShowAttributes] = useState(false);
  const handleShowAttributes = () => {
    if (showAttributes) {
      setShowAttributes(false);
    } else {
      setShowAttributes(true);
    }
  };

  const RenderAttributesButton = () => {
    const _title = showAttributes ? 'Hide Attributes' : 'Attributes';
    return (
      <Box
        onClick={handleShowAttributes}
        as="button"
        style={{ position: 'relative', textAlign: 'center', color: 'white' }}
      >
        <img src={StickyNote2} alt="heart frame" style={{ width: '100%' }} />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Text
            color="green.500"
            fontSize="xl"
            style={{ fontFamily: 'Lobster' }}
          >
            {_title}
          </Text>
        </div>
      </Box>
    );
  };

  const RenderValleyItemAttributes = ({ attributes }) => {
    if (attributes) {
      return (
        <Box style={{ border: '5px dotted green', borderRadius: '5px' }}>
          <Box textAlign="center">
            <Text>NFT attributes</Text>
          </Box>
          {attributes.map((a, key) => (
            <Box key={key} maxW="sm">
              <HStack>
                <Box>Name: {a.trait_type} </Box>
                <Box>Value: {a.value}</Box>
              </HStack>
            </Box>
          ))}
        </Box>
      );
    }
  };

  return (
    <VStack>
      <RenderAttributesButton />
      {showAttributes && <RenderValleyItemAttributes attributes={attributes} />}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default RenderAttributes;
