import React, { useState } from 'react';
import {
  Container,
  Text,
  VStack,
  Box,
  Icon,
  Link,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import { AiTwotoneMail } from 'react-icons/ai';
import RenderAboutValley from '../../components/views/aboutPage/RenderAboutValley';
import AboutValley from '../../resources/images/valley/about-valley.gif';
import AboutTimeline from '../../resources/images/valley/about-timeline.gif';
import AboutContact from '../../resources/images/valley/about-contact.gif';
import ContactCard from '../../resources/images/valley/contact-card.png';
import RenderProjectTimeline from '../../components/views/aboutPage/RenderProjectTimeline';
import Campaign from '../../components/views/misc/Campaign';
function About() {
  const [activeBox, setActiveBox] = useState('valley');

  const handleBoxClick = box => {
    setActiveBox(box);
  };

  const TextOnImage = () => {
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          color: 'black',
          marginTop: '30px',
        }}
      >
        <img src={ContactCard} alt="heart frame" style={{ width: '100%' }} />

        <div
          style={{
            position: 'absolute',
            top: '83%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Link href="mailto:support@magicnftvalley.com">
            <Icon as={AiTwotoneMail} boxSize="88px" color="green.300" />
          </Link>
          <Text>support@magicnftvalley.com</Text>
        </div>
      </div>
    );
  };

  const RenderContact = () => {
    return <TextOnImage />;
  };
  const BoxContent = () => {
    switch (activeBox) {
      case 'valley':
        return <RenderAboutValley />;
      // case 'flower':
      //   return <RenderAboutMagicFlowers />;
      case 'timeline':
        return <RenderProjectTimeline />;
      case 'contact':
        return <RenderContact />;
      default:
        return <RenderAboutValley />;
    }
  };
  return (
    <VStack>
      <HomeIcon />
      {/* <Box boxSize="md">
        <Image
          src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718649600/about_xko9x7.png"
          alt="about"
        />
      </Box> */}
      <Box>
        <Text
          color="green.400"
          fontSize={[33, 47, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          About
        </Text>
      </Box>
      <Wrap spacing={8}>
        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('valley')}>
            <img src={AboutValley} alt="Magic NFT Valley" />
          </Box>
        </WrapItem>
        {/* <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('flower')}>
            <img src={AboutFlower} alt="Magic Flowers" />
          </Box>
        </WrapItem> */}
        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('timeline')}>
            <img src={AboutTimeline} alt="Project Timeline" />
          </Box>
        </WrapItem>
        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('contact')}>
            <img src={AboutContact} alt="Contact US" />
          </Box>
        </WrapItem>
      </Wrap>
      <Container>
        <VStack spacing={6}>
          {/* <RenderTabs /> */}
          <BoxContent />
        </VStack>
      </Container>
      <Box mt="18px">
        <Campaign isDetailed={false} />
      </Box>
      {/* <img
        src={CampaignImage}
        alt="Join Our Indiegogo Campaign"
        style={{ width: '358px', height: 'auto' }}
      /> */}
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
}

export default About;
