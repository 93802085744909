import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  Box,
  VStack,
  Text,
  Container,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { MdGamepad } from 'react-icons/md';
import { GiBeveledStar } from 'react-icons/gi';
import { Player } from 'video-react';
import DetailButton from '../elements/DetailButton';
import ReactAudioPlayer from 'react-audio-player';
import { stringToArray } from '../../../utils/helpers';

const GallerySpotLight = ({ featurePage, featuredItems }) => {
  const getToLink = (featurePage, featuredItems) => {
    let toLink;
    if (featurePage === 'gameList') {
      toLink = `/gameList/${featuredItems.tokenId}`;
    } else {
      toLink = `/tokens/${featuredItems.tokenContract}/${featuredItems.tokenId}`;
    }
    return toLink;
  };
  const RenderImage = ({ item }) => {
    if (featurePage == 'gameList') {
      return (
        <>
          <Box>
            <Player playsInline poster={item.posterUrl} src={item.videoUrl} />
          </Box>

          <Box
            textAlign="center"
            alignItems="baseline"
            style={{ marginTop: '15px' }}
          >
            <Text style={{ fontFamily: 'Lobster' }} fontSize="2xl">
              {item.gameName}
            </Text>
          </Box>
        </>
      );
    } else if (
      featuredItems.category === 'Hdr' ||
      featuredItems.category === 'Music' ||
      featuredItems.category === 'Skybox'
    ) {
      // return (
      //   <Container>
      //     <HdrScene tokenUri={featuredItems.tokenUri} canvasW='400px' canvasH='400px'/>
      //   </Container>
      // );
      let imageUrl = item.metadata.image;
      if (featuredItems.category === 'Skybox') {
        const _imgArray = stringToArray(imageUrl);
        imageUrl = _imgArray[0];
      }
      return (
        <VStack>
          <img
            src={imageUrl}
            alt={imageUrl}
            className="hover-zoom"
            style={{ width: '300px' }}
          />
          {item.category === 'Music' && (
            <Box>
              <ReactAudioPlayer
                src={featuredItems.metadata.animation_url}
                controlsList={'nodownload'}
                controls
              />
            </Box>
          )}
        </VStack>
      );
    } else if (item.category === 'Video' || item.category === 'Video360') {
      return (
        <>
          <Player playsInline poster={item.previewUrl} src={item.tokenUri} />
        </>
      );
    } else {
      return (
        <img
          src={item.metadata.image}
          alt={item.metadata.image}
          className="hover-zoom"
          style={{ width: '300px' }}
        />
      );
    }
  };

  const RenderIconDivider = () => {
    const _icon = featurePage === 'gameList' ? MdGamepad : GiBeveledStar;
    return (
      <Box mt="10px">
        <HStack spacing={6}>
          <Icon as={_icon} w={8} h={8} color="green.500" />

          <Icon as={_icon} w={8} h={8} color="green.500" />
          <Icon as={_icon} w={8} h={8} color="green.500" />
        </HStack>
      </Box>
    );
  };

  const RenderFeaturedItems = () => {
    if (Object.keys(featuredItems).length > 0) {
      return (
        <VStack>
          <Text
            color="teal.500"
            fontSize={[24, 36, 42]}
            style={{ fontFamily: 'Lobster' }}
          >
            Today's Spotlight
          </Text>

          <RenderIconDivider />

          <Box
            maxW="lg"
            style={{
              display: 'grid',
              minHeight: '200px',
              border: '8px solid',
              padding: '1rem',
              borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
              marginTop: '20px',
            }}
          >
            <RenderImage item={featuredItems} />
            <Box
              textAlign="center"
              alignItems="baseline"
              style={{ marginTop: '15px' }}
            >
              <Text style={{ fontFamily: 'Lobster' }} fontSize="3xl">
                {featuredItems.metadata.name}
              </Text>
            </Box>
            <Box
              textAlign="center"
              alignItems="baseline"
              style={{ marginTop: '15px' }}
            >
              <Text fontSize="lg" as='i'>
                {featuredItems.category}
              </Text>
            </Box>
            <Box mt={3}>
              <Link as={ReactLink} to={getToLink(featurePage, featuredItems)}>
                <DetailButton label="" />
              </Link>
            </Box>
          </Box>
          <RenderIconDivider />
        </VStack>
      );
    }
  };

  return <RenderFeaturedItems />;
};

export default GallerySpotLight;
