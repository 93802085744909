import { ethers } from 'ethers';

export const FeaturingPages = [
  'marketplace',
  'auctionHouse',
  'marketplaceGame',
  'auctionHouseGame',
  'gameNfts',
  'gameList',
];

const sketchLabel = {
  marketplace: 'Marketplace',
  auctionHouse: 'Auction House',
  marketplaceGame: 'Marketplace - Game',
  auctionHouseGame: 'Auction House - Game',
  gameNfts: 'Game NFTs',
  gameList: 'Game Showcase',
};

export const getSketchLabel = featurePage => {
  return sketchLabel[featurePage];
};

const featuringFeeMarketplace = ethers.utils.parseEther('10'); // 10 matic
const featuringFeeAuctionHouse = ethers.utils.parseEther('10'); // 10 matic
// const featuringFeeExhibition = ethers.utils.parseEther('100'); // 100 matic
const featuringFeeGameNFTs = ethers.utils.parseEther('30'); // 30 matic
const featuringFeeGameList = ethers.utils.parseEther('100'); // 100 matic

export const FeaturingFees = [
  featuringFeeMarketplace,
  featuringFeeAuctionHouse,
  featuringFeeMarketplace,
  featuringFeeAuctionHouse,
  // featuringFeeExhibition,
  featuringFeeGameNFTs,
  featuringFeeGameList,
];

// export const FeaturingPages = [
//   'marketplace',
//   'auctions',
//   'exhibitions',
//   'gamenfts',
//   'gamelist',
// ];

// const featuringFeeMarketplace = ethers.utils.parseEther('10'); // 10 matic
// const featuringFeeAuctionHouse = ethers.utils.parseEther('10'); // 10 matic
// const featuringFeeExhibition = ethers.utils.parseEther('100'); // 100 matic
// const featuringFeeGameNFTs = ethers.utils.parseEther('30'); // 30 matic
// const featuringFeeGameList = ethers.utils.parseEther('100'); // 100 matic

// export const FeaturingFees = [
//   featuringFeeMarketplace,
//   featuringFeeAuctionHouse,
//   featuringFeeExhibition,
//   featuringFeeGameNFTs,
//   featuringFeeGameList,
// ];
