import {
  Hide,
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

const CollectionModeForm = ({ collectionMode, setCollectionMode, useCase }) => {
  const RenderRadio1 = props => {
    if (props.useCase === 'user') {
      return (
        <Hide>
          <Radio value="1">No Collection</Radio>
        </Hide>
      );
    } else {
      return <Radio value="1">No Collection</Radio>;
    }
  };
  return (
    <Box style={{ marginBottom: '20px' }}>
      <FormControl>
        <FormLabel as="legend">Collection</FormLabel>
        <RadioGroup
          onChange={setCollectionMode}
          value={collectionMode}
          colorScheme="green"
        >
          <Stack direction="row">
            <RenderRadio1 useCase={useCase} />
            <Radio value="2">Add to My Existing Collections</Radio>
            <Radio value="3">Add to a New Collections</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CollectionModeForm;
