import ListIntegrateTokensBase from '../../../components/actions/NFTs/ListIntegrateTokensBase';
const ListErc721 = () => {
  return (
    <>
      <ListIntegrateTokensBase
        tokenType="Erc721"
        isSingleItem={false}
        specificGameTokenId={0}
        label='List/Auction '
      />
    </>
  );
};

export default ListErc721;
