import ValleyItemListBase from './ValleyItemListBase';

const ValleyItemListByUser = ({
  provider,
  saleType,
  tokenType,
  marketplaceIds,
  auctionIds,
  userAddr,
}) => {
  return (
    <ValleyItemListBase
      provider={provider}
      saleType={saleType}
      tokenType={tokenType}
      marketplaceIds={marketplaceIds}
      auctionIds={auctionIds}
      byUser={true}
      forExhibition={true}
      userAddr={userAddr}
      forGame={false}
    />
  );
};

export default ValleyItemListByUser;
