import { PBRMaterial, Color3, StandardMaterial, Matrix, CubeTexture, Texture } from '@babylonjs/core';

export const createPBR1 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.metallic = 1.0;
  pbr.roughness = 0.0;

  pbr.anisotropy.isEnabled = true;
  pbr.anisotropy.intensity = 0.7;

  var a = 0;
  scene.afterRender = () => {
    a += 0.01;
    pbr.anisotropy.direction.x = Math.cos(a);
    pbr.anisotropy.direction.y = Math.sin(a);
  };
  return pbr;
};

export const createPBR2 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.albedoColor = new Color3(0.1, 0.1, 0.1);
  pbr.metallic = 1.0;
  pbr.roughness = 0.0;

  pbr.iridescence.isEnabled = true;
  return pbr;
};

export const createPBR3 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.metallic = 0.0;
  pbr.roughness = 0;

  pbr.subSurface.isRefractionEnabled = true;
  pbr.subSurface.indexOfRefraction = 1.8;

  var a = 0;
  scene.afterRender = () => {
    a += 0.01;
    pbr.subSurface.indexOfRefraction = 1.5 + Math.cos(a) * 0.5;
  };
  return pbr;
};

export const createPBR4 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.metallic = 0.0;
  pbr.roughness = 0;

  pbr.subSurface.isRefractionEnabled = true;
  pbr.subSurface.indexOfRefraction = 1.5;
  pbr.subSurface.tintColor = new Color3(0, 0, 0);

  var a = 0;
  scene.beforeRender = () => {
    a += 0.01;
    pbr.subSurface.tintColor.g = Math.cos(a) * 0.5 + 0.5;
    pbr.subSurface.tintColor.b = pbr.subSurface.tintColor.g;
  };
  return pbr;
};

export const createPBR5 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.metallic = 0.0;
  pbr.roughness = 0;

  pbr.subSurface.isRefractionEnabled = true;
  pbr.subSurface.indexOfRefraction = 1.8;

  var a = 0;
  scene.afterRender = () => {
    a += 0.01;
    pbr.subSurface.indexOfRefraction = 1.5 + Math.cos(a) * 0.5;
  };

  return pbr;
};

export const createPBR6 = scene => {
  var pbr = new PBRMaterial('pbr', scene);

  pbr.metallic = 0.0;
  pbr.roughness = 0;

  pbr.subSurface.isRefractionEnabled = true;

  return pbr;
};

export const createPBR7 = scene => {
  var pbr = new PBRMaterial('pbr', scene);
  pbr.metallic = 1.0;
  pbr.roughness = 0.0;

  pbr.anisotropy.isEnabled = true;

  var a = 0;
  scene.afterRender = () => {
    a += 0.01;
    pbr.anisotropy.intensity = Math.cos(a) * 0.5 + 0.5;
  };

  return pbr;
};

export const createPBR8 = scene => {
  var pbr = new PBRMaterial('pbr', scene);
  pbr.metallic = 1.0;
  pbr.roughness = 0.0;

  pbr.anisotropy.isEnabled = true;
  pbr.anisotropy.intensity = 0.7;

  var a = 0;
  scene.afterRender = () => {
    a += 0.01;
    pbr.anisotropy.direction.x = Math.cos(a);
    pbr.anisotropy.direction.y = Math.sin(a);
  };

  return pbr;
};

export const createHdrTexture = (scene, hdrTexture) => {
  // Create materials
  var glass = new PBRMaterial('glass', scene);
  glass.reflectionTexture = hdrTexture;
  glass.indexOfRefraction = 0.52;
  glass.alpha = 0.5;
  glass.directIntensity = 0.0;
  glass.environmentIntensity = 0.7;
  glass.cameraExposure = 0.66;
  glass.cameraContrast = 1.66;
  glass.microSurface = 1;
  glass.reflectivityColor = new Color3(0.2, 0.2, 0.2);
  glass.albedoColor = new Color3(0.95, 0.95, 0.95);

  return glass;
};

export const createSkyboxTexture = (scene, imageArray) => {
  var shapeMaterial = new StandardMaterial('mat', scene);
  shapeMaterial.backFaceCulling = true;
  shapeMaterial.reflectionTexture = new CubeTexture(
    '',
    scene,
    undefined,
    undefined,
    imageArray //[px, py, pz, nx, ny, nz]
  );
  shapeMaterial.reflectionTexture.coordinatesMode = Texture.PLANAR_MODE;
  shapeMaterial.diffuseColor = new Color3(0, 0, 0);
  shapeMaterial.specularColor = new Color3(0, 0, 0);

  var theta = 0;
  scene.registerBeforeRender(function () {
    Matrix.RotationYToRef(
      theta,
      shapeMaterial.reflectionTexture.getReflectionTextureMatrix()
    );
    theta += 0.015;
  });
  return shapeMaterial;
};
