import {
  Scene,
  PointLight,
  HemisphericLight,
  ArcRotateCamera,
  Vector3,
  Color3,
  CubeTexture,
} from '@babylonjs/core';
import '../../../../styles/Fonts.css';
import createEmitter1 from '../../emitters/createEmitter1';
import createSphere from '../../spheres/createSphere';
import createTexts from '../../createTexts/createTexts';

const createHomeScene = (canvas, engine, randomNum) => {
  const domeImages = [
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574468/artgallery17_vfelfs.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574505/artgallery15_vmhaa7.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574534/artgallery8_pmech7.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574559/artgallery6_jkyfph.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574580/artgallery5_fcapvu.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574614/greenroom2_gp1qo7.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574639/greenroom_pd22gl.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574675/valley_day_9_lhputr.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574334/valley_day_7_rkfpza.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574704/valley_night10_udtg5d.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574725/valley_night_0_hj37nc.env',
    'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574746/valley_night_1_fpdqrx.env',
  ];

  const randomInt = Math.floor(Math.random() * domeImages.length);
  const valley = 'https://res.cloudinary.com/df9jvvwyw/raw/upload/v1718574334/valley_day_7_rkfpza.env'// domeImages[8]; //domeImages[randomInt];

  // Create a scene
  const scene = new Scene(engine);

  scene.clearColor = Color3.Black();

  var camera = new ArcRotateCamera(
    'Camera',
    -Math.PI / 2.8,
    Math.PI / 2,
    10,
    Vector3.Zero(),
    scene
  );

  camera.attachControl(canvas, true);
  // camera.inputs.attached.mousewheel.detachControl(canvas);

  // Light
  var light = new HemisphericLight('light1', new Vector3(0, 1, 0), scene);
  light.intensity = 0.3;

  var pl = new PointLight('pl', Vector3.Zero(), scene);
  pl.diffuse = new Color3(1, 1, 1);
  pl.specular = new Color3(1, 1, 1);
  pl.intensity = 0.8;
  // var light = new PointLight('point', new Vector3(0, 85, 0), scene);
  light.intensity = 20;

  scene.environmentTexture = CubeTexture.CreateFromPrefilteredData(
    valley,
    scene
  );

  // text
  createTexts(
    'Magic NFT Valley',
    '',
    47
  );
  createEmitter1(engine, scene);
  createSphere(scene, randomNum);

  return scene;
};

export default createHomeScene;
