import React, { useState } from 'react';
import {
  Input,
  HStack,
  VStack,
  InputGroup,
  InputLeftElement,
  Button,
  Box,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import RenderValleyItems from '../views/NFTs/elements/valleyItems/RenderValleyItems';
import { isString1InsideString2 } from '../utils/helpers';

const SearchByGameName = ({ allItems }) => {
  const [sticker, setSticker] = useState('');
  const [tagItems, setTagItems] = useState([]);
  const [showItemsByTag, setShowItemsByTag] = useState(false);
  const handleTag = event => setSticker(event.target.value);
  const isForGame = val => {
    return (
      isString1InsideString2(sticker, val.allIntegratedGameNames) ||
      isString1InsideString2(sticker, val.allIntegratedGameNames)
    );
  };

  const getItemsByTag = () => {
    const _tagItems = allItems.filter(isForGame);
    setTagItems(_tagItems);
  };

  const showTagItems = () => {
    getItemsByTag();
    setShowItemsByTag(true);
  };
  return (
    <VStack spacing={3} mt={6}>
      <HStack>
        <Box>
          <InputGroup size="sm">
            <InputLeftElement children={<SearchIcon color="green.300" />} />

            <Input
              value={sticker}
              onChange={handleTag}
              placeholder="Game Name"
              size="sm"
            />
          </InputGroup>
        </Box>
        <Box>
          <Button
            onClick={showTagItems}
            colorScheme="green"
            size="sm"
            variant="outline"
          >
            Search
          </Button>
        </Box>
      </HStack>
      <Box>{showItemsByTag && <RenderValleyItems items={tagItems} />}</Box>
    </VStack>
  );
};

export default SearchByGameName;
