function MagicPlant3(p5) {
  let string = 'F';
  let len = p5.random(25, 30);
  let deapth = 0;
  let angle = 0;
  let windStrength = 0.1 // normal: 0.1
  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    p5.colorMode(p5.HSB);
    p5.background(0);
    angle = p5.PI / 6;
    for (var i = 0; i < 4; i++) {
      generate();
      len /= 1.2;
    }
    // p5.noiseSeed(90);
  };

  p5.draw = () => {
    p5.background(255);
    createTree();
    angle = p5.PI / 6 + windStrength * p5.sin(p5.frameCount / 50);
  };

  function generate() {
    var newstring = '';
    for (var i = 0; i < string.length; i++) {
      var c = string.charAt(i);
      if (c == 'F') {
        newstring += 'FF+[+F-F-F]-[-F+F+F]';
      } else {
        newstring += c;
      }
    }
    string = newstring;
  }

  function createTree() {
    p5.translate(p5.width / 3, p5.height);
    // console.log(string);
    for (var i = 0; i < string.length; i++) {
      var c = string.charAt(i);
      if (c == 'F') {
        if (i === 0) {
          p5.strokeWeight(2); // Adjust stroke weight for thickness
        } else {
          p5.strokeWeight(1); // Adjust stroke weight for thickness
        }

        let plantColor = p5.color(50 + 10 * deapth, 200, 200); // Set color based on depth
      p5.stroke(plantColor);
        // p5.stroke(50 + 10 * deapth);
        // stroke(deapth * 20, deapth * 70, 50 * (1 + deapth));
        // console.log(deapth);
        p5.line(0, 0, 0, -len / (1 + deapth / 3));
        p5.translate(0, -len / (1 + deapth / 3));
      } else if (c == '-') {
        p5.rotate(-angle + deapth / 75);
      } else if (c == '+') {
        p5.rotate(angle + deapth / 75);
      } else if (c == '[') {
        deapth += 1;
        p5.push();
      } else {
        deapth -= 1;
        p5.pop();
      }
      if (c == 'F' && string.charAt(i + 1) == ']') {
        p5.noStroke();
        p5.fill(15 * deapth, 255, 127);
        p5.ellipse(0, 0, 3, 3);
      }
    }
  }
}

export default MagicPlant3;
