import { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { VStack, Box, Text, Icon, Link } from '@chakra-ui/react';
import getMembership from './elements/getMembership';
import { FaStarAndCrescent, FaGem } from 'react-icons/fa';
import 'normalize.css';
import JoinMembership from '../../actions/NFTs/membership/JoinMembership';
function UserMembership({ provider, addr }) {
  const [membershipDetail, setMembershipDetail] = useState({
    isValid: false,
    membershipEndTime: 0,
  });

  useEffect(() => {
    getItem();
  }, []);

  const unixToDate = unixTime => {
    // Convert the string to a number
    const timestamp = Number(unixTime);

    // Check if the conversion is successful
    if (isNaN(timestamp)) {
      throw new Error('Invalid Unix time');
    }

    // Create a new JavaScript Date object based on the Unix timestamp
    const date = new Date(timestamp * 1000);

    // Extract year, month, day, hours, minutes, and seconds from the date
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Format the date and time in a readable format
    const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return readableDate;
  };

  const getItem = async () => {
    const _membershipDetail = await getMembership(provider, addr);

    setMembershipDetail(_membershipDetail);
  };

  const RenderMembership = () => {
    if (membershipDetail.isValid && addr.length > 0) {
      return (
        <VStack>
          <Icon as={FaStarAndCrescent} w={8} h={8} color="yellow.200" />
          <Box
            w="288px"
            h="198px"
            boxShadow="2xl"
            p="6"
            rounded="md"
            borderRadius="md"
            bgGradient="linear-gradient( 109.6deg,  rgba(61,245,167,1) 11.2%, rgba(9,111,224,1) 91.1% )"
            color="white"
          >
            <VStack spacing={5}>
              <Box>
                <Text style={{ fontFamily: 'Lobster' }} fontSize="3xl" as="b">
                  Membership
                </Text>
              </Box>
              <Box>
                <Icon as={FaGem} w={8} h={8} color="yellow.200" />
              </Box>
              <Box>
                <Text as="i">
                  Valid By: {unixToDate(membershipDetail.membershipEndTime)}
                </Text>
              </Box>
            </VStack>
          </Box>

          <style jsx>
            {`
              @import url('https://fonts.googleapis.com/css?family=Lobster');
            `}
          </style>
        </VStack>
      );
    } else {
      return (
        <VStack spacing={6} mt="30px">
          <Text fontSize="xl">You are not currently on any membership.</Text>
          <JoinMembership />
          <Link as={ReactLink} to="/membership">
            <Text fontSize='lg' as='i' color='green.500'>More details about membership</Text>
          </Link>
        </VStack>
      );
    }
  };

  return <RenderMembership />;
}

export default UserMembership;
