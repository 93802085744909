import { Link as ReactLink } from 'react-router-dom';
import { Box, Link, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useState } from 'react';
import TokenIdFormSingle from '../../../formComponents/TokenIdFormSingle';
import { REACT_APP_MAGIC_NFT_ERC1155_ADDRESS } from '../../../Constants/ContractAddress';
const ViewToken = props => {
  const [tokenContractAddr, setTokenContractAddr] = useState(
    REACT_APP_MAGIC_NFT_ERC1155_ADDRESS
  );
  const [tokenId, setTokenId] = useState([0]);

  return (
    <Box boxShadow="2xl" p="6" rounded="md">
      {props.useCase === 'otherContracts' && (
        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">Token Contract:</FormLabel>
            <Input
              value={tokenContractAddr}
              onChange={e => setTokenContractAddr(e.target.value)}
              placeholder="NFT Token Contract Address"
            />
          </FormControl>
        </Box>
      )}

      <TokenIdFormSingle tokenId={tokenId} setTokenId={setTokenId} />

      <Box size="sm">
        <Link
          as={ReactLink}
          to={`/tokens/tokensbyuser/${tokenContractAddr}/${tokenId}/${props.user}`}
        >
          <Box
            as="button"
            p={3}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, teal.500, green.500)"
            _hover={{
              bgGradient: 'linear(to-r, red.500, yellow.500)',
            }}
          >
            View NFT
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default ViewToken;
