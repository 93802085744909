import { Button } from '@chakra-ui/react';
const RenderModalButton = props => {
  return (
    <Button
      onClick={props.onClick}
      colorScheme="green"
      size="md"
      variant="ghost"
    >
      {props.label}
    </Button>
  );
};

export default RenderModalButton;
