import React from 'react';
import SearchByAddress from './SearchByAddress';

const SearchSellerShop = () => {
  return (
    <SearchByAddress
      useCase="shops"
      _placeholder="Seller address"
      label="Search Seller Shop"
    />
  );
};

export default SearchSellerShop;
