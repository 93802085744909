import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
  } from '@chakra-ui/react';
  import {
    FeaturingGame,
    FeaturingNFTcontent,
    FeaturingNFTs,
    HowToFeatureNFT,
    WhatIsFeaturingItem,
  } from './FeatureItemFAQ';
  import {
    AccessArtistPage,
    AccessCollection,
    CreateNewCollection,
    CreatorOfTokens,
    CustomizeArtistPage,
    UpdateMeta,
    WhatIsArtistPage,
  } from './ArtistPageFAQ';
  const SellerPageSection = () => {
    return (
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                1. What is a Seller Shop?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <WhatIsArtistPage />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                2. How can I get my own artist page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Your own artist page will be automatically created for you upon
            minting an NFT in Magic NFT Valley.
          </AccordionPanel>
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                3. How to access an artist page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccessArtistPage />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                4. Can I customize my artist page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Absolutely! You have full control over customizing your artist page.
            This includes adding a captivating poster, an engaging introduction
            video, a compelling description, a link to your external website (if
            applicable), and connections to your social media accounts.
          </AccordionPanel>
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                5. How to customize my artist page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <CustomizeArtistPage />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                6. How to update meta for my artist page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <UpdateMeta />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                7. What is an artist collection?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            An artist collection is a collection created by the creator of the
            NFTs insice the collection for varioud purposes.
          </AccordionPanel>
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                8. How to create an artist collection?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <CreateNewCollection />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                10. How to access an artist collection?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccessCollection />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                11. I created this NFT, but I cannot add it to a collection.
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <CreatorOfTokens />
        </AccordionItem>
  
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                12. I am the owner of this NFT, but I cannot add it to a
                collection.
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            You can only add tokens to a collection if you are the creator and
            owner of those tokens. If you are not the token creator, even if you
            own the token, you cannot add it to an artist collection.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };
  
  export default SellerPageSection;
  