import UpdateTokensInCollection from '../../collections/UpdateTokensInCollection';
const UserAddTokenToCollection = ({ tokenContract, tokenId }) => {
  return (
    <UpdateTokensInCollection
      useCase="TokenByUser"
      tokenContract={tokenContract}
      tokenId={tokenId}
      updateType="Add"
    />
  );
};

export default UserAddTokenToCollection;
