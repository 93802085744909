import ValleyItem from './ValleyItem';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../../walletConnections/obtainProviders/getNetworkProvider';
const SaleInValley = ({saleType, tokenType}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const provider =
    address == undefined
      ? getNetworkProvider()
      : new ethers.providers.Web3Provider(walletProvider);
  // console.log('provider:', provider);
  return (
    <>
      {provider && (
        <ValleyItem
          provider={provider}
          saleType={saleType}
          useCase=""
          tokenType={tokenType}
        />
      )}
    </>
  );
};

export default SaleInValley;
