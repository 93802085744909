import React from 'react';
import SearchById from './SearchById';

const SearchAuctionItem = () => {
  return (
    <SearchById
      useCase="auctions"
      _placeholder="Auction Id"
      label="Search Auction House"
    />
  );
};

export default SearchAuctionItem;
